@use 'sass:math';

@import 'src/styles/vars';

$standard-height: 128px;
$small-height: 48px;

.BasicInformation {
  width: 100%;
  .BasicInformation__content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 0.5em;

    > * {
      margin: 0;
      margin-right: 1rem;
    }
    .Ellitips {
      font-weight: 400;
      width: auto;
    }
  }

  .BasicInformation__lifeCycle {
    display: flex;
    align-content: flex-start;
  }

  .BasicInformation__content__left {
    max-width: 40vw;
  }
}

.PendingExclusivityBadge {
  padding: math.div($padding-small-vertical, 2)
    math.div($padding-small-horizontal, 2);
  margin-right: math.div($margin-base, 2);

  color: $brand-danger;
  border: 1px solid $brand-danger;
  border-radius: $border-radius-base;
}

.BasicInformation__content__left__shared_by {
  color: $gray-medium;
}
