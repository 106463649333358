@import 'src/styles/vars';

.ViewAs {
  background-color: $brand-white;
  padding: $half-margin-base $margin-base-horizontal;
  margin: 0 0 20px 0;
}

.ViewAs__title {
  font-size: 1rem;
  font-weight: 400;
  margin: 0 0 10px 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  > .AddButton {
    width: initial;
    border: none;
    font-weight: 400;
    padding: 0;
    i {
      font-size: large;
    }
  }
}

.ViewAs__ruleSets {
  margin-top: $half-margin-base;
}

.ViewAs__ruleSets__switch {
  margin-top: 1em;
}

.ViewAs__ruleSetItem {
  display: inline-flex;
  align-items: center;
  word-break: break-word;
  min-height: 1.5rem;
}

.ViewAs__ruleSetIcon {
  width: 1.5rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .mdi {
    vertical-align: middle;
    font-size: 1.1rem;
    &.mdi-calendar-check {
      color: $brand-success;
    }
    &.mdi-calendar-clock {
      color: $brand-orange;
    }
    &.mdi-calendar-alert {
      color: $brand-danger;
    }
  }

  &--notRequested {
    opacity: 0.4;
  }
}
