@use 'sass:math';

@import 'src/styles/vars';

.ProductFooter {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10 !important;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);

  .BasicInformation__content {
    display: block;
    .Ellitips {
      font-weight: 300;
    }
  }

  .BasicInformation__packaging,
  .BasicInformation__lifeCycle {
    display: none;
  }
}

.ProductFooter__content {
  align-items: center;
  min-height: $product-sticky-header-height;
}

.ProductFooter__back {
  color: $brand-primary;
}

.ProductFooter__basic {
  padding-left: $grid-gutter-width;
}

.FooterActions__validation {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  .ValidationStepper {
    display: flex;
    margin: 0 $padding-base-horizontal;
    min-width: 64px;
  }
}

.ProductFooter__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * + * {
    margin-left: math.div($margin-base-horizontal, 2);
  }
}

.FooterActions {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.ThirdPartyFooterActions__buttons {
  flex-grow: 0;
  align-items: right;
  display: inline-flex;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.ThirdPartyFooterActions__failButton,
.ThirdPartyFooterActions__cannotCheckButton {
  background-color: $brand-white;
  color: $brand-primary;
}

.ProductFooterAction_saveAndShareButton {
  margin-left: $half-margin-base-horizontal;
}
