@import 'src/styles/vars';

.BulkActionModalInfo {
  display: flex;
  width: 100%;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.BulkActionModalInfo__icon {
  padding-right: 0.2rem;
  &.mdi-check {
    color: $brand-success;
  }
  &.mdi-close {
    color: $brand-danger;
  }
}

.BulkActionModalInfo__separator {
  padding: 0 0.4rem;
}
