@import 'src/styles/vars';

.GTINGenerator {
  &__icon {
    font-size: larger;
    margin-right: $padding-base-vertical;
    &--warning {
      color: $brand-highlight-secondary;
    }
  }

  &__unit {
    display: flex;
    > * + * {
      margin-left: $margin-base-horizontal;
    }
  }

  &__unitName {
    font-weight: 400;
  }
}
