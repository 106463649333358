// =================================================================================================
//                                          BASE COLORS
// =================================================================================================

$brand-white: #ffffff;
$brand-black: #000000;

// Colors
// -------------------------
// Gray and brand colors for use across Bootstrap.

$gray-darker: #222222;
$gray-dark: #3c3c3b;
$gray: #555555;
$gray-medium-dark: #999999;
$gray-medium: #a0a0a0;
$gray-light: #b8b8b8;
$gray-slightly-lighter: #d8d8d8;
$gray-lighterer: #dedfdc;
$gray-lighter: #e9ecf0;
$gray-really-light: #f5f5f5;
$gray-lightest: #fbfbfb;
$gray-soft-light: #ccc;

$blue-light: #ebf8fc;
$blue-status: #2979ff;

$purple-dark: #8252ea;
$purple-light: #f1ecfc;

$orange-light: #fff7e5;

$brand-primary: #3fa2b2;
$brand-secondary: #166a83;

$brand-success: #48b071;
$brand-info: $brand-secondary;
$brand-default: $gray-darker;
$brand-danger: #e64c42;
$brand-warning: $brand-danger;

$brand-inprogress: #c69c5d;
$brand-highlight: #fabf2d;
$brand-highlight-secondary: #ef7e1d;

$brand-primary-hover: $brand-secondary;
$brand-primary-btn-hover: #3e8e9a;
$brand-primary-active: #8cc7d1;

$brand-secondary-hover: #d0e1e6;
$brand-danger-hover: #fadbd9;
$brand-warning-hover: $brand-danger-hover;
$brand-highlight-hover: #fef0cd;

$brand-secondary-disabled: #a8b8c5;
$brand-success-disabled: #c0ddc6;
$brand-danger-disabled: #f6beb7;
$brand-warning-disabled: $brand-danger-disabled;
$brand-inprogress-disabled: #ebdcc6;
$brand-highlight-disabled: #fee5b3;
$brand-highlight-secondary-disabled: #f9cda9;
$brand-default-disabled: #979493;

$text-disabled-color: $gray-light;

$brand-orange: #ff9900;
$brand-orange-darker: #e18339;
$brand-orange-lighter: #f8e6d7;

$alk-red: rgb(227, 37, 34);
$alk-green: $brand-success;
$alk-blue: #166a82;

$alk-background-hover: #d9ecf0;

@mixin color-inverse($color) {
  background-color: $color !important;
  color: white;
}

// =================================================================================================
//                                       FUNCTIONAL COLORS
// =================================================================================================

// Read only components
// -------------------------
$readonly-bg: $gray-really-light; // ex. : input background, ...
$readonly-bg-darker: $gray-lighter; // ex. : background foreground elements (multi-select, tags, ...)
$readonly-bg-darkest: $gray-slightly-lighter; // ex. : Icon background in readonly mode
$readonly-fg: $gray; // ex. : Text, checkbox tick, radio check, ...
$readonly-fg-empty: $gray; // ex. : Empty content

// Other color variables
// -------------------------
$gray-border: $gray-lighterer;
$table-striped-bg: $blue-light;

$alk-total: $brand-primary;
$alk-certified: $brand-success;
$alk-accepted: $brand-inprogress;
$alk-archived: $gray-light;

// Status colors

$status-default-color: $gray-medium;
$status-draft: $gray-medium;
$status-not-attributed: $brand-danger;
$status-attribution-pending: $brand-highlight;
$status-archived: $brand-highlight-secondary;
$status-accepted: $brand-success;
$status-pending: $brand-secondary;
$status-published: $brand-secondary;
$status-updated: $brand-primary-active;
$status-republished: $brand-primary-active;
$status-validation-pending: $brand-highlight-secondary;
$status-validated: $brand-success;
$status-danger: $brand-danger;
$status-rejected: $brand-danger;
$status-success: $brand-success;
$status-not-assigned: $brand-warning;
$status-assignation-pending: $brand-highlight-secondary;
$status-waiting-to-be-shared: $brand-highlight;
$status-refused: $gray-medium;
$status-submitted: $brand-secondary;
