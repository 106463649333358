@import 'src/styles/vars';

.LogisticalHierarchyGroupedFields {
  margin-top: $margin-base;
}

.LogisticalHierarchyGroupedFields__header {
  color: $gray-light;
  border-bottom: 1px solid $gray-light;
  margin-top: $half-margin-base;
}

.LogisticalHierarchyGroupedFields__line {
  display: flex;
  align-items: center;
  padding-left: $padding-xs-horizontal;
  > :not(.LogisticalHierarchyGroupedFields__lineLabel) {
    flex-basis: 15%;
  }
}

.LogisticalHierarchyGroupedFields__lineLabel {
  flex-basis: 25%;
  display: flex;
  align-items: center;
  .LogisticalHierarchyIcon {
    margin-right: 1em;
  }
}

.LogisticalHierarchyGroupedFields__value {
  display: flex;
  align-items: center;
  > :not(.LogisticalHierarchyGroupedFields__unitCode) {
    width: 75%;
  }
}

.LogisticalHierarchyGroupedFields__unitCode {
  margin-left: 0.2em;
  width: 25%;
  color: $gray-light;
}

.LogisticalHierarchyGroupedFields__warning {
  padding-top: 10px;
  padding-left: 10px;

  .GenericTable__row {
    padding: 0;
    border-bottom: none;

    .GenericTable__cell {
      padding: 0;

      &:not(:first-child) {
        padding-left: 5px;
      }
    }
  }
}
