@import 'src/styles/vars';

.StringList__item {
  display: flex;
  align-items: center;
  > i {
    margin-left: $margin-base-horizontal;
    color: $gray-light;
    font-size: $font-size-xlarge;
    &:hover {
      cursor: pointer;
      color: $gray-dark;
    }
  }
  .InputField__input {
    padding-top: 0;
    padding-bottom: 0;
  }
  + .StringList__addButton {
    margin-top: $half-margin-base;
  }
}
