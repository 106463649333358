@import 'src/styles/vars';

.wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 330px;
  width: 210px;
  background-color: $gray-lighter;
  border: 1px solid $gray-lighter;
  border-radius: 8px;
}

.wrapperSelected {
  border-color: $brand-secondary;
  background-color: $brand-secondary;
}

.wrapperError {
  border-color: $brand-danger;
  background-color: $brand-danger;
}

.wrapperWarning {
  border-color: $brand-highlight-secondary;
  background-color: $brand-highlight-secondary;
}

.previewWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 210px;
  height: 210px;
  background-color: $brand-white;
}

.previewWrapperNoPreview {
  background-color: $gray-lighter;
}

.previewHeader {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background-color: $brand-secondary;
}

.headerText {
  font-weight: 300;
  font-size: 1rem;
  color: $brand-white;
}

.preview {
  z-index: 1;
  display: flex;
}

.previewSelected {
  opacity: 0.5;
}

.fileIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 5rem;
}

.previewFooter {
  z-index: 2;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5rem;
}

.fixCheckbox {
  margin-bottom: -0.5rem;
}

.chips {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.chip {
  user-select: none;
  list-style: none;
  height: 25px;
  padding: 0 0.5rem;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 2px;
  color: $brand-default;
}

.chipError {
  background-color: $brand-danger-disabled;
}

.chipWarning {
  background-color: $brand-highlight-secondary-disabled;
}

.chipArchived {
  background-color: $gray;
  color: $brand-white;
}

.chipPackshot {
  background-color: $brand-secondary;
  color: $brand-white;
}

.assetInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.3rem;
  padding: 0.6rem;
  background-color: $brand-white;
}

.label {
  font-weight: 500;
  font-size: 0.8rem;
  color: $gray-darker;
}

.assetLabel {
  font-weight: 500;
  font-size: 0.8rem;
  color: $gray-darker;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fileExtWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 25px;
  background-color: $gray-lighter;
  border-radius: 2px;
  border: 1px solid transparent;
}

.fileExt {
  font-weight: 300;
  font-size: 1rem;
  color: $brand-default;
  text-transform: uppercase;
}

.infoText {
  font-weight: 300;
  font-size: 0.8rem;
  color: $gray-darker;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
