@import 'src/styles/vars';

.CreateHierarchyModal__textileChoice {
  display: flex;
  margin-top: $margin-base * 2;
  > * + * {
    margin-left: $margin-base-horizontal;
  }
}

.CreateHierarchyModal__textileChoiceItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: $padding-large-vertical $padding-base-horizontal;
  &:hover {
    border-radius: $border-radius-base;
    background-color: $brand-secondary-hover;
    cursor: pointer;
  }
}

.CreateHierarchyModal__textileChoiceItemTitle {
  color: $brand-primary;
}

.CreateHierarchyModal-line {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: $brand-secondary-hover;
  }
}

.CreateHierarchyModal-element {
  display: flex;
  align-items: center;

  .mdi-chevron-right {
    font-size: 2.5rem;
    color: $gray-medium-dark;
  }

  .LogisticalHierarchyIcon {
    margin-right: 1em;
  }
}

.CreateHierarchyModal-Radio {
  margin-bottom: 2em;
}

.CreateHierarchyModal__more {
  font-size: xx-large;
}
