@import 'src/styles/vars';

.UserLabelFormItem {
  position: relative;
  justify-content: space-between;

  &:hover {
    background-color: $alk-background-hover;

    .UserLabelFormItem__actions {
      visibility: visible;
    }
  }

  &.UserLabelFormItem--new {
    font-style: italic;
  }

  label {
    flex: 1;
    padding: 0.25rem 0;
    line-height: 1.2em;
    margin: 0;
    border-bottom: 1px solid transparent;
  }
}

$iconHeight: 1.25em;

.UserLabelFormItem__actions {
  height: $iconHeight;
  line-height: $iconHeight;
  visibility: hidden;

  &.UserLabelFormItem__actions--editable,
  &.UserLabelFormItem__actions--delete {
    visibility: visible;
  }
}

.UserLabelFormItem__action {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  color: $brand-secondary;

  &:hover {
    color: $brand-primary;
  }

  &,
  .mdi {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $iconHeight;
    height: $iconHeight;
  }

  &[disabled] {
    opacity: 0.65;
  }
}

.UserLabelFormItem__input {
  flex: 1;
  padding: 0.25rem;
  margin: 0;
  line-height: 1.2em;
  border: none;
  border-bottom: 1px solid $brand-secondary;
  outline: none;
  background-color: transparent;

  &:focus {
    border-color: $brand-primary;
  }

  &[disabled] {
    opacity: 0.65;
  }
}

.UserLabelFormItem__confirmDelete {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $brand-danger;
  color: $brand-white;
  padding: 0 4px;
  z-index: 1;

  button {
    border: 1px solid $brand-white;
    padding: 1px 2px;
    background-color: transparent;
    border-radius: 2px;
    font-style: normal;

    &:hover {
      background-color: $brand-white;
      color: $brand-danger;
    }
  }

  button + button {
    margin-left: 4px;
  }
}

.UserLabelFormItem__delete {
  font-weight: 500;
}
