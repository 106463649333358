@import 'src/styles/colors';

.ProgressByLanguage {
  display: flex;
  justify-content: center;
}

.ProgressByLanguageDropdown {
  width: 80%;
}
