@import 'src/styles/vars';

.RemoveFromActiveRange__products {
  @include ul();
  margin: 0;
  padding: 1rem;
  max-height: 30vh;
  overflow: hidden;
  overflow-y: auto;

  &.RemoveFromActiveRange__errors {
    max-height: 20vh;
  }
}

.RemoveFromActiveRange__errorsLabel {
  padding-top: 1rem;
}
