@import 'src/styles/vars';

.RetailerHeaderActions {
  text-align: right;
}

.ProductHeaderActions {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  > button,
  > div {
    margin: 0 8px;
  }
}

.ProductHeaderActions__admin {
  color: $brand-danger !important;
  &:hover {
    background-color: $brand-danger-hover !important;
  }
}

.moreActions_label {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .moreActions_label_language {
    margin-right: 10px;
  }
}

.SaveButton--small,
.RetailerActions__previewHpp--small {
  background: white;
  border-radius: 50%;
  color: $brand-secondary;
  font-size: $font-size-h3;
  height: 40px;
  line-height: 40px;
  padding: 0;
  width: 40px;

  i {
    height: 100%;
    width: 100%;
  }

  &:hover {
    background: $brand-secondary;
    color: white;
  }

  &:disabled {
    background: transparent;
    border: 1px solid $text-disabled-color;
    color: $text-disabled-color;
  }
}
