@import 'src/styles/vars';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem 0 1rem;
}

.tabs {
  display: flex;
  flex: 1;
}

.tabButton {
  display: flex;
  padding: 0.5rem 1rem;
  user-select: none;
  background: none;
  border: none;
  font-weight: 300;
  text-align: center;
  color: $brand-primary;
  border-bottom: 2px solid transparent;
}

.tabButtonActive {
  font-weight: 500;
  border-bottom: 2px solid $brand-primary;
}
