@use 'sass:math';

@import 'src/styles/vars';

.PSQEditor {
  margin-top: $half-margin-base;
  margin-left: -7.5px;
  margin-right: -7.5px;
  padding-left: 7.5px;
  padding-right: 7.5px;

  &.FormField--flagged,
  &.FormField--editable,
  &.FormField--patched {
    background-color: $brand-highlight-hover;

    .PSQEditor__buttonBar .Button.selected:after {
      border-bottom-color: $brand-highlight-hover;
    }

    .FormField {
      background-color: transparent;
    }

    .PNQTable__item:nth-child(even) {
      background-color: lighten($brand-highlight-hover, 5%);
    }
  }
}

.PSQEditor__header .Raguel {
  margin-top: $half-margin-base;
}

.PSQEditor__buttonBar {
  padding: $padding-large-vertical math.div($grid-gutter-width, 2);
  margin-left: math.div(-$grid-gutter-width, 2);
  margin-right: math.div(-$grid-gutter-width, 2);
  border-bottom: 1px solid $brand-secondary;
  &--empty {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .Button.selected {
    &:before,
    &:after {
      bottom: -19px; // In pixels to stay responsively stable
      border: solid transparent;
      content: ' ';
      position: absolute;
      pointer-events: none;
    }
    &:before {
      border-color: transparent;
      border-bottom-color: $brand-secondary;
      border-width: 10px;
      margin-left: -10px;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: $brand-white;
      border-width: 9px;
      margin-left: -9px;
    }
  }
}

.PSQEditor__form {
  display: flex;
  padding-top: $half-margin-base;
  & > * {
    min-width: 450px;
    max-width: 100%;
    width: 100%;
  }
  .Autocomplete {
    min-width: 100px;
  }
}

.PSQEditor__table {
  border-top: 1px solid $gray-lighterer;
  padding-top: $padding-large-vertical;
}

.PSQEditor__autocomplete {
  padding-top: $half-margin-base;
  padding-left: 25%;
}

.PSQEditor__header__buttonBar {
  display: flex;
  flex-direction: column;

  &.PSQEditor__header__buttonBar--empty > .Raguel {
    order: 2;
  }
}
