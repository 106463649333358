@import 'src/styles/vars';

$circle-size: 400px;

.PermissionsEmptyState {
  display: flex;
  justify-content: center;
  padding: $margin-base;
}

.PermissionsEmptyState__circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $circle-size;
  height: $circle-size;
  background-color: $brand-white;
  border-radius: 50%;
  padding: 2rem;
}

.PermissionsEmptyState__icon {
  flex: 0 0 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 6rem;
  color: $brand-danger;
}

.PermissionsEmptyState__body {
  flex: 0 0 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;

  & > * + * {
    margin-top: 0.25rem;
  }
}
