.ListAdvancedFilterItem {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;

  .ReactUiCheckbox {
    margin-bottom: 0;
    flex: 1;
  }
}

.ListAdvancedFilterItem__chevron {
  width: 1em;
  height: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
}
