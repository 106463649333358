@import 'src/styles/vars';

.CustomHistoryDiffLine {
  .historyDiffComponent--button {
    display: flex;
  }

  .historyDiffComponent--button--content {
    height: 2em;
    width: 30em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-light;
    border-radius: 20px;
    box-shadow: 0px 2px 2px $gray-medium-dark;

    &:hover {
      background: $blue-light;
      border: 1px solid $gray-light;
    }
  }

  .historyDiffComponent--card__empty {
    display: flex;
    justify-content: center;
    color: $gray-light;
    font-style: italic;
  }
}
