@import 'src/styles/vars';

.FormList__addButton {
  margin-top: $padding-large-vertical;
  margin-bottom: $padding-base-vertical;
  width: 100%;
}

/* Don't add an extra border if only one child */
.FormList__list--singleChild > .FormList__item > .FormList__itemContent {
  > .DisplayGroup--condensed {
    margin-bottom: 0;
  }
}

.FormList__itemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FormList__list--multipleChildren .form-group {
  margin-bottom: 1rem;
}

.FormList__deleteButton {
  margin-left: $margin-base-horizontal;
  color: $gray-light;
  cursor: pointer;
  font-size: $font-size-xlarge;
  opacity: 1;
  width: $font-size-xlarge;
  transition:
    opacity 0.5s,
    margin-left 0.5s,
    width 0.5s;
}

.FormList__deleteButton--hidden {
  opacity: 0;
  margin-left: 0;
  width: 0;
  transition:
    opacity 0.5s,
    margin-left 0.5s,
    width 0.5s;
}

.FormList__deleteButton:hover {
  color: $gray-dark;
}
