@import 'src/styles/vars';

.CatalogMenuTab {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  min-height: calc(3rem - 2px);
  color: $brand-primary;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &:hover {
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &.CatalogMenuTab--selected {
    border-color: $brand-primary;
    cursor: default;
    background: rgba($brand-primary, 0.2);
    color: inherit;
    font-weight: 400;
  }

  &,
  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
