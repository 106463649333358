.TableBody {
  flex-grow: 1;
  padding: 0 1rem;

  &.TableBody--scrollable {
    position: relative;
  }

  &.TableBody--withMargin {
    .TableBody__scroller {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .TableBody__scroller__sticky {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.TableBody__scroller {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.TableBody__scroller--padding {
  padding: 0 1rem;
}

.TableBody__scroller__sticky {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.TableBody__scrollerWithMargin {
  min-height: 100%;
  overflow-x: scroll;
  overflow-y: visible;
}
