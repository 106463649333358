.CoreLayoutRoot {
  position: relative;
  width: 100%;
  height: 100%;

  &.CoreLayoutRoot--absolute {
    .HeaderLayout {
      margin-bottom: 0;
    }
  }
}

.CoreLayout {
  position: relative;
  top: 40px;
  height: calc(100% - 40px);
  transition: all 0.6s;

  &.CoreLayout--absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }
}
