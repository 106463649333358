@import 'src/styles/vars';

.ProductReference {
  position: relative;
}

.ProductReference.ProductReference__inline {
  display: inline-block;
}

.ProductReference__copyWrapper {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.ProductReference__highlight {
  color: $brand-primary;
}

.ProductReference__copy {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.4s;
}

.ProductReference__iconCopyDisplayable {
  font-size: 1.2rem;
  padding: 0.5rem;
  display: inline-block;
  color: $brand-primary;
  &:hover {
    cursor: pointer;
  }
}

.ProductReference__copyVisible {
  opacity: 1;
}

.ProductReference__copyMessage {
  font-size: 12px;
  line-height: 24px;
  height: 28px;
  padding: 2px 8px;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.ProductReference__copyIcon {
  color: $brand-success;
}

.ProductReference__duplicatedIcon {
  font-size: 1.2rem;
  padding: 0.5rem;
  color: $gray;
  display: inline-block;

  &:hover {
    color: $gray-darker;
    cursor: pointer;
  }
  &.ProductReference__duplicatedIcon--exportable {
    color: $brand-primary;
    &:hover {
      cursor: pointer;
      color: $brand-secondary;
    }
  }
}

.ProductReference__Tooltip {
  width: 180px;
  font-size: 10px;
  padding: 0px;
}
