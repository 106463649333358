.ListTableCell {
  padding: 0 0.5rem;
  width: 1px;

  &:first-child {
    padding-left: 1rem;
    &.ListTableCell--firstColumnSticky {
      position: sticky;
      left: 0;
      background-color: inherit;
    }
  }

  &:last-child {
    padding-right: 1rem;
  }

  & > * {
    vertical-align: middle;
  }

  &--center {
    text-align: center;
  }
}
