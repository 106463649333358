@import 'src/styles/vars';

.ValidationResults {
  display: inline-flex;
  align-items: center;
  max-width: 550px;
  &--grow {
    flex-grow: 1;
  }
  &--noWarnings {
    max-width: 480px;
  }
}

.ValidationResults--empty {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.ValidationResults__prefix {
  margin-right: $half-margin-base-horizontal;
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.ValidationResults__prefixLabel {
  font-weight: 400;
}

.ValidationResults__progressBar {
  flex-grow: 1;
  min-width: 200px;

  &.ValidationResults__progressBar--notRequested {
    .ProgressBar__value {
      background-color: $brand-highlight-secondary;
    }
  }
}

.ValidationResults__warnings {
  margin-left: 10px;
  display: flex;
  align-items: center;
  border-left: 1px grey solid;
  padding-left: 10px;
  & > span {
    min-width: 6rem;
  }
}

.ValidationResults__warningIcon {
  color: $brand-highlight;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-right: 2px;
}
