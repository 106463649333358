@import 'src/styles/vars';

.CollapsibleHierarchy {
  background-color: $brand-white;
  border-radius: $border-radius-base;
}

.CollapsibleHierarchy__header {
  padding: $padding-large-vertical $padding-base-horizontal;
  cursor: pointer;

  &.CollapsibleHierarchy__header--patched {
    background-color: $brand-highlight-hover;
  }

  .Raguel {
    margin-top: $half-margin-base;
  }

  .Raguel__FailedGtins {
    margin: 0;
  }
}

.CollapsibleHierarchy__headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CollapsibleHierarchy__collapseBtn {
  font-size: 2em;
}

.CollapsibleHierarchy__content {
  padding: $padding-large-vertical $padding-base-horizontal;
  border-top: 1px solid $gray-light;
}

.OneLineHierarchy__caseHelp {
  margin-left: $margin-base-horizontal;
  cursor: initial;
}
