@import 'src/styles/vars';

.Label {
  display: inline-block;
  padding: $padding-xs-vertical $padding-small-horizontal;
  border-width: 1px;
  border-style: solid;
  border-radius: $border-radius-very-large;
  white-space: nowrap;

  @mixin Label($color) {
    border-color: $color;
    color: $color;
    &.Label--filled {
      border-color: $color;
      background-color: $color;
      color: white;
    }
  }

  &.Label--inprogress {
    @include Label($brand-inprogress);
  }

  &.Label--success {
    @include Label($brand-success);
  }

  &.Label--warning {
    @include Label($brand-warning);
  }

  &.Label--draft {
    @include Label($gray-medium);
  }

  &.Label--default {
    @include Label($brand-primary);
  }
}
