@import 'src/styles/vars';

.ErrorPanel {
  display: flex;
  padding: 10px;
  background: #fadbd9;
  margin: 20px 0;
  margin-left: -3px;
  border-left: 3px solid #e64c42;
  color: #e64c42;
  align-items: center;
}

.ErrorPanel__text {
  margin-right: 10px;
  width: 100%;
}

.ErrorPanel__icon {
  margin-right: 5px;
  font-size: large;
}
