@use 'sass:math';

@import 'src/styles/vars';

.RecipientRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $padding-base-vertical $padding-base-horizontal;

  &:nth-child(odd) {
    background-color: $table-striped-bg;
  }

  &.RecipientRow--unavailable {
    background-color: $gray-really-light;
  }
}

.RecipientRow__helpIcon,
.RecipientRow__exportIcon {
  margin-left: 0.25em;
}

.RecipientRow__helpIconSwitch {
  margin-right: 0.5em;
}

.RecipientRow__help {
  max-width: 50vw;
}

.RecipientRow__Issues {
  color: $brand-danger;

  a,
  a:active,
  a:visited,
  a:hover {
    color: $brand-danger;
  }
}

.RecipientRow__Warnings {
  margin-right: $half-margin-base-horizontal;
  color: $brand-highlight;
}

.RecipientRow__FixButton {
  margin-left: math.div($half-margin-base, 2);
}

.RecipientRow__Sync {
  color: $brand-highlight;
  white-space: nowrap;
  // font-weight: 400;
  // margin-right: 1em;
}

.RecipientRow__bullet {
  color: $gray-light;
  padding: 0 1.5em;
  font-size: 0.5em;
}

.RecipientRow__switchStatus {
  padding-right: 0.5em;

  & > .mdi-loading {
    padding-left: 0.5em;
  }
}

.RecipientRow__activateModal .Modal__body {
  max-width: 600px;
}
