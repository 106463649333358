@import 'src/styles/colors';

.ListReferentialCell {
  $line-height: 1.3em;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  min-width: 200px;
  max-height: $line-height * 2;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
