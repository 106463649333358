@import 'src/styles/colors';

.AverageTimeCell {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.AverageTimeCell--good {
    color: $brand-success;
  }

  &.AverageTimeCell--medium {
    color: $brand-highlight-secondary;
  }

  &.AverageTimeCell--low {
    color: $brand-danger;
  }
}

.AverageTimeCell__count {
  font-weight: 500;
}
