@import 'src/styles/vars';

.DocumentModal {
  height: 100%;
}

.DocumentModal__dropzone {
  width: 100%;
  height: 100%;
  min-height: 300px;
  padding: 15px;
  border: 1px $brand-primary dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > div {
    display: inline-block;
  }

  &.DocumentModal__dropzone--active {
    background-color: $blue-light;
    border-color: $brand-secondary;
  }
}

.DocumentModal__objectContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px;
  border-color: black;
  text-decoration: none !important;
}

.Asset__object {
  font-size: 10rem;
  color: $gray-medium;
  min-height: 300px;
}

.DocumentModal__image {
  max-width: 100%;
  max-height: 300px;
}

.DocumentModal__fileName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  margin-bottom: 30px;
}
