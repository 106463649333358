@import 'src/styles/vars';

.CollapsibleVariant {
  background-color: $brand-white;
  border-top: 1px solid $gray-lighterer;
  i {
    font-size: x-large;
  }
  .UnreadCount {
    margin-bottom: 0;
  }
}

.CollapsibleVariant-header {
  padding: $padding-large-vertical 0;
  cursor: pointer;
  .Ellitips {
    margin-left: 30px;
    width: 130px;
  }
}

.CollapsibleVariant-content {
  padding: $padding-large-vertical 0;
  display: flex;
  align-items: center;
}

.CollapsibleVariant-content__data {
  flex-grow: 2;
  padding: $padding-base-vertical $padding-small-horizontal;
  border-radius: $border-radius-base;
  border: 1px solid $gray-light;
}

.CollapsibleVariant-content__actions {
  color: $gray-light;
  background-color: $brand-white;
  border: 0;
  align-items: center;
  margin-right: -5px;
  > span {
    font-size: 1.6rem;
  }
}
