@import 'src/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
  height: 100%;
  width: 100%;
  border: 1px solid $gray-lighter;
  border-radius: 4px;
  padding: 1rem;

  &--no-details {
    justify-content: center;
  }
}

.help-text {
  font-weight: 300;
  text-align: center;
  color: $brand-default-disabled;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-weight: 500;
}

.close-button {
  background: none;
  padding: 0;
  border: none;
}
