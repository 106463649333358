@import 'src/styles/vars';

.AllowanceHeader {
  display: flex;
  margin-top: $half-margin-base;
  padding-left: $padding-xs-horizontal;
  color: $gray-light;
}

.AllowanceHeader__rank {
  width: 1.5em;
}

.AllowanceHeader__metadata {
  display: flex;
  flex-grow: 1;
  padding-left: $padding-large-horizontal;
}

.AllowanceHeader__type {
  flex-basis: 30%;
}

.AllowanceHeader__unit {
  flex-basis: 20%;
  padding-left: $padding-xs-horizontal;
}

.AllowanceHeader__description {
  flex-grow: 1;
  padding-left: $padding-xs-horizontal;
}

.AllowanceHeader__value {
  flex-basis: 25%;
  padding-left: $padding-xs-horizontal;
}
