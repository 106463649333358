@import 'src/styles/vars';

.modalOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal {
  position: relative;
  width: calc(100% - 60px);
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 1rem;
  border-radius: 20px 20px 0 0;
  background: rgb(255, 255, 255);
  inset: 50px 30px;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  font-weight: 200;
  font-size: 1.3rem;
}

.close {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;

  font-size: 1.5rem;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 1.5rem 2rem;
}
