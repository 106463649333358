@import 'src/styles/vars';

.Labels {
  margin-top: $padding-large-vertical;
  display: flex;
}

.Labels__item {
  margin: 10px 0;
}

.Labels__selection {
  margin-left: $margin-base-horizontal;
  flex: 1;
}
