.ListTableCell.CatalogProductActionColumn {
  width: 160px;
  min-width: 160px;
  padding-right: 1rem;
}

.CatalogProductActionColumn__productpage {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CatalogProductActionColumn__btnTxt {
  white-space: nowrap;
  padding-left: 0.2rem;
}

.CatalogProductActionColumn__btnChevron {
  font-weight: 100;
  padding-left: 0.2rem;
}
