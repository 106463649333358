@import 'src/styles/vars';

.RecipientFilter {
  padding: 1rem 0.5rem;
  background-color: $gray-really-light;
  margin: 1rem;
  border-radius: 0.5rem;
}

.RecipientFilter__header {
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.RecipientFilter__recipients {
  background-color: $brand-white;
}
