@import 'src/styles/colors';

.ListSelectedFilterItem {
  display: inline-flex;
  align-items: center;
  flex-shrink: 1;
  min-width: 0;
  max-width: 100%;
  border: 1px solid $brand-secondary;
  border-radius: 2px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0.1rem 0.25rem;
  color: $brand-secondary;

  & > label {
    flex-grow: 1;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .mdi-close {
    padding-left: 0.2rem;
    cursor: pointer;
  }
}
