@import 'src/styles/vars';

.physical-check-plugin {
  cursor: pointer;
  position: absolute;
  left: 101%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.physical-check-plugin .btn {
  padding: 0 3px;
  min-height: 1.5em;
}

// override button colors :
.physical-check-plugin .btn-secondary {
  border-color: $brand-primary;
  color: $brand-primary;

  &:hover {
    border-color: $brand-primary;
  }
}

.physical-check-plugin .btn-secondary.selected {
  box-shadow: inset 0 0 1px 0 $brand-secondary;
  border: 1px solid $brand-secondary;
  z-index: 2;
}

// override product page
.PhysicalCheckProductPage .Collapsible__content {
  padding: 40px;
}

.physical-check-plugin
  .Button.btn.btn-secondary.physical-check-plugin-btn-pass.selected {
  background-color: $brand-success;
  .mdi {
    color: $brand-white;
  }
}

.physical-check-plugin
  .Button.btn.btn-secondary.physical-check-plugin-btn-pass:hover:not(
    .selected
  ) {
  background-color: rgba($brand-success, 0.2);
  color: $brand-primary;
  cursor: pointer;
  .mdi {
    color: $brand-primary;
  }
}

.physical-check-plugin
  .Button.btn.btn-secondary.physical-check-plugin-btn-fail.selected {
  background-color: $brand-danger;
  .mdi {
    color: $brand-white;
  }
}

.physical-check-plugin
  .Button.btn.btn-secondary.physical-check-plugin-btn-fail:hover:not(
    .selected
  ) {
  background-color: rgba($brand-danger, 0.2);
  color: $brand-primary;
  cursor: pointer;
  .mdi {
    color: $brand-primary;
  }
}

.physical-check-plugin
  .Button.btn.btn-secondary.physical-check-plugin-btn-na.selected {
  background-color: $gray-light;
  color: $brand-white;
}

.physical-check-plugin
  .Button.btn.btn-secondary.physical-check-plugin-btn-na:hover:not(.selected) {
  background-color: rgba($gray-light, 0.2);
  color: $brand-primary;
  cursor: pointer;
}
