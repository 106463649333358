@import 'src/styles/colors';

.ListTextCell {
  $line-height: 1.3em;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  min-width: 100px;
  max-height: $line-height * 2;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.ListTextCell--default {
    font-style: italic;
    color: $gray-medium;
  }

  &.ListTextCell--clickable:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &.ListTextCell--RTL {
    direction: rtl;
  }
}
