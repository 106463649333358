@import 'src/styles/vars';

.CatalogMessageColumn {
  display: inline-block;
  font-size: 1.3rem;
  &--ALL_ACKED {
    color: $brand-success;
  }
  &--I_NEED_TO_ACK {
    color: $brand-danger;
  }
  &--WAITING_FOR_OTHER_TO_ACK {
    color: $brand-highlight;
  }
}

.CatalogMessageColumnDate {
  font-size: 0.8rem;
}

.CatalogMessageCell {
  display: flex;
  flex-direction: column;

  & > .Button {
    padding: 0;
  }
}
