@import 'src/styles/vars';

.ProductAdminIndexing {
  padding: $padding-small-vertical;
  background-color: $brand-white;
  border-radius: $border-radius-large;
}

.ProductAdminIndexing__header,
.ProductAdminIndexing__body {
  margin: $margin-base-vertical $margin-base-horizontal;
}
