@import 'src/styles/vars';

.Navbar__collapsableDropdown {
  font-size: 1.5rem;
  max-height: $navbar-size;
}

.Navbar__menuButton {
  border-radius: 4px;
  padding: 1px 4px;
  cursor: pointer;

  &:hover i {
    color: $brand-primary;
    text-decoration: none;
  }
}

.Navbar__collapsableDropdown.open {
  .Navbar__menuButton {
    color: $brand-primary;
  }
}

.Navbar__dropdownMenu {
  left: 160px;
  margin-top: 0;
  border-radius: 0;
  border: 0;
  background: $gray-darker;
  padding: 0;
  user-select: none;

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 3px 20px;
    clear: both;
    font-weight: 300;
    color: rgb(222, 222, 222);
    text-align: inherit;
    white-space: nowrap;
    background: none;
    border: 0;

    &:hover {
      color: white;
      text-decoration: none;
      background-color: $brand-primary;
    }

    &:focus {
      color: #2b2d2f;
      text-decoration: none;
      outline: none;
    }
  }
}

.Navbar--PPR.bg-inverse {
  .Navbar__dropdownMenu {
    background: $brand-secondary;
  }
}
