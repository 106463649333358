@import 'src/styles/vars';

.HelpCenter__button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  margin-right: 5px;
  margin-left: 3px;

  &,
  &:focus {
    outline: none;
  }
}

.HelpCenter__helpIcon {
  color: $brand-white;
  padding: 0 2px 0 4px;
  vertical-align: middle;
  font-size: 22px;
  cursor: pointer;

  &.ctxUnderline {
    cursor: pointer;
    border: inherit;
  }
}
