@import 'src/styles/colors';

.ListFilters {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-right: 1px solid $gray-lighterer;

  & > div:first-child {
    flex-grow: 1;
  }
}

.ListFilters__collapse.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-top: 1px solid $gray-lighterer;
  border-radius: 0;
  color: $brand-secondary;

  &:hover {
    color: $brand-primary;
  }

  .mdi {
    font-size: 24px;
  }
}

.ListFilters__collapseLabel {
  flex-grow: 1;
  text-align: center;
  padding-right: 24px;
}
