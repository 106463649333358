@use 'sass:math';

@import 'colors';

// Function
//-- Must be defined before variables
@function rem($multiplier) {
  $font-size: 10px;
  @return $multiplier * $font-size;
}

// Variables
// --------------------------------------------------
$margin-base-horizontal: 20px;
$margin-base-vertical: 10px;
$half-margin-base-horizontal: math.div($margin-base-horizontal, 2);
$margin-base: 30px;
$half-margin-base: math.div($margin-base, 2);

$product-header-height: 105px;
$product-sticky-header-height: 75px;

// Options
// -------------------------
$enable-shadows: false !default;
$enable-hover-media-query: false !default;

// Scaffolding
// -------------------------
$body-bg: $gray-lighter !default;
$input-border-color: $gray-light;
$input-border-color-disabled: $readonly-fg;
$text-color: $gray-dark !default;
$text-color-disabled: $gray-light;

// Links
// -------------------------
$link-color: $brand-primary !default;
$link-hover-color: $brand-secondary !default;

// Typography
// -------------------------
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:
  'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif:
  'Roboto Slab', 'Roboto', Georgia, 'Times New Roman', Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:
  'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$font-size-large: 1.25rem;
$font-size-small: 0.75rem;

$font-size-h1: 2rem; // 32px
$font-size-h2: 1.625rem; // 26px
$font-size-h3: 1.375rem; // 22px
$font-size-h4: 1.15rem; // 16px
$font-size-h5: 0.875rem; //14px
$font-size-h6: 0.75rem;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: 2rem; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 100;
$headings-line-height: 1.1;
$headings-color: inherit;

// Iconography
// -------------------------
$icon-size: 60px;

// Components
// -------------------------
// Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.33;
$line-height-small: 1.5;

$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-small: 3px;
$border-radius-very-large: 14px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: $brand-white;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

// Tables
// -------------------------
// Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;

//** Default background color used for all tables.
$table-bg: transparent;
//** Background color used for `.table-striped`.
$table-bg-accent: $gray-really-light;
//** Background color used for `.table-hover`.
$table-bg-hover: $brand-primary;
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: $input-border-color;

// Buttons
// -------------------------
// For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal;

$btn-primary-color: $brand-white;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $brand-primary;

$btn-secondary-color: $brand-primary;
$btn-secondary-bg: $brand-white;
$btn-secondary-border: $brand-primary;

$btn-success-color: $brand-success;
$btn-success-bg: $brand-white;
$btn-success-border: $brand-success;

$btn-info-color: $gray-dark;
$btn-info-bg: $brand-white;
$btn-info-border: $gray-dark;

$btn-warning-color: $brand-warning;
$btn-warning-bg: $brand-white;
$btn-warning-border: $brand-warning;

$btn-danger-color: $brand-danger;
$btn-danger-bg: $brand-white;
$btn-danger-border: $brand-danger;

$btn-link-disabled-color: $gray-light;

// Forms
// -------------------------

//** `<input>` background color
$input-bg: transparent;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: $gray;
//** `<input>` border color
$input-border: $input-border-color;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
$input-border-radius: $border-radius-base;

//** Border color for inputs on focus
$input-border-focus: $brand-primary;

//** Placeholder text color
$input-color-placeholder: $gray-light;

//** Default `.form-control` height
$input-height-base: 40px; // ($line-height-computed + ($padding-base-vertical * 2) + 2);

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;
//** Disabled cursor for form controls and buttons.
$cursor-disabled: default;

// Dropdowns
// -------------------------
// Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: $brand-white;

//** Divider color for between dropdown items.
$dropdown-divider-bg: $gray-lighterer;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $gray-really-light;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: $brand-black;

// Z-index master list
// -------------------------

// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal: 1040;

// Media queries breakpoints
// -------------------------
// Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// Grid system
// -------------------------
// Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 15px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;

// Container sizes
// -------------------------
// Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);

// Navbar
// -------------------------
// Basics of a navbar
$navbar-height: 2.5rem;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(math.div($grid-gutter-width, 2));
$navbar-padding-vertical: math.div($navbar-height - $line-height-computed, 2);

// Navbar links
$navbar-default-link-color: $brand-white;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%);
$navbar-inverse-bg: $gray-darker;

// Inverted navbar links
$navbar-inverse-link-color: lighten($gray-light, 15%);
$navbar-inverse-link-hover-color: $brand-white;

// Navs
// -------------------------
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

// Tabs
$nav-tabs-border-color: $gray-lighterer;

$nav-tabs-link-hover-border-color: $gray-lighter;

$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: $gray-lighterer;

$nav-tabs-justified-link-border-color: $gray-lighterer;
$nav-tabs-justified-active-link-border-color: $body-bg;

// Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

// Pagination
// -------------------------
$pagination-color: $link-color;
$pagination-bg: $brand-white;
$pagination-border: $gray-lighter;

$pagination-hover-color: $brand-white;
$pagination-hover-bg: $alk-background-hover;
$pagination-hover-border: $pagination-border;

$pagination-active-color: $brand-white;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $pagination-border;

$pagination-disabled-color: $gray-lighter;
$pagination-disabled-bg: $brand-white;
$pagination-disabled-border: $pagination-border;

// Pager
// -------------------------

$pager-bg: $pagination-bg;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

// Jumbotron
// -------------------------

$jumbotron-padding: 30px;
$jumbotron-bg: $gray-lighter;

// Form states and alerts
// -------------------------
// Define colors for form feedback states and, by default, alerts.

$state-success-text: $brand-white;
$state-success-bg: $brand-success;
$state-success-border: $brand-success;

$state-info-text: $brand-white;
$state-info-bg: $brand-info;
$state-info-border: $brand-info;

$state-warning-text: $brand-white;
$state-warning-bg: $gray-darker;
$state-warning-border: $gray-darker;

$state-danger-text: $brand-white;
$state-danger-bg: $brand-danger;
$state-danger-border: $brand-danger;

// Cards
$card-spacer-x: 1.875rem !default;
$card-spacer-y: 0.75rem !default;
$card-border-width: 1px !default;
$card-border-radius: 0;
$card-border-color: $gray-lighterer !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg: $gray-really-light !default;
$card-bg: $brand-white !default;

$card-link-hover-color: $brand-white !default;

// Tooltips
// -------------------------

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: $brand-white;
//** Tooltip background color
$tooltip-bg: $brand-black;
$tooltip-opacity: 0.9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

// Popovers
// -------------------------

//** Popover body background color
$popover-bg: $brand-white;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: rgba(0, 0, 0, 0.2);
//** Popover fallback border color
$popover-fallback-border-color: $gray-slightly-lighter;

//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%);

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color: fadein($popover-border-color, 5%);

// Labels
// -------------------------

//** Default label background color
$label-default-bg: $gray-light;
//** Primary label background color
$label-primary-bg: $brand-primary;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: $brand-white;
//** Default text color of a linked label
$label-link-hover-color: $brand-white;

//** Label tags' height
$label-height: 50px;

// Modals
// -------------------------

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 15px;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: $brand-white;
//** Modal content border color
$modal-content-border-color: $brand-primary;

//** Modal backdrop background color
$modal-backdrop-bg: $brand-black;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.5;
//** Modal header border color
$modal-header-border-color: $brand-primary;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 1160px;
$modal-md: 720px;
$modal-sm: 300px;

// Alerts
// -------------------------
// Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: bold;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

// Progress bars
// -------------------------

//** Background color of the whole progress component
$progress-bg: $gray-really-light;
//** Progress bar text color
$progress-bar-color: $brand-white;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

//** Default progress bar color
$progress-bar-bg: $brand-primary;
//** Success progress bar color
$progress-bar-success-bg: $brand-success;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
//** Info progress bar color
$progress-bar-info-bg: $brand-info;

// List group
// -------------------------

//** Background color on `.list-group-item`
$list-group-bg: $brand-white;
//** List group border radius
$list-group-border-radius: $border-radius-base;

//** Background color of single list items on hover
$list-group-hover-bg: $gray-really-light;
//** Text color of active list items
$list-group-active-color: $component-active-color;
//** Background color of active list items
$list-group-active-bg: $component-active-bg;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

//** Text color of disabled list items
$list-group-disabled-color: $gray-light;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: $gray;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: $gray-dark;

// Panels
// -------------------------

$panel-heading-padding: 10px 15px;

// Thumbnails
// -------------------------

//** Padding around the thumbnail image
$thumbnail-padding: 4px;
//** Thumbnail background color
$thumbnail-bg: $body-bg;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base;

// Wells
// -------------------------

$well-bg: $gray-really-light;

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: $gray-really-light;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light;

// Carousel
// -------------------------
$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

$carousel-control-color: $brand-white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: $brand-white;
$carousel-indicator-border-color: $brand-white;

$carousel-caption-color: $brand-white;

// Close
// -------------------------
$close-font-weight: bold;
$close-color: $brand-black;
$close-text-shadow: 0 1px 0 $brand-white;

// Code
// -------------------------
$code-color: #c7254e;
$code-bg: #f9f2f4;

$kbd-color: $brand-white;
$kbd-bg: $gray-dark;

$pre-bg: $gray-really-light;
$pre-color: $gray-dark;
$pre-border-color: $gray-slightly-lighter;
$pre-scrollable-max-height: 340px;

// Type
// -------------------------
//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
