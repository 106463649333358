@import 'src/styles/vars';

@mixin manufacturerProductValidationStatus() {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;

  &.ItemTree__icon {
    margin-right: 4px;
  }
}

.ProductValidationStatusUnknown {
  border: 1px dashed $gray-light;
  @include manufacturerProductValidationStatus();
}

.ProductValidationStatusKo {
  border: 1px solid $brand-danger;
  @include manufacturerProductValidationStatus();
  background: linear-gradient(
    to bottom,
    white 0%,
    white 65%,
    $brand-danger 65%,
    $brand-danger 100%
  );
}

.ProductValidationStatusOk {
  border: 1px solid $brand-success;
  background-color: $brand-success;
  @include manufacturerProductValidationStatus();
}

.ProductValidationStatusSuggested {
  border: 1px solid $brand-highlight;
  @include manufacturerProductValidationStatus();
  background: linear-gradient(
    to bottom,
    white 0%,
    white 35%,
    $brand-highlight 35%,
    $brand-highlight 100%
  );
}
