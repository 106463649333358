@import 'src/styles/vars';

.main {
  position: fixed;
  z-index: 2147482998;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -3px 0 15px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: panelAnimation;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

@keyframes panelAnimation {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.navContainer {
  display: table;
  width: 100%;
  position: relative;
  height: 40px;
  background-color: #2276c5;
}

.navTitle {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  font-family: sans-serif;
  color: #fff;
  font-weight: bold;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.iframe {
  flex: 1;
  width: 100%;
  border: none;
}

.invalidSalesforceUrl {
  padding: 1rem;
  color: $brand-danger;
  text-align: center;
}
