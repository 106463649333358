@import 'src/styles/vars';

.ValidationStepper {
  position: relative;

  button {
    font-size: x-large;
    padding: 0 3px;

    &:focus:not(:hover),
    &:active:hover {
      background: white;
      border-color: $brand-secondary;
      outline: none;
      .mdi {
        color: $brand-secondary !important;
      }
    }

    &.ValidationStepper__button--left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &.ValidationStepper__button--right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .alkemics-spinner {
    position: relative;
    width: 24px;
  }
}

.Raguel__message--current {
  font-weight: 500;
}
