@import 'src/styles/vars';
@import '../../vars';

.LogisticalHierarchyIcon {
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  position: relative;
  background-color: $gray-light;

  @each $type, $color in $logistical-hierarchy-packages {
    &--#{$type} {
      background-color: $color;
    }
  }

  &--small {
    width: $input-height-base;
    height: $input-height-base;
    padding: 6px;
  }

  &--extra-small {
    width: 30px;
    height: 30px;
    padding: 3px;
  }
}

.LogisticalHierarchyIcon__image {
  width: 100%;
}

.LogisticalHierarchyIcon__quantity {
  position: absolute;
  top: 0;
  right: 0;

  background-color: $gray-dark;
  border-radius: 0.8em;
  color: $brand-white;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.6em;
  margin-bottom: 14px;
  margin-left: 1px;
  min-width: 1.6em;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
}
