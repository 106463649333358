.RetailerRefuseModal__body {
  display: flex;
  width: 70vw;
}

.RetailerRefuseModal__label {
  width: 140px;
  text-align: right;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.RetailerRefuseModal__textarea {
  flex: 1;

  textarea {
    width: 100%;
    height: 200px;
    padding: 0.5rem;
    resize: none;
  }
}
