@import 'src/styles/vars';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > label {
    margin: 0 1rem 0 2.5rem;
  }
}

.relativeWrapper {
  position: relative;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 0.5rem;
  border: 1px solid var(--grays-brand-default-disabled, #979493);
}

.inputValue {
  font-weight: 300;
  color: $brand-default;
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close {
  background: none;
  border: none;
}

.panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  background-color: $brand-white;
  border: 1px solid $gray-lighter;
  border-radius: 8px;
}

.panelHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  gap: 0.5rem;
  border: 1px solid $brand-default-disabled;
}

.searchIcon {
  font-size: 1.5rem;
}

.searchInput {
  flex-grow: 1;
  font-weight: 300;
  border: none;
  color: $brand-default;

  &::placeholder {
    color: $text-disabled-color;
  }
}

.hidden {
  visibility: hidden;
}

.itemList {
  display: flex;
  flex-direction: column;
  height: 10.7rem;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  height: 2.15rem;
  border-top: 1px solid $gray-lighter;
}

.itemClickable {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemText {
  margin: 0 0 0.5rem 0.5rem;
  font-weight: 300;
  color: $brand-default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
