@import 'src/styles/vars';

.BulkEditModal__footerStatus {
  color: $brand-secondary;
}

.BulkEditModal__footerButtons {
  position: absolute;
  top: 0%;
  bottom: 0%;
  display: flex;
  align-items: center;
}

.BulkEditModal__footerButtons--left {
  left: 0%;
  button {
    margin-left: $half-margin-base;
  }
}

.BulkEditModal__footerButtons--right {
  right: 0%;
}
