@import 'src/styles/vars';

.MaturityPanelDeadlines__dateSections {
  margin-top: 16px;
  gap: 24px;
}

.MaturityPanelDeadlines__dateHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  color: $brand-default;
  border-bottom: solid $gray-lighterer 2px;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 8px;
  padding-bottom: 3px;
}

.MaturityPanelDeadlines__overdueFlag {
  color: white;
  font-size: 0.9rem;
  font-weight: 400;
  background: $brand-danger;
  border-radius: 100px;
  margin-left: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.MaturityPanelDeadlines_recipients {
  gap: 8px;
}

.MaturityPanelDeadlines__recipientHeader {
  color: $gray;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 3px;
}

.MaturityPanelDeadlines_maturities {
  gap: 8px;
}
