.media-errors {
  .Raguel__block {
    flex-direction: column;
    padding: 10px;

    .Raguel {
      padding: 5px;
    }
  }
}
