@import 'src/styles/vars';

$action-statuses: (
  // NOT_STARTED
  1: $brand-primary,
  // TO_REVIEW
  2: $brand-primary,
  // REVIEWED
  3: $brand-success,
  // SKIPPED
  4: $brand-primary,
  // REVIEWING
  5: $brand-inprogress,
  // CHANGE_REQUESTED
  6: $brand-highlight-secondary,
  // UPDATED
  7: $brand-highlight-secondary,
  // VALIDATED
  8: $brand-success,
  // REJECTED
  9: $brand-danger
);
