@import 'src/styles/vars';

.RaguelInternalTaxonomy__suggestionContainer {
  color: $gray-dark;
  width: 100%;
}

.RaguelInternalTaxonomy__suggestion {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.RaguelInternalTaxonomy__label {
  border-radius: $border-radius-base;
  padding: $padding-xs-vertical * 2 $padding-xs-horizontal;
  color: $brand-white;
  background-color: $gray-light;
  margin-right: $half-margin-base-horizontal;
  &--error {
    background-color: $brand-primary !important;
  }
}

.RaguelInternalTaxonomy__suggestionActionContainer {
  display: inline-flex;
}

.RaguelInternalTaxonomy__suggestionAction {
  margin-left: 20px;
  &.btn {
    padding: initial;
  }
}

.RaguelInternalTaxonomy__suggestionIcon {
  color: $brand-primary !important;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-right: 2px;
}

.RaguelInternalTaxonomy__suggestionLabel {
  display: inline-flex;
  align-items: center;
}

.RaguelInternalTaxonomy__suggestionTitle {
  color: $gray-medium;
}

.RaguelInternalTaxonomy__suggestionMessage {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.RaguelInternalTaxonomy__suggestionTooltip {
  padding-left: 0.4rem;
}

.RaguelInternalTaxonomy__copy {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.4s;
}

.RaguelInternalTaxonomy__copyVisible {
  opacity: 1;
}

.RaguelInternalTaxonomy__copyMessage {
  font-size: 12px;
  line-height: 24px;
  height: 28px;
  padding: 2px 8px;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.RaguelInternalTaxonomy__copyIcon {
  color: $brand-success;
}
