@import 'src/styles/vars';

.Navbar {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  padding: 0 $container-fluid-margin;
  height: $navbar-size;

  &.bg-inverse {
    background: $navbar-inverse-bg;
    color: $navbar-inverse-color;
    a.nav-link {
      display: inline-flex;
      align-items: center;
      color: $navbar-inverse-color;
      white-space: nowrap;

      &:hover,
      &.active,
      &.active:hover {
        color: $brand-primary;
      }
    }
  }
}

.Navbar__logoLink.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 1rem 0 0;
}

.Navbar__logo,
.Navbar__secondaryLogo {
  height: 30px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.Navbar__productDnaLogo {
  height: 22px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.Navbar__secondaryLogo {
  margin-left: 1rem;
}

.Navbar__menu {
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  line-height: $navbar-size;
  height: $navbar-size;

  &.Navbar__menu--full {
    position: fixed;
    top: $navbar-size * -2;
    visibility: hidden;
    flex-wrap: nowrap;
  }

  & > li.nav-item,
  .Navbar__moreDropdown li {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    float: none !important;
    height: $navbar-size;

    a.nav-link {
      display: inline-flex;
      align-items: center;
      color: inherit;
      padding: 0 1rem;
      height: $navbar-size;
      line-height: $navbar-size;
      white-space: nowrap;

      &.active {
        &,
        &:hover,
        &:active,
        &:visited,
        &:focus,
        & > a {
          color: $brand-primary;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 1px;
          right: 1px;
          height: 3px;
          background-color: $brand-primary;
        }
      }
    }
  }
}
.Navbar__menuright {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  line-height: $navbar-size;

  > * {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    height: $navbar-size;
  }

  > .nav-item {
    float: none !important;
  }

  .mdi {
    display: flex;
    align-items: center;
    max-height: $navbar-size;
  }

  a {
    text-decoration: none !important;
  }
}
.dropdown-children {
  background-color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 100%;
  top: 0;
  box-shadow: 0px 0px 6px -3px rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 0;
  padding: 0;
}
.Navbar__dropdown {
  user-select: none;
  > a {
    color: #fff;
  }
  a.dropdown-toggle:after,
  button.dropdown-toggle:after {
    content: initial;
  }
  .dropdown-menu {
    right: 5px;
    margin-top: -2px;
    box-shadow: 0px 0px 6px -3px rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 0;
    padding: 0;
    &:before {
      position: absolute;
      top: -5px;
      right: 2px;
      display: block;
      content: '';
      border: 5px solid #fff;
      border-top: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    a {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: $gray-darker;
      &:hover {
        background: $brand-primary-active;
        color: #fff;
      }
    }
    i {
      margin-right: 15px;
    }
  }
  a.dropdown-item-reverse {
    color: #fff;
    background: $gray-darker;
    &:hover {
      background: $brand-primary-active;
      color: #fff;
    }
  }
  a.dropdown-item-reverse.no-hover:hover {
    background: $gray-darker;
  }
  i.mdi {
    vertical-align: middle;
    font-size: 22px;
    margin-left: -4px;
  }
}
.Navbar__support {
  margin-right: 20px;
}

.Navbar__user,
.HelpCenter {
  .dropdown-toggle {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;

    i.mdi {
      &.mdi-account,
      &.mdi-chevron-down {
        color: #fff;
        line-height: $navbar-size;
      }
      &.mdi-account {
        font-size: 28px;
        margin-left: 3px;
        margin-right: 0;
      }
    }
  }
}

.navbar-nav .nav-item + .nav-item {
  margin-left: 0;
}

.Navbar--PPR.bg-inverse {
  background: $brand-secondary;
  .Navbar__user {
    > a {
      img {
        cursor: pointer;
        filter: brightness(1.13);
        height: auto;
        margin-top: -3px;
      }
    }
  }

  .Navbar__moreDropdown {
    background: $brand-secondary;
  }
}

.Navbar__resources {
  margin-right: 5px;
  margin-left: 3px;
}

.Navbar__more {
  position: relative;
}

.Navbar__moreIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  width: $navbar-size;
  height: $navbar-size;

  &:hover {
    color: $brand-primary;
  }
}

.Navbar__moreDropdown {
  @include ul();
  position: absolute;
  top: $navbar-size - 1;
  right: 50%;
  background: $navbar-inverse-bg;
  padding: 0 0.5rem 0.5rem 0.5rem;
  transform: translateX(50%);
  z-index: 9999;
}
