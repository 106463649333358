@import './mixins';
@import './colors';

html,
body,
#app {
  width: 100%;
  height: 100%;
}

@media (max-width: 1300px) {
  html {
    font-size: 14px !important;
  }
}

body {
  font-weight: 300;
  overflow-y: scroll;
  overflow-x: hidden;
}

button.btn {
  font-weight: 300;
}

.imo-table-header-actions {
  button,
  input {
    color: $gray-darker;
  }
}
