@import 'src/styles/colors';

.CatalogAssignationActionColumn {
  vertical-align: top !important;
  font-size: 0;
}

.CatalogAssignationActionColumn__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  background-color: $brand-white;
  padding: 0 0.5rem;
  font-size: 1rem;
  height: 66px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid $gray-lighterer;
  margin-top: -1px;
}

.ListTableRow:first-child {
  .CatalogAssignationActionColumn__content {
    border-top: 1px solid $gray-lighterer;
  }
}

.CatalogTableRow:hover {
  .CatalogAssignationActionColumn__content {
    background-color: $alk-background-hover !important;
  }
}

.CatalogAssignationActionColumn__question,
.CatalogAssignationActionColumn__answer {
  text-align: center;
  text-align: -webkit-center;
}

.CatalogAssignationActionColumn__question {
  min-width: 100%;
  padding-bottom: 0.2rem;
  white-space: normal;
}

.CatalogAssignationActionColumn__btn {
  padding: 0 0.5rem;
  white-space: nowrap;
}

.CatalogAssignationActionColumn__disabledAction {
  display: inline-flex;
  align-items: center;
  color: $gray-medium;
  cursor: default;

  & > .mdi {
    color: $brand-highlight;
    margin-left: 0.25em;
  }
}
