@import 'src/styles/vars';

.InputGln {
  position: relative;
}

.InputGln__SideElements {
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
}
