@import 'src/styles/vars';

$autocomplete-height: 30px;
$autocomplete-min-width: 200px;
$autocomplete-list-height: 150px;

.Autocomplete--pnq {
  display: block;
  width: 100%;
  min-width: $autocomplete-min-width;
  padding-bottom: 1px;
  // border-bottom: 1px solid $gray-light;
}
.Autocomplete--pnq__input {
  position: relative;
  display: block;
}
.Autocomplete--pnq__inputSearch {
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  border: 1px solid $gray-light;
  border-radius: 3px;
  padding: 8px 14px;
  margin: 0;
  box-shadow: none !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &.opened {
    border-radius: 3px 3px 0 0;
  }
}
.Autocomplete--pnq__searchIcon {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 20px;
  cursor: default;
  transform: translateY(-50%);
}
.Autocomplete--pnq__list {
  position: absolute;
  visibility: hidden;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  padding: 0;
  max-height: $autocomplete-list-height;
  overflow: auto;
  z-index: 1;
  &.Autocomplete__list--opened {
    visibility: visible;
    border: 1px solid $gray-light;
    background: white;
  }
  li {
    cursor: pointer;
    padding: 8px 15px;
    list-style: none;
    &:hover,
    &.selected {
      background: $alk-background-hover;
    }
  }
}
