.button {
  position: fixed;
  z-index: 2147482997;
  top: 50lvh;
  right: 0;
  transform: translate3d(50%, -50%, 0) rotate(270deg) translateY(-50%);
  transform-origin: center;
  color: #ffffff;
  background-color: #fabf2d;
  padding: 10px 15px;
  border: 0;
  font-size: 13px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  animation-duration: 0.5s;
  animation-name: buttonAnimation;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

@keyframes buttonAnimation {
  0% {
    opacity: 0;
    right: -40px;
  }
  to {
    opacity: 1;
  }
}
