@import 'src/styles/vars';

.ListSimpleFilterItem {
  .ReactUiCheckbox {
    width: 100%;
    align-items: center;
  }
}

.ListSimpleFilterItem__content {
  display: flex;
  align-items: center;
}

.ListSimpleFilterItem__icon + .ListSimpleFilterItem__label {
  padding-left: 0.5rem;
}

.ListSimpleFilter__header {
  color: $gray-light;
  padding-bottom: 0.5rem;
  font-style: italic;
}
