.ReportingStackedBarChart__values {
  display: flex;
  align-items: stretch;
  border-radius: 4px;
  overflow: hidden;
  padding-top: 1rem;
}

.ReportingStackedBarChart__legend {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 0.4rem;
  padding-bottom: 1rem;
}

.ReportingStackedBarChart__legendItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }

  & > i {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-bottom: 0.1rem;
    border-radius: 2px;
  }

  & > span {
    display: inline-block;
    white-space: nowrap;
    padding-left: 0.25rem;
  }
}
