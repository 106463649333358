@import 'src/styles/colors';

.CatalogColumnSelectorOption {
  &:hover {
    background-color: $alk-background-hover;
  }

  .ReactUiCheckbox {
    display: flex;
    padding: 0.5rem 1rem;
    margin: 0;
  }

  .ReactUiCheckbox__label {
    white-space: nowrap;
  }
}
