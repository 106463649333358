@import 'src/styles/vars';

.ScheduledApplicationFilter {
  &_DatePicker {
    position: relative;

    .InputDate__calendar {
      width: 230px;
    }
    .DateRangePicker__Month {
      width: 226px;
      margin: 0;
    }
  }

  &_DeselectAll {
    padding: $padding-base-vertical 0;
  }
}
