@import 'src/styles/vars';

.RetailerValidationDashboard {
  background-color: $gray-lightest;
  border: 1px solid $gray-border;
  padding: $margin-base;
  display: flex;
  flex-direction: column;
  & .ValidationResults {
    max-width: inherit;
  }
}

.RetailerValidationDashboard--loading {
  flex-direction: row;
  justify-content: space-between;
  opacity: 0.5;
}

.RetailerValidationDashboard__title {
  margin-bottom: $half-margin-base;
}
