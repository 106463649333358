@import 'src/styles/vars';

.RaguelWarning__suggestionContainer {
  color: $gray-dark;
  width: 100%;
}

.RaguelWarning__suggestion {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.RaguelWarning__label {
  border-radius: $border-radius-base;
  padding: $padding-xs-vertical * 2 $padding-xs-horizontal;
  color: $brand-white;
  background-color: $gray-light;
  margin-right: $half-margin-base-horizontal;
  &--error {
    background-color: $brand-danger;
  }
}

.RaguelWarning__suggestionActionContainer {
  display: inline-flex;
}

.RaguelWarning__suggestionAction {
  margin-left: 20px;
  &.btn {
    padding: initial;
  }
}

.RaguelWarning__suggestionIcon {
  color: $brand-highlight;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-right: 2px;
}

.RaguelWarning__suggestionLabel {
  display: inline-flex;
  align-items: center;
}
