@import 'src/styles/vars';

.wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 400px;
}

.main {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem 0 1.75rem;
  border: 1px solid hsla(214, 19%, 93%, 1);
  width: 50%;

  @media (width > $screen-md) {
    width: 70%;
  }
}

.assets {
  display: flex;
  flex-direction: row;
  height: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: auto;
  padding: 0 1rem 1rem 1rem;
}

.asideContainer {
  width: 50%;
  overflow: hidden;
  margin-right: 1.5rem;

  @media (width > $screen-md) {
    width: 30%;
  }
}
