@import 'src/styles/colors';
@import 'src/styles/vars';

.InformationRequests__supplierDetails {
  padding-left: 2em;
}
.InformationRequests__supplierManuf {
  font-weight: bold;
}
.InformationRequests__supplierDate {
  font-size: 0.6rem;
}
.InformationRequests__supplierMessage {
  font-style: italic;
}
.InformationRequests__titleMessage {
  padding-top: 1em;
  font-size: 1.1rem;
}
.InformationRequests__supplierDetailsSpacing {
  padding-top: 1em;
}
.InformationRequests__hintMessage {
  font-size: 0.9rem;
}
.InformationRequests__errorEntities {
  @include ul();
  margin-top: 1em;
  padding: 1rem;
  max-height: 30vh;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba($brand-danger, 0.1);
  border: 1px solid $brand-danger;
  border-radius: 4px;
}
