@import 'src/styles/colors';

.CollapsedListFilters {
  display: flex;
  flex-direction: column;
  color: $brand-secondary;
}

.CollapsedListFilters__action.btn {
  background-color: transparent;
  color: $brand-secondary;
  padding: 1.5rem 0.5rem;
  border-width: 1px 0;
  border-bottom: 1px solid $gray-lighterer;
  border-radius: 0;

  &:hover {
    color: $brand-primary;
  }

  .mdi-plus-circle {
    font-size: x-large;
  }
}

.CollapsedListFilters__selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }

  & > span {
    display: flex;
    align-items: center;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
}
