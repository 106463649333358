@import 'src/styles/vars';

.AddRecipientButton {
  border: 1px dashed #3fa2b2;
  margin: 0;
  margin-bottom: calc(100vh - 425px);
  padding: $half-margin-base;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .ButtonText {
    color: #3fa2b2;
    font-size: 22px;
    padding-top: 3px;
    cursor: pointer;

    i {
      margin-right: $half-margin-base;
    }
  }

  .ViewAsSelectMultiple__search {
    background: white;
  }
}
