@import 'src/styles/vars';

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.2rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 10px;
  background-color: $brand-danger;
  font-size: 0.6rem;
  color: $brand-white;

  &--large {
    padding: 0 0.4rem;
    height: 1.5rem;
    font-size: 1rem;
  }
}
