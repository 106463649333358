@import 'src/styles/vars';

.ListSelectedFilters {
  width: 100%;
  padding: 1rem;
  background-color: $alk-background-hover;
}

.ListSelectedFilters_remove {
  margin-bottom: 0.5rem;
}

.ListSelectedFilters__list {
  @include ul();
  width: 100%;
}
