@import 'src/styles/vars';

.ProductPictureForm {
  .Raguel {
    padding: 1em;
  }
}

.ProductPictureForm__data {
  display: flex;
  margin-top: $half-margin-base;
}

.ProductPictureForm__form {
  flex-basis: 60%;
}

.ProductPictureForm__image {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: $margin-base-horizontal;
  img {
    max-width: 100%;
    max-height: 300px;
  }
}

.ProductPictureForm__imageMetadata {
  margin-top: $half-margin-base;
  width: 100%;
}

.ProductPictureForm__imageMetadataDetail {
  margin-bottom: $half-margin-base;
  width: 100%;
}

.ProductPictureForm__imageMetadataTitle {
  font-size: small;
  font-weight: 400;
}

.ProductPictureForm__imageMetadataSuggestion {
  color: $brand-highlight-secondary;

  > i {
    margin-right: 2px;
    color: $brand-highlight;
  }
}

.ProductPictureForm__scope {
  height: 42px;
  padding-top: 15px;
}
