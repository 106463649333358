@import 'src/styles/vars';

$indent: 40px;

.LogisticalHierarchy {
  max-width: 100%;
}

.LogisticalHierarchy__title {
  font-size: 1.1rem;
}

.LogisticalHierarchy__level--root {
  flex-grow: 1;
}

.LogisticalHierarchy__level {
  flex-grow: 1;
  max-width: calc(100% - #{$indent});

  &::before {
    position: absolute;
    margin-left: -$indent;
    content: '';
    border-bottom: 1px solid $brand-secondary;
    width: $indent;
    height: 50px;
  }

  &--addNewLevel::before {
    border-bottom-color: $brand-primary;
    border-bottom-style: dashed;
  }
}

.LogisticalHierarchy__child,
.LogisticalHierarchy__child--last {
  display: flex;
  margin-left: $indent;
  max-width: 100%;
}

.LogisticalHierarchy__child::before {
  content: '';
  border-left: 1px solid $brand-secondary;
  width: $indent;
}

.LogisticalHierarchy__child {
  &--last::before {
    content: '';
    border-left: 1px solid $brand-secondary;
    width: $indent;
    height: 50px;
  }

  &--last--addNewLevel::before {
    content: '';
    border-left: 1px dashed $brand-primary;
    width: $indent;
    height: 50px;
  }
}

.LogisticalHierarchy__addNewLevel {
  display: flex;
  height: 80px;
  align-items: center;
  border: 1px dashed $brand-primary;
  border-radius: $border-radius-large;
  margin-top: 10px;
  padding-left: 10px;
  max-width: 100%;
  color: $brand-primary;

  .mdi {
    font-size: x-large;
    padding-right: $padding-base-horizontal;
  }

  .LogisticalHierarchy__addNewLevel__label {
    padding-right: $padding-base-horizontal;
  }
}
