@import 'src/styles/vars';

.InputReference {
  position: relative;
}

.InputReference__SideElements {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: inline-flex;
  align-items: center;
}
