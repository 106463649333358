@import 'src/styles/vars';

.AdminRuleResultRow {
  margin: 0 -20px;
  padding: 4px;
}

.AdminRuleCheck__modalToggle {
  display: flex;
  align-items: center;
}

.AdminRuleResultRow:nth-child(even) {
  background-color: $gray-really-light;
}

.AdminRuleCheck__body {
  padding-bottom: unset;
  margin-bottom: 70px;
}

.AdminRuleCheck__filterContainer {
  margin: 0 -20px;
  margin-bottom: 15px;
  padding: 4px;
}

.AdminRuleCheck__filterButtonsContainer {
  margin-bottom: 15px;
}

.AdminRuleCheck__filterButton {
  margin-left: 5px;
  margin-right: 5px;
}
