@import 'src/styles/vars';

.AddLabel__col {
  overflow: auto;
}

.AddLabel__category {
  padding: $padding-large-vertical $padding-large-horizontal;
  line-height: $line-height-large;
  cursor: pointer;
  &:hover {
    background-color: $brand-secondary-hover;
  }
}

.AddLabel__category--selected {
  background-color: $alk-background-hover;
}

.AddLabel__categoryLabel {
  font-weight: 500;
}

.AddLabel__categoryCount {
  color: $gray-medium;
}

.AddLabel__label {
  margin: 5px 0;
}

.AddLabel__search {
  line-height: $line-height-large;
  margin: 0 0 0 $margin-base-horizontal;
}

.AddLabel__icon {
  margin-right: 2px;
}

.AddLabel__messageContainer {
  display: flex;
}
.AddLabel__message {
  margin: auto;
}
