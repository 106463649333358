@import 'src/styles/vars';

.HierarchyIcon__Image {
  width: 100%;
  padding: 20%;
}

.HierarchyIcon__NumberCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 50%;
  width: 30%;
  color: white;
  padding: 5%;
  position: absolute;
  top: 0;
  right: 0;
}

.HierarchyIcon__NumberCircle::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.HierarchyIcon__Icon {
  border-radius: 50%;
  width: 100%;

  &.HierarchyIcon__DISPLAY_SHIPPER {
    background-color: $brand-inprogress;
  }

  &.HierarchyIcon__EACH {
    background-color: $brand-highlight;
  }

  &.HierarchyIcon__PALLET {
    background-color: $brand-success;
  }

  &.HierarchyIcon__CASE {
    background-color: $brand-primary;
  }

  &.HierarchyIcon__PACK {
    background-color: $brand-highlight-secondary;
  }

  &.Disabled {
    background-color: $gray-light;
  }
}
