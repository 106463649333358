@use 'sass:math';

@import 'src/styles/vars';

.LabelList {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.LabelList__item {
  flex-basis: 33%;
  padding: math.div($half-margin-base, 2) $half-margin-base-horizontal;
  &--small {
    flex-basis: 50%;
  }
}

.LabelList__item__content {
  position: relative;
  .ConceptLabel__delete {
    right: -6px;
  }
}

.LabelList__kindWarning {
  font-style: italic;
}
