@import 'src/styles/vars';

$button-lg-icon-size: 1.2rem;

@mixin button-nav-colors(
  $color: #000,
  $background: $brand-white,
  $arrow-color: #fff,
  $arrow-background: $gray-light
) {
  color: $color;
  background: $background;
  &:before {
    border-top-color: darken($arrow-background, 4%);
  }
}

.AnchorNavItem {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  outline: 0;
  border: 0;
  padding-left: $button-lg-icon-size;
  @include button-nav-colors();
}

.AnchorNavItem:not(.AnchorNavItem--active):hover {
  @include button-nav-colors(
    $color: $brand-primary,
    $arrow-background: $brand-secondary
  );
  z-index: 1;
}

.AnchorNavItem--active {
  @include button-nav-colors(
    $color: #fff,
    $background: $brand-secondary,
    $arrow-background: darken($brand-secondary, 4%)
  );
}

.AnchorNavItem--level-1 {
  font-weight: 400;
}
