@use 'sass:math';

@import './mixins';
@import './colors';
@import './bootstrap_variables';

.alk-relative {
  position: relative;
}

.alk-list {
  @include ul();
}

// Text styling
.alk-txt-center {
  text-align: center;
}

.alk-txt-right {
  text-align: right;
}

.alk-txt-danger {
  color: $brand-danger;
}

.alk-txt-orange {
  color: $brand-orange;
}

.alk-txt-success {
  color: $brand-success;
}

.alk-txt-gray-light {
  color: $gray-light;
}

.alk-txt-primary {
  color: $brand-primary;
}

.alk-txt-highlight {
  color: $brand-highlight;
}

::placeholder {
  color: $gray-light;
}

.alk-txt-gray {
  color: $gray-medium;
}

.alk-txt-muted {
  @extend .alk-txt-gray;
  font-style: italic;
}

.alk-margin {
  &-right {
    margin-right: $half-margin-base-horizontal;

    &--large {
      margin-right: $margin-base-horizontal;
    }

    &--small {
      margin-right: math.div($half-margin-base-horizontal, 2);
    }
  }

  &-bottom,
  &-top {
    margin-bottom: $margin-base-vertical;

    &--small {
      margin-bottom: math.div($margin-base-vertical, 2);
    }

    &--large {
      margin-bottom: $margin-base-vertical * 2;
    }
  }

  &-top {
    margin-top: $margin-base-vertical;

    &--small {
      margin-top: math.div($margin-base-vertical, 2);
    }

    &--large {
      margin-bottom: $margin-base-vertical * 2;
    }
  }

  &-left {
    margin-left: $half-margin-base-horizontal;

    &--large {
      margin-left: $margin-base-horizontal;
    }

    &-1 {
      margin-left: 1rem;
    }
  }
}

// Button-like thingies
.alk-clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.alk-cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.alk-clickable-icon {
  font-size: $font-size-h3;
  color: $gray-medium;

  &:hover {
    color: $brand-secondary;
  }
}

button[disabled] .alk-clickable-icon {
  color: $gray-lighter;
  &:hover {
    color: $gray-lighter;
  }
}

.alk-clickable-icon--secondary {
  color: $brand-secondary;

  &:hover {
    color: $brand-secondary-hover;
  }
}

.alk-clickable-icon--white {
  color: $brand-white;

  &:hover {
    color: $gray-lightest;
  }
}

.alk-btn-phantom {
  border: 0;
  background: transparent;
}

.alk-inline-block {
  display: inline-block;
}

// Flex
.alk-flex {
  display: flex;
  min-height: 0;
  min-width: 0;

  &-1 {
    flex: 1;
  }
  &-shrink-0 {
    flex-shrink: 0;
  }
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
  &-center {
    align-items: center;
  }
  &-start {
    align-items: start;
  }
  &-baseline {
    align-items: baseline;
  }
  &-justify-center {
    justify-content: center;
  }
  &-justify-right {
    justify-content: flex-end;
  }
  &-align-self-end {
    align-self: flex-end;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-half-margin-horizontal {
    > * + * {
      margin-left: $half-margin-base-horizontal;
    }
  }
  &-margin-horizontal {
    > * + * {
      margin-left: $margin-base-horizontal;
    }
  }
  &-v-center {
    align-items: center;
    flex-direction: column;
  }

  &-grow {
    flex: 1;
  }
}
.alk-inline-flex {
  display: inline-flex;
  min-height: 0;
  min-width: 0;
}

// Align
.alk-text-align {
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

.alk-font-weight {
  &--normal {
    font-weight: 300;
  }
  &--bold {
    font-weight: 500;
  }
}

.alk-overflow {
  &--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Spinner
.loader {
  display: flex;
  align-items: center;
  > .Spinner {
    margin-right: $half-margin-base-horizontal;

    &.Spinner--small {
      margin-right: math.div($half-margin-base-horizontal, 2);
    }
  }
}

.alk-padding {
  &-base {
    padding: $padding-base-vertical $padding-base-horizontal;
  }

  &-large {
    padding: $padding-large-vertical $padding-large-horizontal;
  }

  &-v-base {
    padding: $padding-base-vertical 0;
  }

  &-v-large {
    padding: $padding-large-vertical 0;
  }

  &-top-large {
    padding-top: $padding-large-vertical;
  }

  &-top {
    padding-top: $padding-base-vertical;

    &-1 {
      padding-top: 1rem;
    }
    &--small {
      padding-top: $padding-small-vertical;
    }
  }

  &-bottom {
    padding-bottom: $padding-base-vertical;

    &-1 {
      padding-bottom: 1rem;
    }
    &-large {
      padding-bottom: $padding-large-vertical;
    }
    &--small {
      padding-top: $padding-small-vertical;
    }
  }
}

.card.alk-card-no-border {
  border: 0;
}

.scroller {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.text-align {
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}
