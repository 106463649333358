@import 'src/styles/colors';

.StatusBar {
  background-color: $status-default-color;
  width: 5px;

  &.StatusBar--filter {
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &.StatusBar--NOT_ATTRIBUTED {
    background-color: $status-not-attributed;
  }

  &.StatusBar--ATTRIBUTION_PENDING {
    background-color: $status-attribution-pending;
  }

  &.StatusBar--ARCHIVED {
    background-color: $status-archived;
  }

  &.StatusBar--ACCEPTED {
    background-color: $status-accepted;
  }

  &.StatusBar--PENDING {
    background-color: $status-pending;
  }

  &.StatusBar--UPDATED {
    background-color: $status-updated;
  }

  &.StatusBar--VALIDATION_PENDING {
    background-color: $status-validation-pending;
  }

  &.StatusBar--VALIDATED {
    background-color: $status-validated;
    opacity: 0.3;
  }

  &.StatusBar--DANGER {
    background-color: $status-danger;
  }

  &.StatusBar--SUCCESS {
    background-color: $status-success;
  }
}
