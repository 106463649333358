@import 'src/styles/vars';

.ProductPage__loading {
  text-align: center;
  position: absolute;
  top: calc(50% - 100px);
  left: 0;
  right: 0;
  bottom: 0;
}

.ProductPage__loadingText {
  font-weight: 100;
  font-size: $font-size-h3;
  padding: $margin-base 0;
}
