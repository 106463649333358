@import 'src/styles/vars';

.Dropzone__label {
  color: $brand-primary;
  font-size: $font-size-large;
}

.Dropzone__button {
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    color: $brand-primary;
    font-size: $font-size-large;
  }
  & > button {
    margin-left: $half-margin-base-horizontal;
    font-size: $font-size-large;
  }
}
