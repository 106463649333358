@import 'src/styles/colors';

.LinkButton {
  color: $brand-primary;
  border: none;
  border-color: $brand-primary;

  &:hover {
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &.LinkButton--secondary {
    color: $brand-secondary;
    border-color: $brand-secondary;

    &:hover {
      color: $brand-primary;
      border-color: $brand-primary;
    }
  }

  &.LinkButton--border {
    border-width: 1px;
    border-style: solid;
    padding: 0.25rem 1rem;
  }
}

.LinkButton__icon {
  font-size: 1.5em;
  line-height: 1.5em;
  padding-right: 0.25rem;
}
