@import 'src/styles/vars';

.specific-asset-row {
  padding-top: $half-margin-base;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.specific-asset {
  padding: 0.5em;
  width: 50%;
}

.specific-asset-wrapper {
  border: 1px solid $gray-light;
  padding-bottom: 4rem;
  height: 100%;
  position: relative;
}

@media all and (min-width: 50em) {
  .specific-asset {
    width: 50%;
  }
}

@media all and (min-width: 70em) {
  .specific-asset {
    width: 33.33%;
  }
}

.specific-asset-disabled {
  .ProductPictureThumbnail__image,
  .ProductPictureThumbnail__mainInformation,
  .ProductPictureThumbnail__tags,
  .ProductPictureThumbnail__secondaryInformation {
    opacity: 0.5;
  }
}

.specific-asset-toggle-active {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  .SwitchButton {
    margin: auto;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.asset-not-active {
  text-align: center;
  font-style: italic;
  font-weight: 100;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 28px;
}

.specific-asset-disabled .asset-not-active {
  background: $gray-lighter;
}
