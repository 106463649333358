@use 'sass:math';

@import 'src/styles/vars';

.BulkEditModalSave__status {
  text-align: center;
  margin: $margin-base 0;
  &--success {
    font-weight: 500;
    color: $brand-success;
  }
  &--error {
    font-weight: 500;
    color: $brand-danger;
  }
}

.BulkEditModalSave__errors {
  margin-top: $margin-base;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BulkEditModalSave__error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $half-margin-base 0;
  border-top: 1px solid $gray-lighter;
}

.BulkEditModalSave__errorProduct {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  .ProductReference {
    margin-bottom: 0;
  }
}

.BulkEditModalSave__errorSeparator {
  margin-left: $half-margin-base-horizontal;
  margin-right: $half-margin-base-horizontal;
  font-weight: 500;
}

.BulkEditModalSave__errorMessages {
  margin-top: math.div($half-margin-base, 2);
  .ProductError {
    justify-content: flex-start;
  }
  .ProductError__error {
    color: $brand-danger;
    font-weight: 500;
  }
  .ProductError__field {
    flex-basis: initial;
  }
}
