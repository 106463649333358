@import 'src/styles/vars';

.DiffValue {
  white-space: pre-wrap;
}

.DiffValue__empty {
  color: $gray-light;
  font-style: italic;
}

.DiffValue__notHandledDesc {
  font-style: italic;
  padding-bottom: 0.5rem;
}

.DiffValue__notHandledValue {
  white-space: pre-wrap;
  word-break: break-all;
  margin: 0;
}
