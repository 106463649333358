@import 'src/styles/vars';

.Reporting__tabs {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  background: white;
  justify-content: center;
  align-items: stretch;
  z-index: 1;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.Reporting__tab {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  text-transform: uppercase;
  color: $gray-dark;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &:hover,
  &.Reporting__tab--selected {
    color: $brand-primary;
    border-color: $brand-primary;
  }

  &.Reporting__tab--selected {
    cursor: default;
  }

  &,
  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &.Reporting__tab--admin,
  &.Reporting__tab--admin.Reporting__tab--selected,
  &.Reporting__tab--admin.Reporting__tab--selected:hover {
    position: relative;
    color: $brand-warning;
    &:after {
      content: 'admin';
      position: absolute;
      display: block;
      right: 0.2rem;
      bottom: 0.1rem;
      font-size: 0.6rem;
    }
  }
  &.Reporting__tab--admin.Reporting__tab--selected,
  &.Reporting__tab--admin:hover {
    border-color: $brand-warning;
  }
}

.Reporting__body {
  position: relative;

  .ManufacturerReporting,
  .RetailerReportingView {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow: auto;
  }

  .ReportingList {
    padding-top: 0.5rem;
  }
}

.Reporting__spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 0;
}
