@import 'src/styles/vars';

$button-lg-padding: 0.5rem;
$button-lg-icon-size: 1.2rem;

.AnchorNavItem__icon {
  display: inline-block;
  position: relative;
  left: -$button-lg-icon-size - $button-lg-padding;
  font-size: $button-lg-icon-size;
  width: 0;
  opacity: 0.7;
}
