@import 'src/styles/vars';

.ProductSegmentsModal__body .DropdownTree .Dropdown__Node {
  .Dropdown__button {
    width: auto;
    padding-right: 2em;
  }
  .Dropdown__list {
    width: auto;
  }
}
