@use 'sass:math';

@import 'src/styles/vars';

.FormSelect {
  &.FormSelect--invalid {
    input {
      border-color: $brand-danger;
    }
  }
  &.FormSelect--valid.FormSelect--dirty {
    select {
      border-color: $brand-success;
    }
  }
}

.FormSelect__help {
  margin-left: math.div($margin-base-horizontal, 3);
  top: 0;
  min-width: 14px;
}
