@use 'sass:math';

@import 'src/styles/vars';

.InputField {
  flex-grow: 1;
}

.InputField__input {
  padding-top: $field-padding-vertical;
  padding-bottom: $field-padding-vertical;
}

.InputField__label {
  align-items: center; /* align vertical */
  display: flex;
  height: $field-height-base;
  justify-content: flex-end; /* align right */
  margin-bottom: 0;
  text-align: right;
}

.InputField__help {
  margin-left: math.div($margin-base-horizontal, 3);
  top: 0;
}

.FormField {
  position: relative;

  &:hover {
    .FormField__action {
      visibility: visible;
    }
  }

  &.FormField--flagged,
  &.FormField--editable,
  &.FormField--patched {
    background: $brand-highlight-hover;
  }

  &.FormField--flagged,
  &.FormField--editable {
    .FormField__action {
      visibility: visible;
    }
  }
}

.FormField--error {
  color: $brand-danger;
}

.FormField--raguelWarning {
  background: $brand-highlight-hover;
  position: relative;
}

.FormField--raguelWarning::before {
  content: '';
  margin-left: -3px; /* To keep alignment */
  border-left: 3px solid $brand-highlight;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.FormField--raguelError {
  background: $brand-danger-hover;
  position: relative;
  &.FormField--notRequested {
    background: rgba($brand-highlight-secondary, 0.2);
    &::before {
      border-color: $brand-highlight-secondary;
    }
  }
}

.FormField--raguelError--multiLevel {
  border-radius: 0.5em;
  margin-left: -10px;
  padding-left: 2px;
  padding-bottom: 3px;
}

.FormField--raguelError::before {
  content: '';
  margin-left: -3px; /* To keep alignment */
  border-left: 3px solid $brand-danger;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.FormField--raguelError--multiLevel::before {
  margin-left: 0;
  margin-right: 3px;
}

.FormField--hideField {
  > .DeclinableField__contentContainer {
    display: none;
  }
  > .FormText__input > .InputField {
    display: none;
  }
  > .InputField > .InputField__input {
    display: none;
  }
}

// actions on fields (normalized comments, data ops)

.FormField__actions {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.FormField__action {
  display: block;
  font-size: large;
  visibility: hidden;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: $brand-primary;
  cursor: pointer;

  &:hover {
    visibility: visible;
  }

  &.FormField__action--selected {
    color: $brand-highlight;
  }
}
