@import 'src/styles/vars';

.Variant_Table_column_gtin {
  width: 130px;
  padding-right: 5px;
}

.Variant_Table_column_actions {
  width: 80px;
}

.Variant_Table_column_value {
  max-width: 200px;
  padding-right: 5px;
}

.Textile_variants_list {
  margin-top: 30px;
}
