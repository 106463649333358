@import 'src/styles/vars';

.ProductHistoryDiffCard {
  padding: $margin-base;
  word-wrap: break-word;
}

.ProductHistoryDiffCard__changes {
  margin: $margin-base 0;
}

.ProductHistoryDiffCard__sentence {
  margin-bottom: 10px;
}

.ProductHistoryDiffCard__sentenceColored {
  font-weight: 500;
  color: $brand-highlight;
  &.ProductHistoryDiffCard__sentenceColored--accept {
    color: $brand-success;
  }
  &.ProductHistoryDiffCard__sentenceColored--refuse {
    color: $brand-danger;
  }
}

.ProductHistoryDiffCard__sentenceBold {
  font-weight: 500;
}

.ProductHistoryDiffCard__id {
  color: $brand-secondary;
}
