@import 'src/styles/vars';

.Raguel {
  display: flex;
  flex-direction: column;
  &.FormField--raguelError,
  &.FormField--raguelWarning {
    padding: $padding-base-vertical $padding-base-horizontal;
    margin: $margin-base $margin-base-horizontal;
  }
}

.Raguel__block {
  margin: $margin-base-horizontal 0;
  display: flex;
  .Raguel {
    padding: $margin-base-horizontal;
  }
}

.Raguel__message {
  color: $brand-danger;

  &--disabled {
    color: $text-color;
  }

  margin-bottom: 3px;
  width: 100%;

  .mdi {
    margin-right: $padding-xs-horizontal;
    font-size: large;
  }
}

.FormField--raguelError {
  &,
  &.FormField--raguelWarning {
    .Raguel__message {
      color: $brand-danger;
    }
  }
}

.FormField--raguelWarning {
  .Raguel__message {
    color: $brand-highlight;
  }
}

.Raguel__message.Raguel__message__warning {
  color: $text-color;
  .mdi.mdi-alert {
    color: $brand-highlight;
  }
}

.Raguel__message__line {
  display: flex;
  align-items: center;
}

.Raguel__notRequestedErrors {
  color: $brand-highlight-secondary;

  .Raguel__label {
    background-color: $brand-highlight-secondary;
  }
}

.Raguel__default {
  display: flex;
  align-items: center;
  .Raguel__message__buttons {
    margin-left: 10px;
  }
}

.Raguel__bypass {
  display: flex;
  align-items: baseline;
}

.Raguel__label {
  border-radius: $border-radius-base;
  padding: $padding-xs-vertical * 2 $padding-xs-horizontal;
  color: $brand-white;
  background-color: $gray-light;
  margin-left: 0;
  &--error {
    background-color: $brand-danger;
  }
  &--margin {
    margin: 0 $half-margin-base-horizontal;
  }
  ul {
    margin: 0;
    padding-inline-start: 0 !important;
    > li {
      list-style: none;
      .deadline {
        font-weight: bolder;
      }
    }
  }

  .mdi {
    margin-right: 0;
    font-size: 140%;
    vertical-align: middle;
  }
}
