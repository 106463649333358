@import 'src/styles/vars';

.summary {
  padding: 20px;
  display: flex;
  gap: 1rem;
}

.section {
  flex-grow: 1;
  border: 1px solid $gray-lighterer;
  border-radius: 5px;
  padding: 10px;
}

.header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-weight: 400;
  margin: 0;
}

.content {
  display: flex;
  gap: 0.5rem;
}
.trackingSection {
  .content {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.statusSummary {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
}

.icon {
  font-weight: 700;
  line-height: 36px;
  font-size: 36px;
  display: flex;
  margin-right: 0.5rem;
}

.number {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
}

.statusText {
  color: $gray-medium-dark;
}

.separator {
  border-right: 1px solid $gray-slightly-lighter;
  height: 36px;
}
