@import 'src/styles/colors';

.ListTableCell.ProductStatusBar {
  padding: 0;
}

.ProductStatusBar.StatusBar {
  width: 3px;

  &.StatusBar--NOT_ASSIGNED {
    background-color: $status-not-assigned;
  }
  &.StatusBar--ASSIGNATION_PENDING {
    background-color: $status-assignation-pending;
  }
  &.StatusBar--WAITING_TO_BE_SHARED {
    background-color: $status-waiting-to-be-shared;
  }
  &.StatusBar--REFUSED {
    background-color: $status-refused;
  }
  &.StatusBar--SUBMITTED {
    background-color: $status-submitted;
  }
  &.StatusBar--UPDATED {
    background-color: $status-updated;
  }
  &.StatusBar--ACCEPTED {
    background-color: $status-accepted;
  }
}
