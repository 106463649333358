@use 'sass:math';

@import 'src/styles/vars';

$toolbar-size: 35px;

.InputRichContent__richContent {
  position: relative;
  border: 1px solid $gray-light;
  padding: math.div($margin-base, 2);
  min-height: 5rem;
  max-height: 12rem;
  overflow: auto;
}

.InputRichContent__richContent--large {
  min-height: 24rem;
  max-height: 24rem;
}

.InputRichContent__richContent--disabled {
  background-color: $readonly-bg;
  color: $readonly-fg;
  border: none;
}
