@use 'sass:math';

@import 'src/styles/vars';

.FormRadio__item {
  display: inline-block;
  margin: 0;
  margin-right: 1rem;
}

.FormRadio__itemLabel {
  margin-left: 0.5rem;
}

.FormRadio__label {
  display: inline-block;
}

.FormRadio__help {
  margin-left: math.div($margin-base-horizontal, 3);
}

.FormRadio__radio {
  min-height: $field-height-base;
}
