@import 'src/styles/vars';

.ListExportableTagFilterIcon {
  font-size: 1.3rem;
  &--NotExportable {
    color: $brand-danger;
  }
  &--Exportable {
    color: $brand-success;
  }
  &--NotApplicable {
    color: $gray-light;
  }
}
