@import 'src/styles/colors';

.ProductUserLabels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .UserLabel__label {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
  }
}

.ProductUserLabels__add {
  display: flex;
  align-items: center;
  border: 1px dashed $brand-primary;
  background-color: transparent;
  color: $brand-primary;
  border-radius: 4px;

  &:hover {
    background-color: $brand-secondary-hover;
  }

  & > span {
    padding-left: 0.2rem;
  }
}

.ProductUserLabels__moreLabels {
  color: $brand-secondary;
  font-size: 0.8rem;
  padding: 0 2px;
  cursor: default;

  &:hover {
    text-decoration: underline;
  }
}
