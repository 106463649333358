@import 'src/styles/vars';

.DateRange__item {
  display: inline-block;
}

.DateRange__input {
  padding: 1% 12%;
  width: 50%;
  border: hidden;

  &:last-child {
    float: right;
  }
}

.DateRangePicker__WeekdayHeading {
  font-weight: 300;
  cursor: default;
}

.DateRangePicker__CalendarSelection {
  background-color: $brand-primary;
  border-color: $brand-primary;
}
