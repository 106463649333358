@import 'src/styles/vars';

.ProductAdminAudit {
  padding: $padding-base-vertical $padding-base-horizontal;
}

.ProductAdminAudit__element {
  margin-top: $margin-base-vertical;
  margin-bottom: $margin-base-vertical;
  padding: $padding-base-vertical $padding-base-horizontal;
  border-radius: $border-radius-base;
  border: 1px solid $brand-black;
}

.ProductAdminAudit__elementHeader {
  cursor: pointer;
}

.ProductAdminAudit__elementAuditCollapsedIcon {
  margin-right: $half-margin-base;
}

.ProductAdminAudit__elementAuditTitle {
  font-weight: bold;
}

.ProductAdminAudit__elementAuditDeleteButton,
.ProductAdminAudit__elementAuditRefreshButton {
  margin-left: $half-margin-base;
}

.ProductAdminAudit__elementContext {
  border-top: 1px solid $gray-border;
  margin-top: $padding-base-vertical;
  padding-top: $padding-base-vertical;
  align-items: center;
  gap: 10px;
}

.ProductAdminAuditContext__workflowName {
  background-color: $gray-lighter;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  width: fit-content;
}

.ProductAdminAudit__elementContextEmpty {
  color: $brand-secondary;
}
