@import 'src/styles/vars';

.DeclinableField {
  flex-wrap: nowrap;
}

.DeclinableField__innerContent {
  width: 100%;
  display: inline-flex;
  align-items: center;
  > div {
    flex-grow: 1;
  }
}

.DeclinableField__innerContent--full {
  > div:nth-child(1) {
    width: 45%;
  }
  > div:nth-child(2) {
    width: 55%;
    padding-left: $padding-base-horizontal;
  }
}
