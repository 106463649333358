@import 'src/styles/colors';

.ListTableRow {
  display: table-row;
  background-color: $brand-white;

  &.selected {
    background-color: $alk-background-hover !important;
  }

  &:hover {
    background-color: $alk-background-hover !important;

    &.clickable {
      cursor: pointer;
    }

    .RFPCodeCell__Code {
      text-decoration: underline;
    }
  }

  &.ListTableRow--background:nth-child(even) {
    background: #f4f9fb;
  }

  & > td {
    height: 66px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid $gray-lighterer;
  }

  &:first-child > td {
    border-top: 1px solid $gray-lighterer;
  }

  &.ListTableRow--selected {
    background-color: $alk-background-hover;
  }
}
