@import 'src/styles/vars';

.MessageModal__summaryLine {
  font-weight: 600;
}

.MessageModal__textareaHeader {
  padding: $padding-base-horizontal 0;
  & > h3 {
    font-weight: 400;
    margin-bottom: 0;
  }
}
