@import 'src/styles/colors';

.Navbar__appStoreLink {
  display: flex;
  align-items: center;
  border: 1px solid $brand-white;
  padding: 0 10px;
  height: 30px;
  line-height: initial;
  margin: 5px 10px;
  cursor: pointer;
  background: transparent;
  border-radius: 4px;
  user-select: none;

  &,
  &:focus,
  &:active,
  &:visited {
    color: $brand-white;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: $brand-primary;
    color: $brand-primary;
    text-decoration: none;
  }

  .mdi {
    display: flex;
    align-items: center;
    margin-right: 2px;
  }
}
