@use 'sass:math';

@import 'src/styles/vars';

.ProductPage__content {
  .card {
    border: none;
    &:not(:first-of-type) {
      border-top: 1px solid #ddd;
    }
  }

  .AssetList__container {
    background-color: inherit;
  }

  .AssetList__card {
    border: 1px solid #ccc;
  }

  .AnchorStickyNav__stickyContainer {
    margin-left: 7.5px;
    width: 100%;
  }

  .Collapsible__content > .AnchoredBlock {
    padding: 20px;
  }

  .AnchoredBlock {
    &:not(:first-of-type) {
      border-top: 1px solid #ccc;
    }
  }

  .AnchoredMain > .AnchoredBlock {
    border-top: 0;
  }
}

.Layout--rtl {
  .ProductPage__content--rtl .AnchoredMain,
  .ProductPage__modal {
    input,
    textarea,
    option,
    .InputRichContent,
    .InputTextarea,
    .InputField__input,
    .ChoiceTreeItem__item {
      direction: rtl;
    }

    #InputText-FormField-webContent,
    #FormField-webContent,
    input[type='radio'],
    .InputRadio__inputContent {
      direction: ltr !important;
    }

    select {
      text-align-last: right;
    }
  }
}

.ProductPage__navigation {
  position: static;
}

.ProductNavigation__container--sticky {
  top: $navbar-size !important;
  width: 25%;
  height: 4.5rem;
  padding: 0;
  padding-right: math.div($grid-gutter-width, 2);
  .row {
    padding: 0;
    align-items: center;
    background: $brand-primary;
    color: #fff;
    min-height: 4.5rem;
  }
  h3 {
    font-size: $font-size-base;
    margin: 0;
  }
}

.ProductPage__recipientsButton {
  text-align: right;
}

.RetailerProductPage {
  & > .AnchoredMain > .AnchoredBlock:last-child {
    min-height: calc(100vh - 350px);
  }

  .card {
    border: none;
    &:not(:first-of-type) {
      border-top: 1px solid #ddd;
    }
  }

  .AssetList__container {
    background-color: inherit;
  }

  .AssetList__card {
    border: 1px solid #ccc;
  }

  .AnchorStickyNav__stickyContainer {
    margin-left: 7.5px;
    width: 100%;
  }
}

.RetailerProductPage__buttonBar {
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  .Button {
    margin-left: 0.5rem;
  }
}
