@import 'src/styles/colors';

.RequiredFieldsCategoryColumn__name {
  padding: 0.2rem;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
}

.RequiredFieldsCategoryColumn__name--normal {
  border: 1px solid $gray;
}

.RequiredFieldsCategoryColumn__name--warning {
  background-color: $brand-orange;
  color: white;
  font-weight: 400;
}

.RequiredFieldsCategoryColumn__name--error {
  background-color: $brand-warning;
  color: white;
  font-weight: 400;
}

.RequiredFieldsCategoryColumn__name--allComplete {
  color: $gray-medium;
  font-style: italic;
  > i {
    color: $brand-success;
  }
}

.RequiredFieldsCategoryColumn__calendar {
  color: $brand-secondary;
}

.RequiredFieldsCategoryColumn__deadline {
  padding-top: 0.2rem;
}

.RequiredFieldsCategoryColumn__date {
  padding-left: 0.2rem;
  font-weight: 500;
}

.RequiredFieldsCategoryColumn__date--warning {
  color: $brand-orange;
}

.RequiredFieldsCategoryColumn__date--error {
  color: $brand-warning;
}

.tooltip-required-fields-category__name-deadline {
  padding: 0.1rem;
  font-weight: 500;
}

.tooltip-required-fields-category__name-deadline--warning {
  color: $brand-orange;
}

.tooltip-required-fields-category__name-deadline--error {
  color: $brand-warning;
}

.tooltip-required-fields-category__name-no-deadline {
  color: $gray-medium;
  padding: 0.2rem;
  font-style: italic;
}
