@import 'src/styles/colors';

.ActionOption {
  padding: 0.5rem;
  border: 0;
  text-align: left;

  &:hover {
    background-color: $alk-background-hover;
    cursor: pointer;
  }

  &.ActionOption--link {
    padding: 0;

    a,
    a:active,
    a:visited,
    a:hover {
      display: inline-block;
      width: 100%;
      padding: 0.5rem;
      color: inherit;
      text-decoration: none;
    }
  }
}

.ActionOption--link-disabled {
  *,
  *:active,
  *:visited {
    color: #ccc;
    display: inline-block;
    width: 100%;
    text-decoration: none;
  }

  & > button.ActionOption,
  & div.ActionOption {
    cursor: default;
    // &:hover {
    //   background-color: transparent;
    // }
  }

  .__react_component_tooltip {
    width: auto;
  }
}
