@import 'src/styles/vars';

.EarlyPaymentDiscount {
  margin-bottom: 15px;
  &Header,
  &Body {
    display: flex;
    align-items: center;
    margin-top: 15px;
    &__Metadata {
      flex-basis: 75%;
      display: flex;
      align-items: center;
      border: 1px solid $gray-lighter;
      border-radius: 3px;
      padding: 5px;
    }
    &__Type {
      flex-basis: 30%;
      padding-right: 5px;
    }
    &__Values {
      flex-basis: 25%;
      display: flex;
      padding: 5px;
      align-items: center;
      &_Item {
        flex-grow: 1;
        flex-basis: 0;
      }
      &_Item + &_Item {
        margin-left: 5px;
      }
    }
    &__Unit {
      flex-basis: 20%;
      padding-right: 5px;
    }
    &__Description {
      flex-grow: 1;
      padding-right: 5px;
    }
    &__Actions {
      position: relative;
    }
    &__deleteIcon {
      color: $gray-light;
      font-size: large;
      position: absolute;
      right: -25px;
      top: -10px;
    }
  }
  &Header {
    color: $gray-light;
  }
}
