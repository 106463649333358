@import 'src/styles/vars';

.BulkValidate__products {
  @include ul();
  margin: 0;
  padding: 1rem;
  max-height: 30vh;
  overflow: hidden;
  overflow-y: auto;

  &.BulkValidate__errors {
    max-height: 20vh;
  }
}

.BulkValidate__errorsLabel {
  padding-top: 1rem;
}

.BulkValidate__success {
  margin-top: 1rem;
}
