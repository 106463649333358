@import 'src/styles/colors';

.RecipientsSelector {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.RecipientsSelector__productsNumber {
  font-weight: bold;
}

.RecipientsSelector__helpMessage {
  margin-bottom: 45px;
}

.RecipientsSelector__dropdown {
  display: flex;
  align-items: center;
}

.RecipientsSelector .TurboSelect {
  width: 100%;
}
