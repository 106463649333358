@import 'src/styles/vars';

.BaseCommentModal__body {
  display: flex;
  width: 70vw;
}

.BaseCommentModal__label {
  width: 140px;
  text-align: right;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.BaseCommentModal__textarea {
  flex: 1;

  textarea {
    width: 100%;
    height: 200px;
    padding: 0.5rem;
    resize: none;
  }
}

.ContributionDiffModal {
  min-width: 60em;
}

.ContributionDiffModal__body {
  max-height: 600px;
}

.PhysicalCheckDiff_FailedField__field_diff__value {
  color: $brand-success;
  font-style: italic;
  font-weight: 400;
}

.PhysicalCheckDiff_FailedField__field_diff__desc {
  font-style: italic;
}
