@import 'src/styles/vars';

.ConsentManagerModal {
  .Modal__body {
    flex: 1;
    max-width: 1024px;
    overflow-y: auto;
  }
}

.ConsentManagerModal:not(.ConsentManagerModal--prefs) {
  .Modal__footer {
    .Modal__footerConfirmButton {
      background: white;
      border-color: $brand-secondary;
      color: $brand-secondary;

      &:hover {
        background: $brand-secondary;
        color: white;
      }
    }
  }
}

.ConsentManagerModal__tools {
  @include ul();
  width: 100%;
  display: table;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}

.ConsentManagerModal__toolsDesc {
  p {
    margin-bottom: 0.25rem;
  }
}

.ConsentManagerModal__tool + .ConsentManagerModal__toolsDesc {
  p {
    margin-top: 1.5rem;
  }
}

.ConsentManagerModal__tool {
  display: table-row;

  &.ConsentManagerModal__toolHeader {
    .ConsentManagerModal__toolCell {
      border-bottom: 1px solid $gray-medium;
      padding-bottom: 0.2rem;
    }
  }
}

.ConsentManagerModal__toolCell {
  display: table-cell;
  min-width: 1px;
  padding-top: 0.5rem;
  vertical-align: top;

  & + & {
    padding-left: 0.5rem;
  }

  &.ConsentManagerModal__toolDesc {
    min-width: initial;
  }
}
