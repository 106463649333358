@import 'src/styles/vars';

.Brackets {
  display: flex;
  flex-direction: column;
}

.Brackets__header {
  background: $brand-success;
  color: white;
  width: 100%;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .BracketsCarousel {
    flex-basis: 25%;
  }
}

.Brackets__headerTitle {
  display: flex;
  align-items: center;
  padding-right: $padding-base-horizontal;
}

.Brackets__data {
  display: flex;
  align-items: center;
}

.Brackets__unit {
  flex-basis: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $padding-xs-horizontal;
  > * {
    margin-left: $half-margin-base-horizontal;
  }
}

.Brackets__values {
  flex-basis: 25%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: $padding-xs-horizontal;
}

.Brackets__value {
  flex-grow: 1;
  flex-basis: 0;
  + .Brackets__value {
    margin-left: 5px;
  }
}

.BracketType {
  margin-top: 2px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-small;
  padding: $padding-base-horizontal;

  .BracketType__label {
    color: $gray-medium-dark;
  }
}
