@use 'sass:math';

@import 'src/styles/vars';

.PriceTemplateStatusBadge {
  text-align: center;
  margin-right: math.div($margin-base, 2);
  border-radius: $border-radius-very-large;
  padding: $padding-xs-vertical $padding-small-horizontal;
  border-width: 1px;
  border-style: solid;
  display: inline;
}

.PriceTemplateStatusBadge--DRAFT {
  border-color: $brand-inprogress;
  color: $brand-inprogress;
}

.PriceTemplateStatusBadge--PUBLISHED {
  border-color: $status-published;
  color: $status-published;
}

.PriceTemplateStatusBadge--ACCEPTED {
  border-color: $status-accepted;
  color: $status-accepted;
}

.PriceTemplateStatusBadge--REJECTED {
  border-color: $status-rejected;
  color: $status-rejected;
}

.PriceTemplateStatusBadge--ARCHIVED {
  border-color: $status-archived;
  color: $status-archived;
}

.PriceTemplateStatusBadge--REPUBLISHED {
  border-color: $status-republished;
  color: $status-republished;
}
