#deadline_selector_checkbox {
  margin: 0 1em 0 0;
}

.MaturityRuleSetModal__body .InputDate__calendar {
  position: relative;
  top: 5px;
}

.DeadlineSelector {
  label {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
}
