.ListActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.ListActions__left,
.ListActions__right {
  display: flex;
  align-items: center;
}

.ListActions__left {
  justify-content: flex-start;
  order: 1;

  & > *:not(.ListActionsSelectAll) {
    margin-right: 0.5rem;
  }
}

.ListActions__right {
  justify-content: flex-end;
  order: 2;

  & > *:not(.ListPaginationButtons) {
    margin-left: 0.5rem;
  }
}
