@import 'src/styles/vars';

.Textile__variantField {
  height: $field-height-base;
  display: flex;
  align-items: center;
  .ReactUiCheckbox {
    margin-bottom: 0;
  }
}

.Textile_variants {
  margin-top: 3em;
}

.Textile_variants_actions {
  color: $gray-light;
  background-color: $brand-white;
  border: 0;
  align-items: center;
  > i {
    font-size: 1.6rem;
  }
}
