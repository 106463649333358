@import 'src/styles/vars';

.CatalogExportModal.CatalogExportModalV1 {
  width: 50vw;
  height: 500px;
}

.CatalogExportModal__label {
  padding-bottom: 0.5rem;
}

.CatalogExportModal__format {
  padding-bottom: 1rem;
}
