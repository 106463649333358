@import 'src/styles/colors';

.DataOpsDiffModal__spinner {
  margin-top: 0.5rem;
}

.DataOpsDiffModal__error {
  color: $brand-danger;
  text-align: center;
  font-weight: 500;
  padding-top: 1rem;
}

.DataOpsDiffModal__body {
  width: 1200px;
}
