@import 'src/styles/vars';

.ListTable td > span {
  &.scoreColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:after {
      display: block;
      content: ' ';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-left: 5px;
    }
  }

  &.scoreGreen:after {
    background: $brand-success;
  }

  &.scoreYellow:after {
    background: $brand-highlight;
  }

  &.scoreRed:after {
    background: $brand-danger;
  }

  &.scoreColumnEmpty {
    color: $gray-medium;
    font-style: italic;

    &:after {
      display: none;
    }
  }
}
