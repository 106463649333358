@import 'src/styles/vars';

.Dashboard__menuRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: $margin-base;
}

.Dashboard__menuCol {
  flex: 0 0 18%;
}

.Dashboard__col {
  &.Dashboard__col--messenger {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 1.5rem 0;
  }
}

.Dashboard__completionCol {
  & + & {
    margin-top: $half-margin-base;
  }
}

.Dashboard__MessageDisabled {
  background-color: $gray-lightest;
  border: 1px solid $gray-border;
  padding: $margin-base;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

.Dashboard__MessageDisabledTitle {
  margin-bottom: $half-margin-base;
}
