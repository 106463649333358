@import 'src/styles/vars';

.Tariffs {
  border-top: solid 1px #ddd;
  margin-top: 20px;
  padding: 20px;
}

.Tariffs__list {
  padding-bottom: 1.5em;
  > * ~ * {
    margin-top: 10px;
  }
}

.Tariff {
  display: flex;
  justify-content: flex-end;
  > * ~ * {
    margin-left: 1em;
  }
  align-items: center;
  padding-top: 5px;
}

.Tariff__info {
  display: flex;
  background: $gray-really-light;
  color: $gray-medium-dark;
  justify-content: space-between;
  min-width: 30em;
  min-height: 38px;
  align-items: center;
  padding: 0 0.5em;
}

.Tariffs__footer {
  padding: 0 20px;
  opacity: 0.6;
  a {
    display: inline;
  }
}

.Tariffs__in-error {
  margin-top: 10px;
}

.Tariffs__list__error {
  padding-top: 10px;
  padding-left: 10px;
}

.Tariff-error {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
