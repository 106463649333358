@import 'src/styles/vars';

.globalCompletionTitle {
  color: $gray-medium-dark;
  font-size: 1.1rem;
}

.globalCompletion {
  text-align: center;
  padding-top: 20px;
}

.globalCompletionBar:global(.CircularProgressbar) {
  width: 25%;
  margin-bottom: 15px;
  :global(.CircularProgressbar-trail) {
    stroke: $gray-lighterer;
  }
  :global(.CircularProgressbar-text) {
    fill: $brand-default;
    font-size: x-large;
  }
}
.globalCompletionBar__low:global(.CircularProgressbar) {
  :global(.CircularProgressbar-path) {
    stroke: $brand-danger;
  }
}
.globalCompletionBar__medium:global(.CircularProgressbar) {
  :global(.CircularProgressbar-path) {
    stroke: $brand-highlight-secondary;
  }
}
.globalCompletionBar__good:global(.CircularProgressbar) {
  :global(.CircularProgressbar-path) {
    stroke: $brand-success;
  }
}

.detailsTitle {
  color: $gray-medium-dark;
  border-bottom: solid $gray-lighterer 2px;
  font-size: 1rem;
  margin-bottom: 15px;
}

.recipients {
  row-gap: 24px;
}

.recipientTitle {
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.recipient {
  margin-right: 5px;
}

.maturities {
  gap: 8px;
}
