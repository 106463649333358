@import 'src/styles/vars';

.SynchronizationError {
  text-align: center;
  padding: $padding-xs-horizontal;
  background-color: $brand-danger;
  color: $brand-white;

  .mdi.mdi-cancel {
    margin-right: $padding-xs-horizontal;
  }
}
