.ListTable {
  display: table;
  min-width: 100%;
  margin: 0;
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1rem;

  tbody,
  thead {
    display: table-row-group;
  }

  tr {
    display: table-row;
  }

  td {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;

    & > span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  label,
  p {
    margin: 0;
  }
}
