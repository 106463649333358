.ListActionsSelectAll {
  display: block;
  padding-left: 0.7rem;
  padding-right: 1rem;

  .ReactUiCheckbox {
    display: flex;
    margin: 0;
  }

  .ReactUiCheckbox__checkbox {
    display: block;
  }
}
