@use 'sass:math';

@import 'src/styles/vars';

.deprecatedPatches {
  padding: 20px;
}

.deprecatedPatchesHeader {
  font-weight: 500;
  padding: math.div($margin-base, 40);
}

.deprecatedPatchesLine {
  border-top: 1px solid $gray-light;
  display: flex;
  align-items: baseline;
}

.deprecatedPatchesTable {
  width: 100%;
  margin: 0;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
