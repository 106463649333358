@import 'src/styles/vars';

.DisplayGroup--condensed {
  width: 100%;

  .InputField {
    margin-bottom: 0;
  }
}

%DisplayGroup--borders {
  border-left: 2px solid $brand-primary;
  border-right: 2px solid $brand-primary;
}

.DisplayGroup--borders {
  > .DeclinableField > .DeclinableField__contentContainer {
    @extend %DisplayGroup--borders;
  }
  > .FormText {
    .FormText__input {
      @extend %DisplayGroup--borders;
    }
  }
  > div > .InputField {
    > .InputField__input {
      @extend %DisplayGroup--borders;
    }
  }
  > div > .InputField__input {
    @extend %DisplayGroup--borders;
  }
}

.FormGroup__showHiddenFieldsButton {
  margin-left: $half-margin-base;
  margin-top: $half-margin-base;
  width: 100%;
  &.btn {
    background-color: #eee;
    border: none;
  }
}

.FormGroup__showHiddenFieldsButton.btn.btn-secondary:active,
.FormGroup__showHiddenFieldsButton.btn.btn-secondary:focus {
  background-color: #eee;
  color: $brand-secondary;
  &:hover {
    background-color: $brand-secondary;
    color: white;
  }
}

.form-group {
  margin-bottom: 0;
  $pluginPadding: 0.75rem;

  &.form-group--plugins1 {
    padding-right: $pluginPadding;
  }
  &.form-group--plugins2 {
    padding-right: $pluginPadding * 3;
  }
}

.FormGroup__header h2 {
  display: inline-block;
  margin-bottom: 0;
  flex-grow: 1;
}

.FormGroup__header-error-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;

  &__title {
    margin: 0 1rem 0 0;
  }
}

.FormGroup__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.FormGroup__headerErrors,
.FormGroup__headerSuggestions {
  margin-right: $half-margin-base;
  border-radius: 12.5px;
  font-size: 1rem;
  padding: 0px 10px;
}

.FormGroup__headerErrors .mdi {
  color: $brand-danger;
}

.FormGroup__headerErrors {
  background: rgb(251, 219, 215);
}

.FormGroup__headerSuggestions {
  background: rgb(254, 240, 205);

  .RaguelWarning__suggestionIcon {
    font-size: 1rem;
  }
}

.FormGroup__headerErrors,
.FormGroup__headerSuggestions,
.ValidationResults__warningIcon {
  padding-top: 2px;
}
