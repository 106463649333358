@import 'src/styles/vars';

.TaxLevel {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.TaxLevel__labelWrapper {
  flex-basis: 75%;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-small;
  padding: $padding-base-horizontal;
}

.TaxLevel__label {
  color: $gray-medium-dark;
}

.TaxLevel__included {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: $half-margin-base-horizontal;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.TaxLevel__value {
  padding: $padding-xs-horizontal;
  flex-basis: 25%;
}
