@import 'src/styles/vars';

.SharingUnits {
  padding-bottom: $half-margin-base;
}

.SharingUnits__errors {
  margin: 20px;
  padding-top: 10px;
  padding-left: 10px;
}
