@import 'src/styles/vars';

.AddWrapper__Wrapper {
  padding: $padding-large-vertical 0;
  display: inline-flex;
  border: 1px dashed $brand-primary;
  width: 100%;
  margin-bottom: 1rem;
}

.AddWrapper__select {
  flex-grow: 1;
}

.AddWrapper__Icon {
  color: $brand-primary;
  font-size: x-large;
  padding: $padding-base-horizontal;
}

.AddWrapper__Input {
  padding-right: $card-spacer-x; // Bobi : Trust me - I'm famous
}
