.AddUserLabelFormInput {
  display: flex;
  justify-content: stretch;
  flex-grow: 1;
  & > input {
    flex-grow: 1;
    min-width: 10rem;
    margin-right: 1rem;
  }
}
