@import 'src/styles/vars';

.BracketsCarousel {
  display: flex;
  height: 3em;
  align-items: center;
}

.BracketsCarousel__headerItem {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: 100%;
  padding: $padding-xs-horizontal;
  + .BracketsCarousel__headerItem {
    margin-left: 5px;
  }
}

.BracketsCarousel__headerLeftArrow {
  position: absolute;
  left: 0;
  font-size: 1.3em;
  &:hover {
    cursor: pointer;
    color: $gray-lighterer;
  }
}

.BracketsCarousel__headerRightArrow {
  position: absolute;
  right: 0;
  font-size: 1.3em;
  &:hover {
    cursor: pointer;
    color: $gray-lighterer;
  }
}

.BracketsCarousel__delete {
  position: absolute;
  font-weight: 400;
  font-size: 1.3em;
  top: 0;
  right: 25%;
  &:hover {
    cursor: pointer;
    color: $gray-lighterer;
  }
}
