@import 'src/styles/colors';

.PercentageCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray-medium;

  &.PercentageCell--clickable:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.PercentageCell__percentage,
.PercentageCell__count {
  text-align: center;
}

.PercentageCell__percentage {
  font-weight: 500;

  &.PercentageCell__percentage--good {
    color: $brand-success;
  }

  &.PercentageCell__percentage--medium {
    color: $brand-highlight-secondary;
  }

  &.PercentageCell__percentage--low {
    color: $brand-danger;
  }

  &.PercentageCell__percentage--neutral {
    color: $brand-info;
  }
}

.PercentageCell__count {
  font-size: 0.8rem;
}
