@use 'sass:math';

@import 'src/styles/vars';

.AddPriceWaterfall__container {
  border: 1px dashed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.AddPriceWaterfall__createButton {
  color: $brand-primary;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;

  span {
    cursor: pointer;
  }

  i {
    cursor: pointer;
    padding-right: 0.5em;
    font-size: 20px;
  }

  &.AddPriceWaterfall__createButton--disabled {
    color: $gray-medium;
  }
}

.AddPriceWaterfall__copy {
  padding-top: 1em;
  padding-bottom: 1em;
}

.AddPriceWaterfall__copy__title {
  color: $gray-dark;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AddPriceWaterfall__copy__selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AddPriceWaterfall__copy__selection__autocomplete {
  padding-right: 0.5em;
  width: 50%;
}

.AddPriceWaterfall__copy__selection__button {
  padding-left: 0.5em;
  width: 30%;
}

.AddPriceWaterfall__creating {
  display: flex;
  align-items: center;

  .Spinner {
    margin-left: math.div($margin-base, 3);
  }
}
