.contact-block {
  background: #fbedca;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;

  p {
    margin: 5px 10px 5px 0;
  }
}

.contact-block-p1 {
  font-weight: bolder;
}
