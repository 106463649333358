@import 'src/styles/colors';

.ListCollapsibleFilter {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.ListCollapsibleFilter__header {
  padding: 0 0.5rem;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & > .Button__content {
    display: flex;
    align-items: center;
  }
}

.ListCollapsibleFilter__icon {
  font-size: 1.5rem;
  color: $brand-secondary;

  &.ListCollapsibleFilter__icon--expanded {
    transform: rotate(90deg);
  }
}

.ListCollapsibleFilter__label {
  padding-top: 1px;
}

.ListCollapsibleFilter__body {
  padding: 0.5rem 0.5rem 0 2rem;
}
