@import 'src/styles/vars';

.CatalogMenu {
  position: relative;
  justify-content: center;
  background: white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  padding: 0 0.5rem;
  z-index: 1;
}

.CatalogMenu__tabs {
  align-items: stretch;
}

.CatalogMenu__left,
.CatalogMenu__right {
  position: absolute;
  top: 0;
  bottom: 0;
}

.CatalogMenu__left {
  left: 0.5rem;
}

.CatalogMenu__right {
  right: 0.5rem;
}

.CatalogMenu__archivedProduct.LinkButton {
  color: $gray-dark;

  &,
  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &.CatalogMenu__archivedProduct--selected {
    color: $brand-secondary;
    cursor: default;
  }

  & > .LinkButton__icon {
    padding-right: 0;
  }
}

.CatalogMenu__chevron {
  font-size: 2rem;
  align-self: center;
  color: rgba($brand-primary, 0.5);
}
