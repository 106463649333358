@use 'sass:math';

@import 'src/styles/vars';

.ImageCheckbox {
  margin-top: $padding-large-vertical;
}

.ImageCheckbox__label {
  text-align: right;
}

.ImageCheckbox__help {
  margin-left: math.div($margin-base-horizontal, 3);
}

.ImageCheckbox__content {
  margin-bottom: $half-margin-base-horizontal;
}

.ImageCheckbox__item {
  margin: 0 $half-margin-base-horizontal $half-margin-base-horizontal 0;
  float: left;
}
