@import 'src/styles/vars';

.DataOpsDiffBlockLines__patchDiffsRow {
  &:hover {
    background-color: $alk-background-hover;
  }
}

.DataOpsDiffBlockLines__patchDiffsCol {
  border-top: 1px solid $gray-light;
  vertical-align: middle;

  .ProductHistoryDiffCard__row:first-child {
    border-top: none;
  }
}

.DataOpsDiffBlockLines__patchActionsCol {
  width: 1px;
  border-top: 1px solid $gray-light;
  vertical-align: middle;
  padding: 0.5rem 0;
  white-space: nowrap;

  button {
    margin-left: 1rem;
  }
}
