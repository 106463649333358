@import 'src/styles/vars';

.CatalogExportModal.CatalogExportModalV2 {
  .Modal__body {
    background-color: #e9ecf0;
  }
}

.CatalogExportModalV2__ExportName {
  font-size: 1.75rem;
}

.CatalogExportModalV2__Group {
  background-color: white;
  margin-top: 5px;
  padding: 10px;
}

.CatalogExportModalV2__GroupLogo {
  width: 60px;
  height: 60px;
  transition: 0.2s all;
}

.CatalogExportModalV2__Export {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;

  &:nth-child(even) {
    background-color: #e7f4f7;
  }
}

.CatalogExportModalV2_ExportWarning {
  margin-right: 8px;
  margin-left: auto;

  .mdi-alert {
    color: $brand-highlight;
  }
}

.CatalogExportModalV2__GroupHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.CatalogExportModalV2__GroupLabel {
  margin-left: 10px;
  font-size: $font-size-xlarge;
}

.CatalogExportModalV2__ProductGroupHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.CatalogExportModalV2__ProductGroupTitle {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: $font-size-xlarge;
  i {
    margin-right: 5px;
  }
}
.CatalogExportModalV2__ProductGroupSubtitle {
  margin-left: 10px;
  color: $gray-medium;
}

.CatalogExportModalV2__Products {
  overflow: auto;
  max-height: 400px;
}

.CatalogExportModalV2__Product {
  display: flex;
  justify-content: space-between;

  &:nth-child(odd) {
    background-color: #e7f4f7;
  }

  div {
    flex-basis: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  a {
    margin-right: 5px;
  }
}

.catalogExportModalV2__footerStep {
  color: $brand-secondary;
}

.catalogExportModalV2__footerLeft {
  &.hidden {
    visibility: hidden;
  }
}

.catalogExportModalV2__footerRight {
  margin-right: 15px;

  &.disabled {
    background: none;
  }
}

.CatalogExportModalV2__disclaimer {
  text-align: center;
}
