@import 'src/styles/vars';

.ListTablePanelContainer {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: default;
}

.ListTablePanel {
  position: fixed;

  width: 330px;
  max-height: 560px;
  padding: 20px 10px;

  white-space: normal;

  background: $brand-white;
  color: $brand-black;
  border-radius: 4px;

  box-shadow: 0 0 8px $gray-light;

  .ListTablePanel__body {
    overflow-y: auto;
  }
  .ListTablePanel__header {
    min-height: auto;
  }

  .ListTablePanel__headerTitle {
    margin-bottom: 1em;
    width: 260px;
    overflow: hidden;
  }

  .ListTablePanel__close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: $brand-default;
    cursor: pointer;
  }
}
