.HierarchiesSelector .LogisticalHierarchyIcon {
  display: inline-block;
  margin: 3px 10px;
}

.HierarchiesSelector__optionLabel {
  font-weight: bold;
  font-style: italic;
}

.HierarchiesSelector .TurboSelect__value-container--has-value {
  padding-left: 0;
}
