@import 'src/styles/vars';

.SimpleModal {
  position: absolute;
  top: 50%;
  background: $brand-white;
  padding: 2em;
  border-top: 4px solid $brand-primary;
  border-radius: 4px;
  box-shadow: 0 6px 12px $gray-light;
  z-index: 10;
  h1,
  p {
    margin: 0;
    padding: 0 0 1rem 0;
  }
  h1 {
    font-weight: 500;
    font-size: 1.2rem;
  }

  &.SimpleModal--small,
  &.SimpleModal--medium,
  &.SimpleModal--big {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.SimpleModal--small {
    width: 400px;
  }
  &.SimpleModal--medium {
    width: 600px;
  }
  &.SimpleModal--big {
    width: 800px;
  }
  &.SimpleModal--relative {
    left: 20%;
    right: 20%;
    transform: translateY(-50%);
  }
}

.SimpleModal__close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0 0.5rem 0 0;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 2em;
  color: $brand-primary;
  &:hover {
    color: $brand-secondary;
  }
}
