@import 'src/styles/vars';

.MaturityCompletion {
  margin-right: 3px;

  .ProgressBar {
    margin-top: -5px;
    justify-content: space-between;
  }

  .ProgressBar__bar {
    width: 82%;
  }
}

.MaturityCompletion__maturityLabel {
  color: $brand-default;
  font-weight: 300;
}

.MaturityCompletion__lateLabel {
  color: $brand-danger;
  font-weight: 300;
  display: flex;
}
