@import 'src/styles/vars';

$button-lg-height: 3rem;
$button-lg-padding: 0.5rem;

.AnchorNavItem__content {
  position: relative;
  display: block;
  line-height: $button-lg-height - ($button-lg-padding * 2);
  left: $padding-base-horizontal;

  .Ellitips {
    display: inline-block;
    vertical-align: bottom;
  }
}
