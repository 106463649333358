@import 'src/styles/colors';
$margin-between-blocks: 10px;
$border-color: #ccc;

.PreviewSourcing__loading {
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
}

.PreviewSourcing {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.PreviewSourcing__left {
  flex-grow: 6;
  margin-right: $margin-between-blocks;
  min-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.PreviewSourcing__right {
  flex-grow: 4;
  min-width: 40%;
  & div:last-child {
    border: 0;
  }
}

.PreviewSourcing__images,
.PreviewSourcing__description,
.PreviewSourcing__right {
  border: 1px solid $border-color;
  background: #fff;
  flex-grow: 1;
}

.PreviewSourcing__images,
.PreviewSourcing__description {
  padding: 20px;
}

.PreviewSourcing__organization,
.PreviewSourcing__name,
.PreviewSourcing__ingredients,
.PreviewSourcing__labels {
  padding: 20px;
  border-bottom: 1px solid $border-color;
}

.PreviewSourcing__organization {
  padding-bottom: 0;
}

.PreviewSourcing__images {
  display: flex;
  justify-content: center;
  margin-bottom: $margin-between-blocks;
}

.PreviewSourcing__highlight {
  font-weight: 400;
}

.PreviewSourcing__description {
  font-size: 1rem;
}

.PreviewSourcing__smallTitle {
  font-size: 1rem;
  color: $brand-highlight;
  font-weight: 300;
}

.PreviewSourcing__labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.PreviewSourcing__label {
  margin-right: 10px;
}

.PreviewSourcing__category {
  padding: 0.25rem 0.75rem;
  display: inline-block;
  background-color: #d8d8d8;
  border-radius: 15px;
  margin: 0.1rem;
}
