.MaturityRuleSetModal {
  .InputRadio {
    margin-bottom: 0.5rem;
    min-width: 500px;
  }

  .TurboSelect {
    margin-bottom: 2em;
  }
  .Modal__body {
    min-height: 560px;
  }
  #deadline_selector_datepicker {
    margin-left: 0.2rem;
  }
}
