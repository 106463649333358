@import 'src/styles/vars';

$selectable-background: lighten($gray-lighterer, 5%);

.ConceptLabel {
  text-align: center;
}

.ConceptLabel__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ConceptLabel__labelContainer {
  padding-left: $half-margin-base-horizontal;
  max-width: calc(100% - #{$label-height});
}

.ConceptLabel__tag {
  color: $gray-lightest;
  background-color: $brand-primary;
  border-radius: $border-radius-large;
}

.ConceptLabel__tag--selectable {
  color: $gray-dark;
  background-color: $selectable-background;
  cursor: pointer;
}

.ConceptLabel__tag--selected {
  cursor: pointer;
  background-color: $brand-primary;
  color: $gray-lightest;
}

.ConceptLabel__tag--selectable:hover {
  background-color: $alk-background-hover;
  color: $gray-dark;
}

.ConceptLabel__tag--disabled {
  .ConceptLabel__overlay {
    display: block;
  }
}

.ConceptLabel__tag--disabled:hover {
  color: $gray-darker;
  background-color: $selectable-background;
  cursor: initial;
}

.ConceptLabel__overlay {
  background-color: $gray-lightest;
  opacity: 0.65;
  z-index: 20;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
}

.ConceptLabel__picture {
  border-top-left-radius: $border-radius-large;
  border-bottom-left-radius: $border-radius-large;
  margin: auto;
}

.ConceptLabel__pictureContainer {
  height: $label-height;
  width: $label-height;
  background: $brand-white;
  overflow: hidden;
  display: flex;
}

.ConceptLabel__add {
  align-items: center;
  border: 1px dashed $brand-secondary;
  border-radius: $border-radius-large;
  color: $brand-secondary;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: $label-height;
  width: 100%;
}

.ConceptLabel__delete {
  position: absolute;
  top: -6px;
  right: 0;
  border-radius: 50%;
  color: $brand-white;
  background: $brand-secondary;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;
}

.ConceptLabel__delete:hover {
  color: $brand-secondary;
  background: $brand-white;
}
