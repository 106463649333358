@import 'src/styles/colors';

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  height: calc(100% - 2.5rem);
}

.imageCanvas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  height: 370px;
  min-height: 370px;
  margin-bottom: 1rem;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.inputs {
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.imageWithMetadataWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.metadataWrapper {
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 1rem;
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}
