.BulkActionAddRecipients_products {
  margin-top: 1rem;
}

.BulkActionAddRecipients_recipients .ChoiceTreeItem__item {
  padding-left: 1.5rem;
}

.Dropdown__list li button.ActionOption {
  // [I ♥ CS]S
  padding: 0.5rem;
}

.BulkActionAddRecipients_countproducts {
  font-weight: bold;
}
