@import 'src/styles/colors';

.Pagination {
  display: flex;
  align-items: center;

  i.mdi {
    color: $brand-secondary;
    cursor: pointer;
    line-height: 24px;

    &:hover:not([disabled]) {
      color: $brand-primary;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.Pagination__pages {
  flex: 1;
  text-align: center;
}
