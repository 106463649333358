@import 'src/styles/colors';

.DataOpsInfoPlugin__icon {
  color: $brand-highlight;
  font-size: large;
}

.DataOpsInfoPlugin__label,
.DataOpsInfoPlugin__cancel_label {
  padding-left: 0.25rem;
}

.DataOpsInfoPlugin__diffBtn {
  margin-left: 1rem;
}
