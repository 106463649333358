@import 'src/styles/vars';

.PublicationSummary {
  max-width: 1000px;
  margin: auto;
}

.PublicationSummary__search {
  padding-bottom: 2rem;
}
