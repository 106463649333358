.ReportingList {
  position: relative;

  .ListActionsSelectAll {
    padding-left: 1rem;
  }

  &.ReportingList--demo {
    .ReportingTableRow:nth-child(1n + 3) {
      filter: blur(5px);
    }
  }
}

.Reporting__spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 0;
}
