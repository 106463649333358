@import 'src/styles/vars';

.VideoModal__link {
  padding: $padding-large-vertical 0;
}

.ProductVideoForm__scope {
  height: 42px;
  padding-top: 15px;
}

.Actions__supportModal {
  margin: $margin-base 0 2 * $margin-base 0;
}
