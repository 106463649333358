@import 'src/styles/vars';

.PriceWaterfall {
  padding-left: 1em;
  padding-right: 1em;
}

.PriceWaterfall__taxes {
  margin-top: $half-margin-base;
  margin-bottom: $half-margin-base;
}

.PriceWaterfall__label {
  margin-top: $half-margin-base;

  .InputLabel {
    height: initial;
  }
}

.PriceWaterfall__calculator {
  margin-top: 7px;

  .Helper {
    margin-top: 7px;
  }
}

.PriceWaterfall__calculator .Helper__left {
  .mdi.mdi-calculator {
    font-size: 22px;
    color: $brand-success;
    margin-right: 0.2rem;
  }

  > span {
    margin-right: 0.4rem;
  }
}

.PriceWaterfall__calculator__validateAll {
  text-align: right;
}

.PriceWaterfall__calculator__infoBlock {
  display: flex;
  align-items: center;
  margin-left: 2rem;

  > span {
    margin-right: 0.5rem;
  }

  > .SimpleSelect {
    background-color: white;
  }
}
