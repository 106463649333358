@import 'src/styles/vars';

.PriceWaterfalls {
  margin-bottom: $half-margin-base;
}

.PriceWaterfalls--bloc h4 {
  padding-left: 1em;
}

.PriceWaterfalls--bloc:not(:first-child) {
  margin-top: 3em;
  padding-top: 2em;
  border-top: solid 1px $gray-light;
}
