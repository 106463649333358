@import 'src/styles/colors';

.ReportingDemoInfo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 1rem;
    right: 1rem;
    height: 2px;
    margin-top: -1px;
    overflow: hidden;
    background-image: linear-gradient(to right, #b8b8b8 50%, transparent 0%);
    background-position: bottom;
    background-size: 4px 2px;
    background-repeat: repeat-x;
  }
}

.ReportingDemoInfo__label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0 2rem;
  color: white;
  background-color: $brand-highlight-secondary;
  border-radius: 1rem;
  text-transform: uppercase;
}
