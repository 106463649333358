@import 'src/styles/vars';

.Loader {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  background: linear-gradient(to right, $brand-success, $brand-primary);
  background-size: 100%;
  height: 3px;
  z-index: 120;

  animation: indeterminateLoadingAnimation 3s ease infinite;
}

@keyframes indeterminateLoadingAnimation {
  from {
    left: -350px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
