@use 'sass:color';
@import 'src/styles/vars';
@import '../vars';

.ProductReviewStatus {
  vertical-align: middle;
  white-space: nowrap;

  &__icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__label {
    padding-left: $padding-xs-horizontal;
  }

  @each $type, $color in $action-statuses {
    &--#{$type}.mdi {
      color: $color;
    }
  }
}
