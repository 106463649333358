@import 'src/styles/vars';

.InputValidityHint {
  .mdi {
    font-weight: bold;
    display: inline-block;
    transition: transform 100ms ease-in;
    transform: scale(1);
    &.appear-enter-active {
      transform: scale(1.3);
    }
  }
  .mdi-check-circle {
    color: $alk-green;
  }
  .mdi-close-circle {
    color: $alk-red;
  }
}
