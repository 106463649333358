@import 'src/styles/vars';

.cellContents {
  :global(.mdi) {
    vertical-align: middle;
    font-size: 1.3rem;

    &:global(.mdi-calendar-blank) {
      color: $gray-slightly-lighter;
    }

    &:global(.mdi-calendar-clock) {
      color: $brand-orange;
    }

    &:global(.mdi-calendar-alert) {
      color: $brand-danger;
    }

    &:global(.mdi-calendar-check) {
      color: $brand-success;
    }
  }
}

.cell {
  :global(.ListTablePanel) {
    max-height: 560px;
    height: 560px;
  }
}

.information {
  font-style: italic;
  color: $gray-slightly-lighter;
}

.others {
  color: $brand-primary;
  font-weight: 300;
  font-size: 0.8rem;
}
