@import 'src/styles/vars';

.UserLabelModal__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
}

.UserLabelModal__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  span {
    margin-top: 5px;
    margin-right: 10px;
  }
}

.UserLabelModal__formRow {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem 1rem 1rem;

  &.UserLabelModal__formRow--tags {
    margin-top: 20px;
    align-items: flex-start;
  }

  & > label {
    margin: 0;
    padding-right: 1rem;
    text-align: right;
  }
}

.UserLabelModal__labels {
  @include ul();
  flex: 1;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.UserLabelModal__add {
  margin-top: $margin-base;
  padding: $padding-base-horizontal;
  border: 1px dashed $brand-primary;
  width: 100%;
}

.UserLabelModal__addLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $brand-primary;
  padding-right: 1rem;
}
