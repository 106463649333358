@import 'src/styles/vars';

.PriceLevel {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.PriceLevel__labelWrapper {
  flex-basis: 75%;
  align-self: stretch;
  display: flex;
  align-items: center;
  border-radius: $border-radius-small;
  padding-left: $padding-base-horizontal;
  font-weight: 400;
  background: $gray-really-light;
}

.PriceLevel__valueWrapper {
  padding: $padding-xs-horizontal;
  flex-basis: 25%;
  display: flex;
}

.PriceLevel__value {
  flex-grow: 1;
  flex-basis: 0;
  + .PriceLevel__value {
    margin-left: 5px;
  }

  position: relative;
}

.PriceLevel__estimation {
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
}

.PriceLevel__estimationIcon {
  &--fail {
    color: $brand-highlight;
    cursor: pointer;
  }

  &--wrong {
    color: $alk-red;
    cursor: pointer;
  }

  &--correct {
    color: $alk-green;
  }
}

.PriceLevel__estimationToolTip {
  text-align: center;
}

.PriceLevel__estimation__failureText {
  max-width: 200px;
}

.PriceLevel__estimatedValue {
  font-style: italic;
  color: $gray-medium-dark;
}
