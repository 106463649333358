@import 'src/styles/vars';

.price-waterfall-collapsible {
  margin-top: 15px;
  border-radius: 8px;
  border: 1px $gray-lighterer solid;
  position: relative;
  .Collapsible__header {
    padding: 10px 20px;
    border-radius: 8px;

    span {
      margin-right: 25px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.Collapsible__header--collapsed {
      &:hover {
        background-color: $gray-really-light;
      }
    }
  }

  &.price-waterfall-collapsible-error {
    .Collapsible__header {
      background-color: $brand-danger-hover;

      &.Collapsible__header--collapsed {
        &:not(.Collapsible__header--disabled):hover {
          background-color: $brand-danger-disabled;
        }

        &.Collapsible__header--disabled {
          &:hover {
            background-color: $brand-danger-hover;
          }
        }
      }
    }
  }

  .PriceWaterfallTitleError {
    color: $brand-danger;

    .mdi {
      vertical-align: middle;
    }
  }

  .Collapsible__content {
    border-radius: 8px;

    .PriceWaterfall {
      margin: 15px 0;
    }
  }

  + .price-waterfall-collapsible {
    margin-left: 50px;
    margin-top: 10px;

    &:before {
      border-left: 3px $gray-lighterer solid;
      border-bottom: 3px $gray-lighterer solid;
      position: absolute;
      content: '';
      display: block;
      width: 22px;
      height: 50%;
      left: -25px;
    }

    &:after {
      border-left: 3px $gray-lighterer solid;
      position: absolute;
      content: '';
      display: block;
      width: 22px;
      height: calc(50% + 20px);
      left: -25px;
      top: 50%;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}

.price-waterfall-collapsibleTitle {
  display: flex;
  align-items: center;
}
