@import 'src/styles/colors';

.Navbar__CollaborationLink {
  display: inline-block;
  background: transparent;
  border: 0;
  font-size: 1.5rem;
  color: white;
  outline: none;
  margin-right: 5px;
  margin-left: 3px;

  &:focus {
    outline: none;
    color: white;
  }

  &:hover {
    color: white;
  }

  &.Navbar__CollaborationLink-active {
    color: $brand-primary;
  }
}

.CollaborationUnread {
  position: relative;
}

.CollaborationUnread__forum {
  font-size: 24px;
  line-height: 40px;
  padding-right: 3px;
}

.CollaborationUnread__forum--animate {
  animation: shake 2s ease-in-out both;
}

@keyframes shake {
  15% {
    transform: rotate(20deg);
  }
  35% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(7deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
