@mixin ul() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin minSize($size) {
  min-width: $size;
  min-height: $size;
}

@mixin checkboxOnly() {
  display: inline-block;
  width: 15px;
  height: 15px;
  input[type='checkbox'].checkbox + label {
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    &:before {
      top: 0;
      margin: 0;
    }
    &:after {
      top: 1px;
    }
  }
}
