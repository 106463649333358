@import 'src/styles/vars';

.BulkEditModalEdit__summary {
  margin-top: $half-margin-base;
  .Tipster {
    margin: 0;
  }
}

.BulkEditModalEdit__summaryText {
  margin-top: $half-margin-base;
  font-weight: 500;
}

.BulkEditModalEdit__summaryText--success {
  color: $brand-success;
}

.BulkEditModalEdit__displayGroup {
  margin-top: $half-margin-base;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.BulkEditModalEdit__displayGroupContent {
  flex-grow: 1;
  > .DisplayGroup--condensed {
    margin: 0;
  }
}

.BulkEditModalEdit__deleteBtn {
  margin-left: $margin-base-horizontal;
  color: $gray-light;
  cursor: pointer;
  font-size: x-large;
  &--hidden {
    visibility: hidden;
  }
}

.BulkEditModalEdit__selectWrapper {
  margin-top: $margin-base;
  padding: $padding-base-horizontal 0;
  display: inline-flex;
  border: 1px dashed $brand-primary;
  width: 100%;
}

.BulkEditModalEdit__select {
  flex-grow: 1;
  &--empty {
    margin-top: $margin-base;
  }
}

.BulkEditModalEdit__selectIcon {
  color: $brand-primary;
  font-size: x-large;
  padding-right: $padding-base-horizontal;
}
