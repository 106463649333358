@import 'src/styles/vars';

.TargetCatalogImportModal__body {
  margin-bottom: 50px;
  min-width: 730px;
}

.TargetCatalogImportModal__description {
  font-weight: 500;
}

.TargetCatalogImportModal__dropzone {
  width: 100%;
  height: 65%;
  padding: 15px;
  border: 1px $brand-primary dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.TargetCatalogImportModal__dropzone--active {
    background-color: $blue-light;
    border-color: $brand-secondary;
  }
}

.Dropzone__label {
  cursor: pointer;
  &:hover {
    color: $brand-primary-hover;
  }
}

.TargetCatalogImportModal_Upload {
  text-align: center;
}

.TargetCatalogImportModal__Readme--Step {
  height: 33px;
  line-height: 33px;
  margin-bottom: $margin-base;

  span {
    margin-right: 30px;
  }
}

.TargetCatalogImportModal__Readme--Bullet {
  border-radius: 50%;

  width: 37px;
  height: 37px;
  float: left;

  margin-right: 20px;

  background: #fff;
  border: 3px solid $brand-primary;
  color: $brand-primary;
  text-align: center;

  font-size: 20px;
  font-weight: bold;
}

.TargetCatalogImportModalMenu_Body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.TargetCatalogImportModalMenu_Col {
  flex: 50%;
  text-align: center;
}

.TargetCatalogImportModalMenu__separator {
  border-right: dashed #3fa2b2 2px;
}
