@import 'src/styles/vars';

.TabbedFilters .Filter__fieldset {
  padding: 0;
}

.TabbedFilters__title {
  font-size: 1.25rem;
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 300;
}

.TabbedFilters__fieldset {
  margin: 0;
  padding: 20px $margin-base;
}

.TabbedFilters__tabList {
  font-size: 14px;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 5px 0;
  width: 100%;
  justify-content: space-around;
}

.TabbedFilters__tab {
  display: block;
  width: 100%;
  padding-bottom: 3px;
  text-align: center;
  border: none;
  font-weight: 300;
  color: $brand-secondary;
  border-bottom: 3px solid $gray-slightly-lighter;
  cursor: pointer;
}

.TabbedFilters__tab--selected {
  font-weight: 500;
  border-bottom: 3px solid $brand-secondary;
}
