@import 'src/styles/vars';

.ListCatalogMessageColumn {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.3rem;

  &--ALL_ACKED {
    color: $brand-success;
  }
  &--I_NEED_TO_ACK {
    color: $brand-danger;
  }
  &--WAITING_FOR_OTHER_TO_ACK {
    color: $brand-highlight;
  }
}
