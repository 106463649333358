@import 'src/styles/vars';

.emptyTabContents {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.emptyTabContentsTitle {
  font-weight: 500;
  font-size: 1rem;
  color: $gray-medium-dark;
}

.emptyTabContentsText {
  font-weight: 300;
  font-size: 0.8rem;
  color: $gray-medium-dark;
}
