@import 'src/styles/vars';

.PNQTable {
  margin: 0;
  padding: 0;
}
.PNQTable__item {
  &:nth-child(even) {
    background-color: $table-striped-bg;
  }
}
