@import 'src/styles/vars';

.ProductHeaderNavigation > a.ProductHeaderNavigation__link {
  color: $brand-primary;
  position: relative;
  text-decoration: none;
  margin-right: 1em;
  font-weight: 300;
  &:hover,
  &:active {
    color: $brand-secondary;
  }
  &--active {
    font-weight: 400;
    border-bottom: solid 2px $brand-secondary;
    color: $brand-secondary;
  }
  &.ProductHeaderNavigation__link--logas {
    color: $brand-warning;
    border-color: $brand-warning;
  }
}
