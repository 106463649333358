@import 'src/styles/vars';

.set-as-packshot-button {
  margin-bottom: $half-margin-base;
}

.Asset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0px;
  gap: 34px;
}
