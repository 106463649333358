@import 'src/styles/vars';

.lifecycleIcon {
  margin-right: 5px;
}

.lifecycleNA {
  color: $gray-medium;
  font-style: italic;
}
