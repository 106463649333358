@import 'src/styles/vars';

.UnreadCount {
  background: $brand-danger;
  border-radius: 0.8em;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.6em;
  margin-bottom: 14px;
  margin-left: 1px;
  min-width: 1.6em;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;

  &--absolute {
    position: absolute;
    right: 0;
    top: 5px;
  }

  &.UnreadCount--top {
    top: -5px;
    right: -10px;
  }
}

.UnreadDot {
  position: absolute;
  background: $brand-danger;
  border-radius: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 0;
  right: -0.5rem;
}
