@import 'src/styles/vars';

.PromoteModalPreview {
  border: 1px solid $gray-border;
  border-radius: 3px;
  padding: 1rem;
  font-size: small;
}

.PromoteModalPreview__logo {
  border-bottom: 1px solid $gray-border;
  text-align: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;

  img {
    height: 50px;
  }
}

.PromoteModalPreview__intro {
  margin: 0.7rem 0;
  font-weight: 500;
}

.PromoteModalPreview__productPackshot {
  margin: 0 0.5rem;
  text-align: center;

  img {
    max-width: 25%;
  }
}

.PromoteModalPreview__gtin {
  text-align: center;
}

.PromoteModalPreview__description {
  margin: 0.5rem 0;
}

.PromoteModalPreview__infos {
  color: $brand-primary;
  font-weight: 500;
}

.PromoteModalPreview__button {
  background: #48b071;
  color: #ffffff;
  padding: 0.4rem 1.2rem;
  margin: 1rem 0;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  width: 50%;
  margin-left: 25%;
}
