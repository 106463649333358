@import 'src/styles/vars';

.ImageCheckbox__item {
  position: relative;
}

.SelectableImage,
.SelectableImage--active,
.SelectableImage--disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 180px;
  border: 1px dashed $brand-primary;
  border-radius: 4px;
  padding: 0.5em;
  &:hover {
    background-color: rgba($brand-primary, 0.1);
    cursor: pointer;
  }
}

.SelectableImage__imageContainer,
.SelectableImage__imageContainer--active {
  max-width: 100px;
  margin: 0 auto;
  max-height: 100px;
}

.SelectableImage__label,
.SelectableImage__label--active {
  margin: auto;
  width: 80%;
}

.SelectableImage__label__code,
.SelectableImage__label__code--active {
  font-size: 0.8em;
  font-weight: 500;
}

.SelectableImage__label__text,
.SelectableImage__label__text--active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SelectableImage__add {
  color: $brand-primary;
  text-align: center;
  & i {
    margin-right: 0.2em;
  }
}

.SelectableImage--active,
.SelectableImage--disabled {
  border: 2px solid $brand-primary;
  background-color: rgba($brand-primary, 0.1);
}

.SelectableImage__add--active {
  color: $brand-primary;
  & span {
    display: none;
  }
  & i {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    font-size: 1.2em;
    background: white;
    border-radius: 50%;
    overflow: hidden;
    width: 1em;
    line-height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      transform: rotate(45deg);
    }
  }
}

.SelectableImage--disabled {
  &:hover {
    cursor: inherit;
  }
  > .SelectableImage__add--active {
    display: none;
  }
}
