@import 'src/styles/vars';

.MultiLevelDropdown {
  color: $brand-secondary;
  line-height: 1em;
  list-style: none;
  margin: 0;
  z-index: 2;
  cursor: pointer;

  &__toggle {
    font-weight: 300;
    color: $brand-secondary;
    position: relative;
    background: 0;
    margin: 0;
    border: 1px solid $brand-secondary;
    border-radius: 4px;
    outline: 0;
    &--bottom > span {
      border: none;
      box-shadow: none;
      border-radius: 4px;
      &:after {
        display: inline-block;
        position: relative;
        top: -2px;
        content: '';
        width: 8px;
        height: 8px;
        border-right: 1px solid $brand-secondary;
        border-bottom: 1px solid $brand-secondary;
        transform: rotate(45deg);
        margin-left: 1rem;
        margin-right: 0;
      }
    }
    &--right {
      & > span {
        padding-right: 2.5rem;
        &:before {
          display: block;
          content: ' ';
          float: right;
          width: 0.5rem;
          height: 0.5rem;
          border: 4px solid transparent;
          border-right-width: 0;
          border-left-color: $brand-secondary;
          margin: 0.25rem -1.5rem 0 0;
        }
      }
    }
  }

  &__label {
    color: $brand-secondary;
    line-height: 1rem;
    font-weight: 300;
    font-size: 1rem;
    white-space: nowrap;
    padding: 0.75rem 1rem;
    display: inline-block;
    background: #fff;
    min-width: 100%;

    &--disabled {
      color: $gray-light;
      cursor: not-allowed;
    }

    &:hover {
      background: $brand-secondary-hover;
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    display: none;
    left: -99999px;
    height: 0;
    overflow: hidden;
    list-style: none;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  }

  &__content--stacked &__label {
    border: none;
  }

  &__toggle--bottom,
  &__toggle--right {
    position: relative;
  }
  &__toggle--bottom#{&}__toggle--open > &__content {
    left: 0;
    display: inherit;
  }
  &__toggle--right#{&}__toggle--open > &__content {
    top: 0;
    display: inherit;
    left: 100%;
  }
  &__toggle--open > &__content {
    height: auto;
    overflow: visible;
  }
}

.MultiLevelDropdown--disabled .MultiLevelDropdown__toggle,
.MultiLevelDropdown--disabled .MultiLevelDropdown__toggle--bottom > span:after {
  color: $gray-light;
  border-color: $gray-light;
}
