@import 'src/styles/vars';

.LogisticalHierarchyCreateUnitDropdown {
  .Dropdown__button {
    display: flex;
    align-items: center;
  }
}

.LogisticalHierarchyCreateUnitDropdown__label {
  display: flex;
  i {
    font-size: large;
    margin-right: $half-margin-base-horizontal;
  }
}
