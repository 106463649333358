@use 'sass:math';

@import 'src/styles/vars';

.ProductSuggestions__row {
  margin: 0.2rem 0;
}

.ProductSuggestions__buttons {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;

  .Button {
    margin-left: 1rem;
  }
}

.ProductSuggestion__Diff {
  padding: 20px;
}

.ProductSuggestion__DiffHeader {
  font-weight: 500;
  padding: math.div($margin-base, 40);
}

.ProductSuggestion__DiffLine {
  padding: math.div($margin-base, 4) 0;
  border-top: 1px solid $gray-light;
  display: flex;
  align-items: baseline;
}

.ProductSuggestion__DiffEmpty {
  color: $gray-light;
  font-style: italic;
}

.ProductSuggestion__CurrentList {
  padding-left: 0em;
  margin-bottom: 0em;
}

.ProductSuggestion__CurrentItem {
  &.ProductSuggestion__CurrentItem--single {
    list-style-type: none;
  }
}

.ProductSuggestion__SuggestionItem {
  padding: math.div($margin-base, 8) 0;
  border-bottom: 1px solid $gray-light;
  display: flex;
  align-items: baseline;

  &.ProductSuggestion__SuggestionItem--last {
    border-bottom: 0;
  }
}

.ProductSuggestion__DiffActions {
  display: flex;
  justify-content: space-around;
}

.ProductSuggestions__busy {
  color: $brand-secondary;
  display: flex;
  align-items: baseline;

  .Spinner {
    margin-left: $half-margin-base;
  }
}
