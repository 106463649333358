@use 'sass:math';

@import 'src/styles/vars';

.InputTag__label {
  display: inline-block;
}

.InputTag__help {
  margin-left: math.div($margin-base-horizontal, 3);
  top: 0;
}
