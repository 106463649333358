@import 'src/styles/vars';

.RetailerFooterActions {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.RetailerFooterActions__buttons {
  display: flex;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.RetailerFooterActions__icon {
  margin-right: 0.2rem;
  color: $brand-success;
}
