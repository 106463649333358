@import 'src/styles/vars';
.AnchorStickyNav {
  button:focus {
    outline: 0;
  }
}

.AnchorStickyNav__stickyContainer {
  position: absolute;
  left: 0;
  width: calc(100% - 1rem);
  height: 100%;

  .AnchorStickyNav__stickyElement--sticky {
    padding-top: calc(2.5rem + 5px);
  }
}

.AnchorStickyNav__stickyElement {
  display: flex;
  flex-direction: column;
  padding-bottom: 75px; // size of footer
  max-height: 100vh;
}

.AnchorStickyNav__title {
  margin-bottom: 1rem;
}

.AnchoredBlock--collapsed {
  cursor: pointer;
  margin: 0;
  &:hover {
    background: #fcfcfc;
    color: black;
  }
}

.AnchoredBlock--open {
  .FormGroup__header {
    cursor: pointer;
    margin: 0;
    &:hover {
      color: black;
    }
  }

  .FormGroup__header + .FormField--raguelError:nth-child(2) {
    margin-top: 20px;
  }
}
