.DisplayGroup__openButton {
  color: inherit;
  font-size: 1.5rem;
  line-height: 1.8rem;
  float: right;
  outline: none;
  &:focus,
  &:hover,
  &:active {
    color: inherit;
    outline: none !important;
    text-decoration: none;
  }
}
