@import 'src/styles/colors';

.ListProductHierarchies {
  width: 100%;
  height: 100%;
}

.ListProductHierarchies__spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListProductHierarchies__Title {
  text-align: center;
  font-weight: 400;
  margin-bottom: 25px;
}

.ListProductHierarchies__body {
  padding-bottom: 30px;

  table {
    width: 100%;
  }

  th {
    color: $gray-medium;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
  }

  tr {
    border-bottom: 1px solid $gray-light;
  }
}

.ListProductHierarchies__image {
  width: 64px;
  max-width: 64px;
  height: 64px;
  max-height: 64px;
  padding: 5px;
}

.ListProductHierarchies__gtin {
  width: 150px;
}

.ListProductHierarchies__hierarchies {
  width: 300px;
}

.ListProductHierarchies__removeButton {
  width: 50px;
  text-align: center;
  cursor: pointer;

  i {
    color: $gray-medium;
  }
}

.ListProductHierarchies__row--grayed {
  background-color: $gray-lighter;
}
