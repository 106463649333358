@import 'src/styles/vars';

.IconWithTextButton {
  width: 112px;
  margin-right: 24px;
  margin-bottom: 24px;
  text-align: center;
  cursor: default;
  display: block;

  &__Title {
    font-weight: 300;
    font-style: normal;
    color: $gray-darker;
    text-align: center;
  }

  &__Text {
    &--withTooltip {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--active &__Title {
    font-weight: 500;
  }

  &__Icon {
    width: 88px;
    height: 88px;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 7px 0px $gray-slightly-lighter;
    background-color: $brand-white;
    border-radius: 24px;
    border: 1px solid $gray-really-light;
    margin: auto;
    margin-bottom: 24px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  &--variant-primary:not(#{&}--disabled):hover &__Icon {
    border: 1px solid $brand-primary;
    box-shadow: none;
  }

  &--disabled#{&}--variant-primary:hover &__Icon {
    cursor: not-allowed;
  }

  &--active &__Icon {
    background-color: $alk-background-hover;
    border: 1px solid $brand-primary;
  }

  &--variant-secondary &__Icon {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    box-shadow: none;
    padding: 8px;
    margin-bottom: 0;

    &--container {
      width: 72px;
      height: 72px;
      border-radius: 72px;
      border: 1px solid transparent;
      margin: auto;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__container-align-icons {
      margin-bottom: 33px;
    }
  }

  &--variant-secondary:not(#{&}--disabled):hover &__Icon,
  &--variant-secondary#{&}--active &__Icon {
    &--container {
      border: 1px solid $brand-primary;
    }
  }

  &--disabled#{&}--variant-secondary:hover &__Icon,
  &--variant-secondary#{&}--disabled &__Icon {
    &--container {
      cursor: not-allowed;
    }
  }
}
