@import 'src/styles/colors';

.ExpandedListFilters {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 300px;
}

.ExpandedListFilters__scrollable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.ExpandedListFilters__action {
  display: block;
  margin: 1rem;

  &:hover {
    color: $brand-primary;
  }

  .mdi-plus-circle {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-right: 6px;
  }
}
