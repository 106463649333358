@import 'src/styles/vars';

.ReportingCharts {
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0 1rem;
  background-color: $brand-white;
}

.ReportingChartsSticky {
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0 1rem;
  background-color: $brand-white;
  position: sticky;
  left: 0;
}
