.DataOpsDiffBlock {
  .Spinner {
    display: block;
  }
}

.DataOpsDiffBlock__table {
  width: 100%;
  margin: 0;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;

  .ProductHistoryDiffCard__row.row,
  .ProductHistoryDiffCard__header.row {
    margin: 0;
  }
}
