@import 'src/styles/colors';

.ListTableHeaderCell {
  color: $gray-medium;

  &.ListTableHeaderCell[data-selected='true'] {
    color: $gray-dark;
  }

  &:first-child {
    .ListTableHeaderCell__label {
      padding-left: 1rem;
    }
  }

  &.ListTableHeaderCell--multilines {
    vertical-align: bottom;

    .ListTableHeaderCell__content {
      align-items: flex-end;
    }

    .ListTableHeaderCell__label {
      white-space: normal;
    }

    .ListTableHeaderCell__sortable {
      padding-bottom: 0.25rem;
    }
  }

  &.ListTableHeaderCell--sticky {
    position: sticky;
    left: 0;
    z-index: 10;
    box-shadow: 5px 0 5px 0 $gray-slightly-lighter;
  }
}

.ListTableHeaderCell--firstRowSticky {
  position: sticky;
  top: 0;
  background-color: #e9ecf0;
  z-index: 1;
}

.ListTablHeaderCell--firstColumnSticky {
  position: sticky;
  left: 0;
  z-index: 2;
}

.ListTableHeaderCell__content {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.ListTableHeaderCell__sortable {
  display: inline-block;
}

.ListTableHeaderCell__label {
  display: inline-block;
  padding: 0 0.5rem 0.5rem 0.5rem;
  font-weight: 500;
  cursor: default;
  white-space: nowrap;
}

.ListTableHeaderCell__sortIcons {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;

  & > i.mdi {
    font-size: 1rem;
    line-height: 0.6rem;
    color: $brand-primary;

    &.mdi[data-selected='true'] {
      color: $brand-secondary;
    }
  }
}
