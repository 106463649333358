@import 'src/styles/vars';
@import 'src/styles/colors';

.Textile_variants__add {
  margin-top: $half-margin-base;
  border: 1px dashed $brand-primary;
  padding: $padding-small-vertical $padding-small-horizontal
    $padding-small-horizontal * 3 $padding-small-vertical;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Textile_variants__add__infos {
  align-self: flex-start;
  i {
    margin-right: 5px;
  }
}

.Textile_variants__add__radio {
  margin-bottom: 1em;
}

.Textile_variants__addFromFront {
  min-width: 30em;
}

.Textile_variants__addFromImport {
  min-width: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Textile_variants__addFromFront__or {
  margin: 0.5em 0;
  text-align: center;
}

.Textile_variants__addFromFront__fromGtin {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .InputReference {
    flex-grow: 3;
    margin-right: 1em;
  }
}

.Textile_variants__addFromImport__info {
  color: $gray-medium-dark;
  margin-bottom: 2em;
}

.Textile_variants__addFromFront__fromGtin_label {
  margin-right: 5px;
}
