@import 'src/styles/vars';

.LogisticalHierarchyUnit {
  display: flex;
  height: 80px;
  align-items: center;
  border: 1px solid $brand-secondary;
  border-radius: $border-radius-large;
  margin-top: 10px;
  padding-left: 10px;
  max-width: 100%;
}

.LogisticalHierarchyUnit__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: $half-margin-base-horizontal;
  margin-right: $margin-base-horizontal;
  min-width: 0;
  .InputText {
    flex-grow: 0;
  }
  input {
    padding: 2px;
    height: 26px;
  }
  .InputDisabled {
    padding: 2px;
    height: 26px;
    min-height: 26px;
  }
}

.LogisticalHierarchyUnit__infoFirstRow {
  display: flex;
  align-items: baseline;
  min-width: 0;
  flex-shrink: 1;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
  .InputText {
    flex-grow: 0;
  }
  input {
    padding: 2px;
    height: 26px;
  }
  .InputDisabled {
    padding: 2px;
    height: 26px;
    min-height: 26px;
  }
}

.LogisticalHierarchyUnit__label {
  font-weight: 400;
  display: flex;
  min-width: 0;
  flex-shrink: 1;
}

.LogisticalHierarchyUnit__reference {
  flex-shrink: 0;
  font-weight: 300;
  input {
    min-width: 200px; // minimum length for 14 digits
  }
}

.LogisticalHierarchyUnit__quantity {
  display: flex;
  align-items: center;
  .InputText {
    width: 75px;
  }
  input {
    text-align: right;
  }
  .InputDisabled {
    justify-content: flex-end;
  }
}

.LogisticalHierarchyUnit__quantityLabel {
  margin-left: 5px;
  &--empty {
    color: $brand-danger;
  }
}

.LogisticalHierarchyUnit__actions {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: $half-margin-base-horizontal;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.LogisticalHierarchyUnit__deleteBtn {
  font-size: x-large;
  color: $gray-light;
  &:hover {
    cursor: pointer;
    color: $gray-dark;
  }
}

.LogisticalHierarchyUnit__productReferenceTypeSelector {
  min-width: 110px;
}
