@import 'src/styles/vars';

.subproductTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subproductQuantity {
  background-color: $gray-lighterer;
  margin-right: 1rem;
  height: 35px;
  width: 35px;
  font-weight: 300;
  font-size: small;
  line-height: 35px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  max-width: 35px;
}

.subproductName {
  max-width: 33em;
}
