@use 'sass:math';

$image-path: '/images/front-productstream';

$container-fluid-margin: math.div(25, 12) * 1vw;
$product-container-padding: $container-fluid-margin;

@import 'colors';
@import 'variables';
@import 'mixins';
