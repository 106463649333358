@import 'src/styles/colors';

.ShareStatus {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.125em;

  .ProgressBar__bar,
  .ProgressBar__value {
    border-radius: 10px;
  }

  .ProgressBar {
    width: 65%;
  }
}

.ShareStatus__labels {
  margin: 25px 0;
  text-align: center;
}
.ShareStatus__firstLabel {
  font-size: 1.5em;
  font-weight: 400;
}
.ShareStatus__thirdLabel {
  margin-top: 20px;
}

.ShareStatus__success {
  text-align: center;
}

.ShareStatus__successIcon {
  font-size: 3em;
  color: $alk-green;
}

.ShareStatus__shareSummary {
  font-size: 1.5em;
  color: $alk-green;
}

.ShareStatus__cancelMessage {
  font-size: 1.2em;
  font-weight: 300;
}
