@import 'src/styles/vars';

$email-height: 30px;

.InputEmail__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.InputEmail__email {
  display: flex;
  align-items: stretch;
  padding: 0 0 0 15px;
  margin: 0 5px 5px 0;
  max-width: 100%;
  height: $email-height;
  line-height: $email-height;
  background: $gray-lighter;
  border-radius: 3px;
}

.InputEmail__label {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  &--readOnly {
    padding-right: 15px;
  }
}

.InputEmail__delete {
  display: inline-block;
  box-sizing: border-box;
  width: $email-height;
  height: $email-height;
  background: $brand-primary;
  border: 0;
  margin-left: 15px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: $brand-primary-btn-hover;
  }
}

.InputEmail__input {
  display: inline-block;
  width: 100%;
  flex: 0 0 100%;
}
