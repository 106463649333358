@import 'src/styles/colors';

.PromoteModalBody {
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
}

.PromoteModalBody__form {
  flex: 3;
  min-width: 500px;
}

.PromoteModalBody__preview {
  flex: 2;
  min-width: 300px;
  box-sizing: border-box;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.PromoteModalBody__previewTitle {
  text-align: center;
  font-size: small;
  font-weight: 500;
}

.PromoteModalBody__text {
  font-weight: 500;
  margin-bottom: 2rem;
}

.PromoteModalBody__row {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  .PromoteModalBody__label {
    flex: 1;
    text-align: right;
    margin-right: 1rem;
    font-weight: 400;
  }

  .PromoteModalBody__field {
    flex: 3;
  }

  // override button colors :
  .PromoteModalBody__fieldSelection .Button {
    border-color: $brand-primary;
    color: $brand-primary;
    &:hover {
      border-color: $brand-primary;
      background-color: rgba($brand-primary, 0.2);
      color: $brand-primary;
    }
    &.selected {
      box-shadow: inset 0 0 1px 0 $brand-secondary;
      border: 1px solid $brand-secondary;
      background-color: rgba($brand-primary, 0.2);
      color: $brand-secondary;
      font-weight: 400;
    }
  }

  p {
    margin-bottom: 0;
  }
}
