.CatalogSourcingCellActionMessageWrapper {
  display: block;
  cursor: pointer;
  text-decoration: inherit;

  &,
  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }
}

.CatalogSourcingCellActionMessageWrapperModal__body {
  height: 100%;
}

.CatalogSourcingCellActionMessageWrapperModal__body .sidebar {
  display: none;
}

.CatalogSourcingCellActionMessageWrapperModal__body .chat-module .chat-main {
  width: 100%;
}
