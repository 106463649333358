@import 'src/styles/vars';

.AnchoredMain,
.AnchoredMain.col-md-9 {
  padding-bottom: 300px;
  padding-left: $margin-base;
}

.AnchoredBlock {
  overflow: visible;
}
