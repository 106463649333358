@import 'src/styles/vars';

.ListCatalogSynchronizationStatusColumn {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.3rem;

  &--SUCCESS {
    color: $brand-success;
  }
  &--ERROR {
    color: $brand-danger;
  }
  &--IN_PROGRESS {
    color: $brand-highlight;
  }
  &--UNKNOWN {
    color: '#a0a0a0';
  }
  &--CORRECTION_REQUESTED_BY_RECIPIENT {
    color: $brand-orange;
  }
}
