@import 'src/styles/vars';

.ErrorFallback {
  padding: calc(2rem + 40px) 2rem 2rem 2rem;
  text-align: center;

  pre {
    display: inline-block;
    text-align: initial;
  }

  .btn.btn-link {
    color: inherit;
    text-decoration: underline;
  }
}

.ErrorFallback__logo {
  position: fixed;
  top: 1rem;
  left: 2rem;
  height: 50px;
}

.ErrorFallback__icon {
  font-size: 3em;
  color: $gray-slightly-lighter;
}

.ErrorFallback__title {
  font-weight: 400;
  margin-bottom: 0.25rem;
}

.ErrorFallback__subTitle {
  margin-bottom: 2rem;
}

.ErrorFallback__reload {
  margin-bottom: 4rem;
}

.ErrorFallback__again {
  font-size: 0.9em;
  margin-bottom: 0.25rem;
}

.ErrorFallback__error {
  font-size: 0.9em;
  margin-top: 4rem;
}
