.LongestWidthLabel {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
}

.LongestLabelWidth__hidden {
  visibility: hidden;
  height: 0;
  white-space: nowrap;
}

.LongestLabelWidth__label {
  white-space: nowrap;
}
