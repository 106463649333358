@use 'sass:math';

@import 'src/styles/vars';

.FormFullList {
  margin-top: $half-margin-base-horizontal;
  margin-bottom: $half-margin-base-horizontal;
}

.FormFullList__list--singleItemSingleChild {
  .FormFullList__listItem--singleChild {
    border: none !important;
    height: $field-height-base;
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }
}

.FormFullList__list--singleChild {
  margin-top: $half-margin-base-horizontal;
  display: flex;
  flex-flow: row wrap;
}

.FormFullList__listItem--singleChild {
  flex-basis: 50%;
  display: inline-flex;
  font-size: 0.875rem;
  width: 100%;
  padding: 2px 0;
  align-items: center;

  &:nth-child(odd) {
    border-right: 1px solid $gray-light;
  }

  .FormRadio__radio {
    min-height: inherit;
  }

  .InputRadio {
    padding-bottom: 0;
  }

  .InputLabel {
    height: auto;
  }

  .InputRadio__option + .InputRadio__option {
    margin-left: math.div($margin-base, 3);
  }
}

.FormFullList__title {
  text-transform: uppercase;
}

.FormFullList__listItem--multipleChildren .FormField {
  margin-right: 0;
}

.FormFullList.FormField--flagged {
  padding-right: $padding-small-horizontal;
  width: calc(100% + #{$padding-small-horizontal});
}

.FormFullList__addChoice {
  display: flex;
  border: 1px dashed $brand-primary;
  margin-top: $half-margin-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  align-items: center;
  > i {
    color: $brand-primary;
    font-size: x-large;
    padding-right: $padding-base-horizontal;
  }
  .FormFullList__addChoiceLabel {
    margin-right: $margin-base-horizontal;
    color: $brand-primary;
  }
  .FormFullList__addChoiceSelect {
    flex-grow: 1;
  }
}
