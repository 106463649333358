.DownloadPicturesModal__derivativeLabel {
  padding: 0.5rem 0.5rem 0.5rem 0;

  & > :first-child {
    flex: 1;
  }
}

.DownloadPicturesModal__derivatives {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.5rem;
}

.DownloadPicturesModal__progress {
  text-align: left;

  .ProgressBar {
    width: 200px;
  }
}
