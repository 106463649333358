@import '../../styles/colors';

.NotificationUnread {
  position: relative;
}

.NotificationUnread__indicator {
  display: block;
  cursor: pointer;
}

.NotificationUnread__bell {
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  margin-right: 5px;
  margin-left: 3px;
}

.NotificationUnread__bell--animate {
  animation: shake 2s ease-in-out both;
}

@keyframes shake {
  15% {
    transform: rotate(20deg);
  }
  35% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(7deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
