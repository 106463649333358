@import 'src/styles/vars';

.SuggestionsAutocomplete__suggestion {
  display: inline-flex;
  align-items: center;
}

.SuggestionsAutocomplete__suggestionIcon {
  font-size: large;
  margin-right: 5px;
  color: $brand-secondary;
}

.SuggestionsAutocomplete__suggestionButton {
  margin-left: $half-margin-base-horizontal;
  padding-top: $padding-base-vertical;
  padding-bottom: $padding-base-vertical;
}

.SuggestionsAutocomplete__examplesLink {
  font-style: italic;
  color: $gray-medium;
}

a[data-elevio-article],
.SuggestionsAutocomplete__topEntity__button {
  color: $brand-primary !important;
  cursor: pointer;
  border-bottom: none;

  &:hover {
    color: $brand-secondary !important;
    text-decoration: underline !important;
  }
}

.SuggestionsAutocomplete__topEntity__suggestion {
  padding: 0.5em;
  border: 1px solid $gray-light;
  border-radius: 0 0 4px 4px;
  margin-top: -7px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.SuggestionsAutocomplete__topEntity__suggestion__title {
  color: $gray-medium;
}

.SuggestionsAutocomplete__separator:last-of-type {
  display: none;
}

.SuggestionsAutocomplete__topEntity__suggestion__tooltip {
  padding-right: 0.4rem;
}
.SuggestionsAutocomplete__separator {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
