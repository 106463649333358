@import 'src/styles/vars';

.PriceWaterfallHeader {
  display: flex;
  margin-top: $half-margin-base;
  align-items: center;
  justify-content: flex-end;
}

.PriceWaterfallHeader__addBracket {
  height: 40px;
  .AddButton {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: 100%;
  }
  .Button_content {
    height: 100%;
  }
}

.PriceWaterfallHeader__delete {
  height: 40px;
  margin-left: $half-margin-base-horizontal;
  .Button {
    height: 100%;
  }
}
