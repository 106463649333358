@import 'src/styles/vars';

.TagsInput__tag {
  position: relative;
  display: inline-block;
  color: $brand-secondary;
  border: 1px solid $brand-secondary;
  border-radius: $border-radius-base;
  padding: $padding-xs-horizontal;
  margin-right: $padding-small-horizontal;
  margin-bottom: $padding-xs-horizontal;
  max-width: 100%;
}

.TagsInput__tag.TagsInput__tag--readonly {
  color: $readonly-fg;
  border: 1px solid $readonly-bg-darker;
  background-color: $readonly-bg-darker;
}

.TagsInput__label {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.TagsInput__delete {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  color: $brand-secondary;
  background: $brand-white;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;
}
