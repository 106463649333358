@import 'src/styles/colors';

.ListAdvancedFilterSearch {
  display: flex;
  align-items: center;
  border: 1px solid $gray-medium;
  margin-bottom: 0.5rem;
}

.ListAdvancedFilterSearch__searchIcon {
  padding-left: 0.4rem;
}

.ListAdvancedFilterSearch__searchInput {
  flex: 1;
  padding: 0.4rem;
  border: none;
  min-width: 0;
}

.ListAdvancedFilterSearch__clearIcon {
  padding-right: 0.4rem;
  cursor: pointer;
}
