@import 'src/styles/vars';

.GenericTable {
  width: 100%;
}

.GenericTable__header {
  width: 100%;
  color: $gray-light;
  border-bottom: 1px solid $gray-lighter;
  th {
    font-weight: 300;
  }
}

.GenericTable__body {
  width: 100%;
}

.GenericTable__row {
  width: 100%;
  padding-top: $padding-large-vertical;
  border-bottom: 1px solid $gray-lighter;
}

.GenericTable__cell {
  margin-top: $padding-base-vertical;
  margin-bottom: $padding-base-vertical;
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;
}
