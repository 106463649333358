@import 'src/styles/vars';

.Helper {
  height: 60px;
  border-bottom-left-radius: $border-radius-very-large;
  border-bottom-right-radius: $border-radius-very-large;
  background-color: rgba($brand-success, 0.3);

  margin-top: $half-margin-base;
  padding: $padding-small-vertical $padding-small-horizontal;
  display: flex;
  align-items: center;

  &.Warning {
    background: $brand-highlight-secondary-disabled;
  }
}

.Helper__left {
  display: flex;
  align-items: center;
}

.Helper__right {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
