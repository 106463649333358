@import 'src/styles/colors';

.CatalogAssignationDateCell {
  width: 1px;
}

.CatalogAssignationDateColumn {
  display: flex;

  &--bold {
    font-weight: 400;
  }

  &--extraBold {
    font-weight: 500;
  }

  &--rejected {
    color: $brand-danger;
  }

  &--small {
    font-size: 0.9em;
  }

  &--archived {
    display: flex;
    align-items: center;
  }

  &--helpIcon {
    font-size: 1em;
    margin-left: 0.4em;
    color: $brand-primary;
  }

  .CatalogAssignationDateColumn__left {
    flex: 1;
  }

  .CatalogAssignationDateColumn__right {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
  }
  .CatalogAssignationDateColumn__open {
    color: $brand-primary;
  }
}
