.MaturityRuleSetActivationModal__ruleSetItem {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1em 0;
  gap: 0.3em;

  &--checked {
    .MaturityRuleSetActivationModal__ruleSetItemLabel {
      font-weight: 400;
    }
    .ViewAs__ruleSetIcon--notRequested {
      opacity: 1;
    }
  }
}

.MaturityRuleSetActivationModal__ruleSetItemDetails {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.MaturityRuleSetActivationModal__ruleSetItemLabel {
  font-weight: 300;
}
