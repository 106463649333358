@import 'src/styles/vars';

.UserLabel__label {
  display: inline-flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  background-color: $brand-secondary-hover;
  border-radius: 4px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;

  i {
    margin-left: 5px;
    color: $brand-secondary;
    cursor: pointer;
  }

  &--new {
    background-color: $brand-primary-active;
  }
}
