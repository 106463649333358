@import 'src/styles/vars';

.OneLineHierarchy {
  display: flex;
  align-items: center;

  .ProductReference {
    margin-left: $half-margin-base-horizontal;
    margin-right: $margin-base-horizontal;
  }

  &__reference {
    font-size: 1rem;
    font-weight: normal;
  }
}

.OneLineHierarchy__separator {
  margin-top: -2px;
  margin-left: 5px;
  margin-right: 10px;
  width: 30px;
  height: 2px;
  background-color: $gray-dark;
  position: relative;

  &::after {
    display: block;
    content: '';
    top: -4px;
    right: -5px;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $gray-dark;
  }
}

.OneLineHierarchy__more {
  font-size: xx-large;
}
