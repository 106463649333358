@import 'src/styles/vars';

.ButtonBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ButtonBar__item {
  position: relative;
  margin-bottom: 8px;

  & + .ButtonBar__item {
    margin-left: 8px;
  }
}
.ButtonBar__button {
  &.selected {
    background: $brand-secondary;
    color: white;
  }
}
.ButtonBar__remove {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: $brand-secondary;
  background: white;
  font-size: 12px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border: 1px solid $brand-secondary;
  border-radius: 8px;
  cursor: pointer;
  transform: translate(25%, -25%);
  &:hover {
    color: white;
    background: $brand-secondary;
    border-color: $brand-secondary;
  }
  &.selected {
    &:hover {
      border-color: white;
    }
  }
}
