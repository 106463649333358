@import 'src/styles/vars';

.Search {
  position: relative;
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;

  &.Search--transparent {
    max-width: none;

    .Search__input {
      background: inherit;
      border-color: transparent transparent $brand-primary transparent;
      border-radius: 0;

      &:focus {
        outline: none;
        border: 1px solid $brand-secondary;
        background: #fff;
        border-radius: 4px;
      }
    }

    &.Search--inProgress {
      .Search__input {
        border: 1px solid $brand-secondary;
        background: #fff;
        border-radius: 4px;
      }
    }
  }
}

.Search__input {
  position: relative;
  z-index: 1;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-base;
  white-space: nowrap;
  padding: 0.75rem 2.4rem 0.75rem 2.6rem;
  width: 100%;
  font-size: 100%;
}

.Search__input--small {
  height: 2.4rem;
}

.Search__icon {
  position: absolute;
  z-index: 2;
  font-size: 2rem;
  line-height: 2rem;
  top: 0.6rem;
  left: 0.4rem;
  transform: scaleX(-1);
}

.Search__icon--small {
  top: 0.4rem;
  left: 0.4rem;
}

.ClearSearch__icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  font-size: 2rem;
  line-height: 2rem;
  top: 0.6rem;
  right: 0.4rem;
  transform: scaleX(-1);
}

.ClearSearch__icon--small {
  top: 0.4rem;
  right: 0.4rem;
}

.Search__errorToast {
  position: relative;
  margin-top: -2px;
  z-index: 2;
  color: $brand-white;
  background-color: $brand-danger;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
