@import 'src/styles/colors';

.FractionCell {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: $gray-medium;

  &.FractionCell--clickable:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.FractionCell__quality {
  &.FractionCell__quality--good {
    // to change success
  }

  &.FractionCell__quality--medium {
    font-weight: 500;
    color: $brand-highlight-secondary;
  }

  &.FractionCell__quality--low {
    font-weight: 500;
    color: $brand-danger;
  }
}

.FractionCell__den {
  font-size: 0.8rem;
  & > span {
    padding-left: 0.2rem;
  }
}
