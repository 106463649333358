@import 'src/styles/colors';

.ActionOption {
  padding: 0.5rem;

  &:hover {
    background-color: $alk-background-hover;
    cursor: pointer;
  }

  &.ActionOption--link {
    padding: 0;

    a,
    a:active,
    a:visited,
    a:hover {
      display: inline-block;
      width: 100%;
      padding: 0.5rem;
      color: inherit;
      text-decoration: none;
    }
  }
}
