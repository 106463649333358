@import '@alkem/react-styles/src/vendor/bootstrap/mixins';
@import '@alkem/react-styles/src/vendor/bootstrap/mixins/buttons';
@import 'vars';

@mixin transparent-btn($color, $disabled-color) {
  @include button-variant($color, rgba(255, 255, 255, 0), $color);

  i,
  .mdi {
    color: $color;
    vertical-align: middle;
  }

  &:hover {
    background-color: $color;
    border-color: transparent;
    color: white;

    i,
    .mdi {
      color: white;
    }
  }

  &:focus {
    outline: 0;
    background-color: $color;
    border-color: transparent;
    color: white;

    i,
    .mdi {
      color: white;
    }
  }

  &.plain {
    background-color: $color;
    border-color: transparent;
    color: white;

    i,
    .mdi {
      color: white;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: default;
    color: $disabled-color;
    border-color: $disabled-color;

    i,
    .mdi {
      color: $disabled-color;
    }

    &:hover {
      color: $disabled-color;
      border-color: $disabled-color;

      i,
      .mdi {
        color: $disabled-color;
      }
    }
  }
}

html {
  .btn {
    font-weight: 300;

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn-primary {
    &:hover {
      background-color: $brand-primary-btn-hover;
    }
  }

  .btn-secondary {
    @include transparent-btn($brand-secondary, $brand-secondary-disabled);
  }

  .btn-success {
    @include transparent-btn($brand-success, $brand-success-disabled);
  }

  .btn-danger {
    @include transparent-btn($brand-danger, $brand-danger-disabled);
  }

  .btn-warning {
    @include transparent-btn($brand-warning, $brand-warning-disabled);
  }

  .btn-transparent {
    @include transparent-btn(
      $brand-primary-btn-hover,
      $brand-secondary-disabled
    );
    border-color: transparent;
  }

  .btn-default {
    @include transparent-btn($brand-default, $brand-default-disabled);
  }

  .btn-highlight {
    @include transparent-btn($brand-highlight, $brand-highlight-disabled);
  }

  .btn-highlight-secondary {
    @include transparent-btn(
      $brand-highlight-secondary,
      $brand-highlight-secondary-disabled
    );
  }

  .btn-inprogress {
    @include transparent-btn($brand-inprogress, $brand-inprogress-disabled);
  }
}

span.mdi.Pagination__chevron {
  font-size: 1.5rem !important;
  line-height: 1.5rem;
}
