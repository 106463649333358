@import 'src/styles/vars';

.HppModal {
  height: 100%;

  .Modal__body {
    background: inherit;
  }
}

.HppModal__iframe {
  border: 0;
}
