@import 'src/styles/vars';

.LogisticalHierarchies {
  padding: 20px;

  > .AnchoredBlock:first-child {
    border: 0;
  }
}

.ProductPage__content
  .LogisticalHierarchies__new
  .AnchoredBlock:not(:first-of-type) {
  border-top: 0;
}

.LogisticalHierarchies__new {
  background-color: $gray-really-light;

  > .AnchoredBlock + .AnchoredBlock {
    margin-top: $half-margin-base;
  }

  .BigDottedButton {
    background-color: $gray-really-light;
  }
}

.LogisticalHierarchies__addRoot {
  display: flex;
  border: 1px dashed $brand-primary;
  margin-top: $half-margin-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  align-items: center;
  > i {
    color: $brand-primary;
    font-size: x-large;
    padding-right: $padding-base-horizontal;
  }
  .LogisticalHierarchies__addRootLabel {
    margin-right: $margin-base-horizontal;
    color: $brand-primary;
  }
}

.LogisticalHierarchies__hierarchyTitle {
  display: flex;
  margin: 1.5rem 0;
  color: $brand-black;
  font-weight: 400;
  font-size: 1.2rem;
}
