@import 'src/styles/vars';

.LogisticalHierarchyForm {
  margin-top: $half-margin-base;

  &--new {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .form-group {
    border: none;
    + .form-group {
      border-top: 1px solid $gray-light;
    }
  }

  .LogisticalHierarchyIcon {
    margin-right: 1em;
  }

  &__header {
    z-index: 100;
    background-color: $brand-white;
    font-weight: 400;
    &.sticky {
      margin-left: -$padding-base-horizontal;
      padding-left: $padding-base-horizontal;
      padding-right: $padding-base-horizontal;
      padding-top: $padding-base-vertical;
    }
    .content {
      display: flex;
      align-items: center;
    }
    .separator {
      height: 1px;
      width: 100%;
      border-bottom: 1px solid $gray-light;
      padding-bottom: $padding-base-vertical;
    }
  }
}

.LogisticalHierarchyForm__content {
  border-radius: $border-radius-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  flex-grow: 1;

  &--EACH {
    border: 1px solid $brand-highlight;
    .LogisticalHierarchyForm__header.sticky {
      border-top: 1px solid $brand-highlight;
    }
  }

  &--PALLET {
    border: 1px solid $brand-success;
    .LogisticalHierarchyForm__header.sticky {
      border-top: 1px solid $brand-success;
    }
  }

  &--CASE {
    border: 1px solid $brand-primary;
    .LogisticalHierarchyForm__header.sticky {
      border-top: 1px solid $brand-primary;
    }
  }

  &--PACK {
    border: 1px solid $brand-highlight-secondary;
    .LogisticalHierarchyForm__header.sticky {
      border-top: 1px solid $brand-highlight-secondary;
    }
  }
}
