.DietTypeItem {
  &_title {
    font-weight: bolder;
  }
  &_options {
    display: flex;
    justify-content: space-around;
    padding-top: 0.5rem;
  }
}
