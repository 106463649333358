@import 'src/styles/vars';

.ConnectAs {
  display: flex;
  position: relative;
  margin-right: 5px;
  margin-left: 3px;
  font-weight: 400;
  align-items: center;
  color: white;

  &.ConnectAs--notLogged {
    cursor: pointer;
  }

  .Select__inputbox {
    border: none;
  }
}

.ConnectAs__loggedAs {
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  font-size: 0.8rem;
  padding-right: 0.1rem;
  user-select: none;
}

.ConnectAs__loggedAsTitle {
  font-weight: 100;
}

.ConnectAs__label,
.ConnectAs__loggedAsTitle,
.ConnectAs__loggedAsName {
  white-space: nowrap;
  text-align: right;
}

.ConnectAs__moreInfo {
  @include ul();
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    & > span:first-child {
      padding-right: 1em;
    }
  }
}

.ConnectAs__icon {
  height: $navbar-size;
  font-size: 1.5rem;
  padding-left: 2px;
  cursor: pointer;
}

.ConnectAs__dropdown {
  border-top: 1px solid $brand-secondary;
  position: absolute;
  width: 300px;
  top: 100%;
  right: 0;
  background: $gray-darker;
  padding: 0.4rem 1rem;
  list-style: none;
  margin: 0;
  cursor: default;
}

@media (min-width: 1301px) {
  .ConnectAs__dropdown {
    top: calc(100% - 1px);
  }
}

.ConnectAs__dropdownRow {
  padding-bottom: 0.5rem;

  &.ConnectAs__dropdownRow--connect {
    text-align: right;
  }

  & > input {
    color: black;
    width: 100%;
    padding: 0 12px;
  }

  & > .ConnectAs__label {
    line-height: 26px;
    text-align: left;
  }

  & > .ConnectAs__input {
    background-color: gainsboro;
    border: none;
    outline: none;
    height: 40px;
  }
}

.ConnectAs__targetUser {
  background: white;
  color: black;
}

.ConnectAs__username,
.ConnectAs__logasLink {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .mdi {
    margin-left: $padding-xs-horizontal;
  }
}

.Navbar--PPR .ConnectAs__dropdown {
  background: #333;
  border-top: 1px solid #ccc;
}
