@import 'src/styles/vars';

.AssetList__container {
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;

  &.card.card-block {
    padding: 20px;
    padding-bottom: 0;
  }
}
.AssetList__card {
  background-color: white;
  border: 1px solid #ccc;
  position: relative;
  height: 100%;
}

.AssetList__list {
  > div {
    margin-bottom: 20px;
    min-width: 32%;
  }
}

.AssetList__list-rfp {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.AssetList__child {
  .Button + .Button {
    margin-left: $margin-base-horizontal;
  }
}
.AssetList__actionsContainer {
  display: flex;
  align-items: stretch;
  padding: 0 0;
  border: 1px dashed $brand-secondary;
  height: 100%;
}
.AssetList__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $margin-base;

  > div {
    margin: 15px 0;
  }

  > div,
  > div > .Button {
    width: 100%;
    white-space: normal;
  }
}
.AssetList__col {
  .ReactUiCheckbox {
    margin: 10px 0 0 10px;
  }

  .Asset__packshot {
    .ReactUiCheckbox {
      visibility: hidden;
    }
  }
}

.AssetList__archivedButton {
  margin: auto;
}
