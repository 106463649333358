@import 'src/styles/vars';

.specific-values {
  margin-bottom: $margin-base;
  border-bottom: 1px solid $gray-soft-light;
  & > .MediaBlock:nth-child(2) {
    border-top: 0 !important;
  }
}

.SpecificValues__DeletePlugin {
  position: absolute;
  right: -30px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.3rem;

  &.delete-media {
    right: -12px;
  }
}

.SpecificValues__DeleteButton {
  font-size: 1.2rem;
  color: $gray-light;
  border: 0;
  background: transparent;

  :hover {
    color: $gray-dark;
  }
}

div.MediaBlock:first-of-type {
  border-top: 0 !important;
}
