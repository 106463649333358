@import 'src/styles/vars';

$dropdown-height: 35px;

.Dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: 0;
  color: $brand-secondary;
  line-height: 1em;
  // THEME
  &.Dropdown--white {
    color: white;
    .Dropdown__button,
    .Dropdown__button.btn {
      &:after,
      &:before {
        border-color: white;
      }
    }
  }
  &.Dropdown--danger {
    color: $brand-danger;
    .Dropdown__button,
    .Dropdown__button.btn {
      border-color: $brand-danger;
      background: transparent;
      &::after {
        border-color: $brand-danger;
      }
      &:focus,
      &:hover {
        background: $brand-danger;
        &::after {
          border-color: $brand-white;
        }
      }
    }
    .Dropdown__list {
      color: $brand-danger;
    }
  }
  &.Dropdown--circle {
    .Dropdown__button,
    .Dropdown__button.btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: 1rem;
      padding: 0;
      width: 2rem;
      height: 2rem;
      overflow: hidden;
      color: $gray-dark;
      background: $gray-really-light;
      &::after {
        display: none;
      }
      &:hover,
      &:focus {
        color: $gray-dark;
        background: $gray-lighterer;
      }
      &.Dropdown__button--darker {
        color: $gray-dark;
        background: $gray-lighterer;
        &:hover,
        &:focus {
          background: $gray-slightly-lighter;
        }
      }
    }
  }
}
.Dropdown__button,
.Dropdown__button.btn {
  position: relative;
  background: 0;
  margin: 0;
  border: 1px solid $brand-secondary;
  border-radius: $border-radius-base;
  outline: 0;
  padding: 0.5rem 1rem;
  &::after {
    display: inline-block;
    position: relative;
    top: -2px;
    content: '';
    width: 8px;
    height: 8px;
    border-right: 1px solid $brand-secondary;
    border-bottom: 1px solid $brand-secondary;
    transform: rotate(45deg);
    margin-left: 15px;
  }
  &:hover:not(:disabled) {
    background: $brand-secondary;
    color: white;

    &::after {
      border-color: white;
    }
  }
  &:focus {
    background: $brand-secondary;
    color: white;

    &::after {
      border-color: white;
    }
  }
}
.Dropdown__list {
  position: absolute;
  z-index: 50;
  background: #fff;
  left: 0;
  min-width: 100%;
  padding: 12px 12px 12px 0;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-base;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  color: $brand-secondary;
  max-height: 32rem;
  overflow: auto;

  &.rightDropdown {
    left: inherit;
    right: 0;
  }

  li {
    padding: 0px 0 0px 12px;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    & > button,
    & > span {
      padding: 6px inherit;
      display: block;
      width: 100%;
      background: 0;
      margin: 0;
      border: 0;
      text-align: left;
    }
    button {
      padding: 6px 12px;
      outline: 0;
      &:hover {
        background: $brand-secondary-hover;
      }

      &:disabled {
        color: $text-disabled-color;

        &:hover {
          background: none;
        }
      }
    }
    ul {
      padding: 0;
      margin: 0;
    }
  }
}
.Dropdown__list--hidden {
  display: none;
}
