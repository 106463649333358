@import 'src/styles/vars';

.Collapsible {
  background: $brand-white;
  margin-bottom: $half-margin-base;

  &--noMargins {
    margin-bottom: 0;
    .Collapsible__header {
      padding: 0 $padding-large-horizontal 0 0;
    }
  }
}

.Collapsible__collapse-button {
  margin-left: 10px;
  padding-top: 2px;
}

.Collapsible__collapse-button_big {
  font-size: 1.5rem;
  min-width: 20px;
}

.Collapsible__header {
  padding: $margin-base-horizontal;
  border-bottom: 1px solid $gray-lighter;
  background-color: $brand-white;
  cursor: pointer;

  h1,
  h2,
  h3 {
    margin-bottom: 0;
    width: 100%;
  }
}

.Collapsible__content {
  overflow: visible;
  background: $brand-white;
}

.Collapsible__content-collapsed {
  display: none;
}

.Collapsible__header--disabled {
  background-color: $gray-really-light;
  cursor: auto;
}

.Collapsible__header--collapsed {
  border-bottom: 0;
}

.Collapsible__header__icon {
  margin-right: 0.5em;
  color: $brand-success;
  font-size: 1.5em;
}
