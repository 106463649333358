@import 'src/styles/vars';

.CatalogSourcingActionColumn {
  vertical-align: top !important;
  font-size: 0;
}

.CatalogSourcingActionColumn__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  right: 1rem;
  background-color: $brand-white;
  padding: 0 0.5rem;
  font-size: 1rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid $gray-lighterer;
  margin-top: -1px;

  i {
    font-size: 2rem;
  }

  .CatalogSourcingActionsButton {
    border: 0 transparent;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: $brand-secondary;

    &:hover {
      color: $brand-primary;
    }
  }
}

.ListTableRow:first-child {
  .CatalogSourcingActionColumn__content {
    border-top: 1px solid $gray-lighterer;
  }
}

.ListTableRow:hover,
.ListTableRow--selected {
  .CatalogSourcingActionColumn__content {
    background-color: $alk-background-hover !important;
  }
}
