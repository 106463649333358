@import 'src/styles/vars';

.ProductsSummary__list {
  position: relative;
  columns: 2;
  text-align: center;
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    content: '';
    width: 1px;
    background: $gray-light;
  }
}

.ProductsSummary__item {
  line-height: 1rem;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  .Ellitips {
    flex-grow: 1;
    text-align: left;
  }
  .ProductReference {
    margin: 0 0 0 $half-margin-base-horizontal;
  }
}

.ProductsSummary__separator {
  margin-left: $half-margin-base-horizontal;
  margin-right: $half-margin-base-horizontal;
  font-weight: 500;
}
