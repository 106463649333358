.FeatureModal__container {
  height: 100%;
}

.FeatureModal__leftPanel {
  float: left;
  width: 50%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FeatureModal__rightPanel {
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.FeatureModal__preview {
  img {
    max-height: 100%;
    width: 100%;
  }
}

.FeatureModal__text {
  padding: 0 0 1rem 0;
  margin: 0;

  &.FeatureModal__text--strong {
    padding-top: 2rem;
    font-weight: 400;
  }
}

.FeatureModal__requested {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
