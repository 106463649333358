@import 'src/styles/vars';

.ProductHeader__header {
  display: flex;
  align-items: center;
  min-height: $product-header-height;

  .ProductHeader__basic {
    margin-left: $half-margin-base;
    flex-grow: 2;
  }
  .ProductHeader__picture {
    border: solid 1px $gray-slightly-lighter;
    min-width: 128px;
  }
}
