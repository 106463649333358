@import 'src/styles/vars';

.ListAdvancedFilter__items {
  @include ul();

  &.ListAdvancedFilter__items--children {
    padding-left: 1em;
  }
}

.ListAdvancedFilter__sort {
  margin-bottom: 0.5em;
  i {
    margin-right: 0.2em;
  }
}
