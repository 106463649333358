@import 'src/styles/vars';

.HierarchyGrid__label {
  text-align: right;
  margin-top: 11px;
}

.HierarchyGrid__label--top {
  text-align: right;
  margin-top: $half-margin-base + 22px;
}

.HierarchyGrid {
  width: 100%;
  margin-top: $half-margin-base;
  td {
    text-align: center;
    width: 25%;
  }
}

.HierarchyGrid__input {
  width: 100%;
  border: 1px solid $gray-slightly-lighter;

  &.HierarchyGrid__input--readOnly {
    background-color: #f5f5f5;
  }
}

.HierarchyGrid__error {
  border: 3px solid $brand-danger;
}

.hierarchyTable {
  display: flex;
}
