.GroupPublicationSummaryHeader__Content {
  justify-content: space-between;
  width: 100%;
}

.GroupPublicationSummaryHeader__switchStatus {
  padding-right: 0.5em;

  & > .mdi-loading {
    padding-left: 0.5em;
  }
}
