@import 'src/styles/vars';

.MultiLevelField {
  &:not(:last-child) {
    margin-bottom: $margin-base;
  }
}

.MultiLevelField__addButton {
  margin-top: $padding-large-vertical;
  margin-bottom: $padding-base-vertical;
  width: 100%;
}

/* Don't add an extra border if only one child */
.MultiLevelField__list--singleChild
  > .MultiLevelField__item
  > .MultiLevelField__itemContent {
  > .DisplayGroup--condensed {
    margin-bottom: 0;
  }
}

.MultiLevelField__itemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  padding: 1rem;
  padding-right: 0.5rem;
  padding-bottom: 0;
  margin-top: 10px;
  > * {
    transition: none !important;
  }
}

.MultiLevelField__deleteButton {
  margin-left: $margin-base-horizontal;
  color: $gray-light;
  cursor: pointer;
  font-size: $font-size-xlarge;
  opacity: 1;
  width: $font-size-xlarge;
  transition:
    opacity 0.5s,
    margin-left 0.5s,
    width 0.5s;
}

.MultiLevelField__deleteButton--hidden {
  opacity: 0;
  margin-left: 0;
  width: 0;
  transition:
    opacity 0.5s,
    margin-left 0.5s,
    width 0.5s;
}

.MultiLevelField__deleteButton:hover {
  color: $gray-dark;
}
