@import 'src/styles/vars';

.ErrorsStepper {
  display: inline-flex;
  align-items: center;
  width: 90vw;
  justify-content: space-between;
}

.ErrorsStepper__details {
  margin-right: $half-margin-base-horizontal;
  max-width: 80vw;
}

.ErrorsStepper__message {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.ErrorsStepper__button {
  font-size: x-large;
  padding-left: 3px;
  padding-right: 3px;
}

.ErrorsStepper__button--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.ErrorsStepper__button--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ErrorsStepper__error {
  background-color: $brand-danger-hover;
  border: 1px solid $brand-danger !important;
}
