@import 'src/styles/colors';

.SimpleQualityCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray-medium;

  &.SimpleQualityCell--clickable:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.SimpleQualityCell__percentage,
.SimpleQualityCell__count {
  text-align: center;
}

.SimpleQualityCell__percentage {
  font-weight: 500;

  &.SimpleQualityCell__percentage--good {
    color: $brand-success;
  }

  &.SimpleQualityCell__percentage--medium {
    color: $brand-highlight-secondary;
  }

  &.SimpleQualityCell__percentage--low {
    color: $brand-danger;
  }

  &.SimpleQualityCell__percentage--neutral {
    color: $brand-info;
  }
}

.SimpleQualityCell__count {
  font-size: 0.8rem;
}

.SimpleQualityCell__chart {
  height: 1.1rem;
  width: 7rem;
  margin: 0 0.5rem;
  overflow: hidden;
  border-radius: 0.25rem;
  background-color: $gray-slightly-lighter;
}

.SimpleBarChart__chartValue {
  height: 100%;
  max-width: 100%;
  border-radius: 0.25rem;

  &.SimpleBarChart__chartValue--good {
    background-color: $brand-success;
  }

  &.SimpleBarChart__chartValue--medium {
    background-color: $brand-highlight-secondary;
  }

  &.SimpleBarChart__chartValue--low {
    background-color: $brand-danger;
  }

  &.SimpleBarChart__chartValue--neutral {
    background-color: $brand-info;
  }
}
