@use 'sass:math';

.form-control {
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background: 0 0;
  border-bottom: 1px solid $gray-light;
  height: 40px;
  outline: 0;
}

textarea.form-control {
  padding: 0.5rem 0.75rem;
  border: 1px solid $gray-light;
}

.container-fluid {
  padding-left: $container-fluid-margin;
  padding-right: $container-fluid-margin;
}

@if $enable-flex {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      @for $size from 1 through $grid-columns {
        .col-#{$breakpoint}-#{$size} {
          max-width: percentage(math.div($size, $grid-columns));
        }
      }
    }
  }
}
