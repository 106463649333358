@use 'sass:math';

@import 'src/styles/vars';

.Asset__image {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 200px;
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: $margin-base;
  img {
    max-height: 180px;
    max-width: 100%;
  }
}

.Asset__image-rfp {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 200px;
  border: 1px solid $gray-lighter;

  img {
    max-height: 180px;
    max-width: 100%;
  }
}

.Asset__image--modal-view {
  display: flex;
  height: 96px;
  border: 1px solid $gray-lighter;
}

.Asset__video__thumbnail {
  height: 180px;
  max-width: 100%;
  margin-bottom: 30px;
}

.Asset__document-wrapper {
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
  gap: 34px;
}

.Asset__documentContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 200px;
  border-bottom: 1px solid $gray-lighter;
  text-decoration: none !important;
  margin-top: 1rem;
  margin-bottom: $margin-base;
}

.Asset__document {
  font-size: 5rem;
  text-decoration: none;
  color: $gray-medium;
  max-height: 250px;
  max-width: 100%;
}

.Asset__image--archived {
  filter: grayscale(100%);
  opacity: 0.6;
}
.Asset__imageBody,
.Asset__recipeBody,
.Asset__videoBody,
.Asset__documentBody {
  margin-left: $half-margin-base;
  margin-right: $half-margin-base;
  overflow: hidden;
}

.Asset__documentBody-rpf {
  display: flex;
  flex-direction: row;
  margin-left: $half-margin-base;
  margin-right: $half-margin-base;
  overflow: hidden;
}

.Asset__main-info {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.Asset__secondary-info {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.Asset__recipeIcon {
  background: $brand-highlight;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 16px;
  > span {
    border: 1px solid #fff;
    border-radius: 50%;
    padding: math.div($margin-base, 2);
  }
}

.Asset__recipeIcon__upgradeRequired {
  // Color is the same as brand highlight
  background-color: rgba(250, 191, 45, 0.2);
}

.Asset__noRecipe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 200px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $brand-highlight;
  font-weight: bold;
  > .mdi {
    padding: 3px;
    font-size: 17px;
  }
}

.Asset__content {
  max-height: 100px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 50%
    );
  }
}

.Asset__tight {
  margin-bottom: 0;
}

.Asset__tags {
  margin-bottom: 0;
  font-style: italic;
  color: $brand-secondary;
}

.Asset__tight--fileSize,
.Asset__tight--definition,
.Asset__tight--transparency,
.Asset__dateAdd {
  color: $gray-medium;
  font-size: 0.9em;
}

.Asset__tight--Error {
  color: $brand-highlight-secondary;
  font-weight: 400;

  > i {
    margin-right: 2px;
    color: $brand-highlight;
  }
}

.Asset__btn--inactive {
  background-color: $gray-medium;
  border: none;
}

.Asset__Video {
  width: auto;
  height: 100%;
  margin-top: $padding-large-vertical;

  object {
    min-height: 200px;
  }
}

.Asset__VideoIframe {
  border: none;
  width: 100%;
  height: 200px;
}

.Asset__noVideo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: $gray-slightly-lighter;
  cursor: pointer;

  & > i.mdi {
    padding: 3px;
    font-size: 17px;
  }
}

.Asset__buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.set-as-packshot-button .Button {
  max-width: 100%;
  white-space: normal;
}

.Asset__button--rightMargin {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.Asset__archivedTag {
  color: $gray;
  border: 1px solid $gray;
  border-radius: 14px;
  padding: 1px 5px;
  text-align: center;
  width: 100px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  cursor: default;
}

.Asset__download {
  margin-bottom: 10px;
}

.AssetList__media-packshot-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  &__title {
    display: flex;
  }
}

.AssetList__card.AssetList__col {
  &.AssetList__card--ProductVideo {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 350px;
  }

  &.AssetList__card-rpf {
    display: none;
    border: none;
  }

  &.AssetList__card--displayed-rpf {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    & > .Asset {
      flex: 1;
      padding: 10px;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      & > .Asset__main-info {
        width: 180px;
      }

      & > .Asset__secondary-info {
        width: 240px;
      }
    }
  }

  &.AssetList__card--raguelError {
    position: relative;
    border-color: $brand-danger;
    background-color: $brand-danger-hover;

    & > .mdi-alert {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      color: $brand-danger;
    }

    &.AssetList__card--notRequested {
      border-color: $brand-highlight-secondary;
      background-color: rgba($brand-highlight-secondary, 0.2);

      & > .mdi-alert {
        color: $brand-highlight-secondary;
      }
    }
  }
}
