.ProductErrorPage {
  height: 100%;
  .ProductErrorPage__content {
    display: flex;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 2rem;
    & > div {
      margin: 1.2rem;
    }
    .ProductErrorPage__message {
      .message-title {
        font-weight: bold;
      }
    }
    .ProductErrorPage__image {
      width: 50%;
    }
  }
}
