@use 'sass:math';

@import 'src/styles/vars';

.InputLabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  min-height: $field-height-base;
  > label {
    margin-bottom: 0;
  }
}

.InputLabel--left {
  justify-content: flex-start;
}

.InputLabel--multiLevel {
  justify-content: flex-start;
  text-align: left;
  min-height: 0;
  margin-top: $half-margin-base;
  label {
    font-size: 1.2em;
    font-weight: 400;
  }
}

.InputLabel__help {
  display: flex;
  align-items: center;
  margin-left: math.div($margin-base-horizontal, 3);
}

.InputLabel__mandatory {
  color: $alk-red;
  font-size: 1.2em;
  font-weight: 400;
}
