@import 'src/styles/vars';

.PublicationSummaryNetworkVisibility__action {
  & > span {
    padding-right: 0.5rem;
  }
}

.PublicationSummaryNetworkVisibility__description {
  padding: 0 1rem;
  font-style: italic;
}
