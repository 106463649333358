.BulkMessage__titleMessage {
  font-size: 1.1rem;
}

.BulkMessage__body {
  min-width: 50vw;
}

.BulkMessage__textArea {
  padding-bottom: 2rem;
}
