@use 'sass:math';

@import 'src/styles/vars';

.PublishedBadge {
  padding: math.div($padding-small-vertical, 2)
    math.div($padding-small-horizontal, 2);
  margin-right: math.div($margin-base, 2);

  color: $brand-success;
  border: 1px solid $brand-success;
  border-radius: $border-radius-base;

  &--draft {
    color: $gray-medium;
    border-color: $gray-medium;
  }
}
