@use 'sass:math';

@import 'src/styles/vars';

.PhysicalCheckDiff_FailedField__button {
  display: flex;
  margin: 0;
  padding: 0.5em 0;

  button {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.PhysicalCheckDiff_ButtonBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.5rem;

  .Button {
    margin-left: 0.5rem;
  }

  .PhysicalCheckDiff_ButtonBar--warning-message {
    font-style: italic;
  }
}

.PhysicalCheckDiff_FailedField {
  padding: 20px;
}

.PhysicalCheckDiff_FailedField__field {
  display: flex;
  align-items: center;

  .ProductHistoryDiffCard__row {
    border-top: none;
  }
}

.PhysicalCheckDiff_FailedField__field:nth-child(odd) {
  background: #f4f9fb;
}

.PhysicalCheckDiff_FailedField__header {
  font-weight: 500;
  padding: math.div($margin-base, 40);
}

// override button colors :
.PhysicalCheckDiff_FailedField__button .btn-secondary {
  border-color: $brand-primary;
  color: $brand-primary;

  &:hover {
    border-color: $brand-primary;
    background-color: rgba($brand-primary, 0.2);
    color: $brand-primary;
  }
}

.PhysicalCheckDiff_FailedField__button .btn-secondary.selected {
  box-shadow: inset 0 0 1px 0 $brand-secondary;
  border: 1px solid $brand-secondary;
  background-color: rgba($brand-primary, 0.2);
  color: $brand-secondary;
  z-index: 2;
  font-weight: 400;
}

.PhysicalCheckDiff_FailedField__diff--empty {
  min-height: 3.5em;
  display: flex;
  align-items: center;
  font-style: italic;
}

.PhysicalCheckDiff_FailedField__diff--NA--missing {
  font-style: italic;
  color: $brand-danger;
}
