@import 'src/styles/vars';

.GroupPublicationSummary {
  margin-bottom: $half-margin-base;
  border-radius: $border-radius-base;
  box-shadow: 0 1px 2px $gray-light;
}

.GroupPublicationSummaryHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding-base-vertical $padding-base-horizontal;
  background: $brand-white;
  border-radius: $border-radius-base $border-radius-base 0 0;
  border-bottom: 1px solid $gray-lighterer;

  &.GroupPublicationSummaryHeader--inactive {
    background: $gray-really-light;

    .GroupPublicationSummaryHeader__GroupLogo {
      filter: grayscale(100%);
    }
  }
}

$img-size: 60px;
.GroupPublicationSummaryHeader__GroupLogo {
  width: $img-size;
  height: $img-size;
  transition: 0.2s all;
}

.GroupPublicationSummaryHeader__GroupName {
  font-size: 1.5rem;
  line-height: $img-size;
  display: flex;
  align-items: center;
}
img + .GroupPublicationSummaryHeader__GroupName {
  padding-left: 0.5rem;
}

.GroupPublicationSummaryHeader__WaitingRecipientsButton {
  display: flex;
  align-items: center;
}

.GroupPublicationSummaryHeader__WaitingRecipients {
  margin-right: $half-margin-base;
}

.GroupPublicationSummaryMembers {
  background: $brand-white;

  &,
  & > :last-child {
    border-radius: 0 0 $border-radius-base $border-radius-base;
  }
}

.GroupPublicationSummaryMember {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $padding-base-vertical $padding-base-horizontal;

  &:nth-child(odd) {
    background-color: $table-striped-bg;
  }

  &.GroupPublicationSummaryMember--noColor:nth-child(odd) {
    background-color: transparent;
  }
}

.GroupPublicationSummary__AdditionalRecipientsButton.Button {
  width: 100%;
  padding: 0;
}

.GroupPublicationSummary__AdditionalRecipientsButtonContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $padding-base-vertical $padding-base-horizontal;
}

.GroupPublicationSummaryHeader__Switch {
  margin-right: 3em;
}
