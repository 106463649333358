@import 'src/styles/vars';

.ProductHeader__header {
  background: $brand-white;
  position: relative;
  min-height: $product-header-height;
}

.ProductHeader__content {
  padding: $half-margin-base $margin-base;
  align-items: center;
  min-height: $product-header-height;
}

.ProductHeader__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ProductHeader {
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin-bottom: 1.5rem;
  background: $brand-white;
}
