@import 'src/styles/vars';

.AssetListButtonsBar {
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.AssetListButtonsBar .helpTooltip {
  margin-left: 1rem;
}

.AssetListButtonsBar__button {
  margin-right: 10px;
  &.AssetListButtonsBar__button--red {
    .Button,
    .Button:focus,
    .Button:active {
      border-color: $brand-danger;
      background-color: $brand-white;
      color: $brand-danger;
      outline: none;
    }

    .Button:hover {
      border-color: $brand-danger;
      background-color: $brand-danger;
      color: $brand-white;
      outline: none;
    }
  }
}
