@import 'src/styles/colors';

.ListPaginationButtons {
  height: 38px;
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;

    > i.mdi {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      line-height: 2rem;
      color: $brand-secondary;
      padding: 0.25rem;
    }

    &:nth-child(odd) {
      padding-right: 0;
    }

    &:nth-child(even) {
      padding-left: 0;
    }

    &:hover:not([disabled]) {
      color: $brand-primary;
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }
}
