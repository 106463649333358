@import 'src/styles/vars';

.ProductError {
  line-height: 1rem;
  font-weight: 100;
  display: inline-flex;
  white-space: nowrap;
  min-width: 0;
  flex-grow: 1;
  justify-content: center;
}

.ProductError__field {
  font-weight: 400;
  flex-basis: 35%;
  text-align: right;
}

.ProductError__separator {
  margin-left: $half-margin-base-horizontal;
  margin-right: $half-margin-base-horizontal;
  font-weight: 500;
}

.ProductError__error {
  text-align: left;
}
