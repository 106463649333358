@use 'sass:math';

@import 'src/styles/vars';

.ViewAsSelectMultiple {
  position: relative;
  input::placeholder {
    font-style: italic;
  }
  &:not(.ViewAsSelectMultiple--no-option-selected) input::placeholder {
    font-style: initial;
    color: inherit;
  }
}

.ViewAsSelectMultiple--one-option-mode {
  .ChoiceTreeItem__checkbox {
    margin-top: 0.1em;
  }
  .ViewAsSelectMultiple__options {
    position: relative;
    border: none;
    z-index: initial;
  }
}

.ViewAsSelectMultiple__search {
  display: flex;
  min-height: $input-height-base - 2px;
  height: $input-height-base - 2px;
  border: 1px solid $gray-light;
  align-items: center;
  padding: 0 $padding-large-horizontal;
  .InputField__input {
    padding: 0;
  }
  input {
    width: 100%;
    border: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  i {
    margin-left: $half-margin-base-horizontal;
  }
}

.ViewAsSelectMultiple__options {
  position: absolute;
  z-index: 10;
  border: 1px solid $gray-light;
  border-top: none;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  .ChoiceTree {
    border: 0;
    max-height: initial !important;
    overflow: initial !important;
  }
}

.ViewAsSelectMultiple__header {
  font-size: 0.95rem;
  padding: $padding-small-vertical 0 $padding-small-vertical
    $padding-large-horizontal;
  background-color: $brand-white;
  color: $gray-medium;
  &--clickable {
    color: $brand-primary;
    &:hover {
      color: $brand-primary-hover;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.ViewAsSelectMultiple__loading {
  background-color: $brand-white;
  padding: $padding-small-vertical $padding-small-horizontal;

  .Spinner {
    margin-right: math.div($half-margin-base-horizontal, 2);
  }
}
