@import 'src/styles/vars';

.NutriScoreCell {
  display: flex;
  align-items: center;
}

.NutriScoreCell__letter {
  border-radius: 14px;
  font-weight: 500;
  line-height: 1.9;
  height: 30px;
  width: 19px;
  text-align: center;
}

.NutriScoreCell__current {
  color: white;
}

.NutriScoreCell__A__current {
  background-color: #00803c;
}
.NutriScoreCell__B__current {
  background-color: #87be22;
}
.NutriScoreCell__C__current {
  background-color: #ffcd00;
}
.NutriScoreCell__D__current {
  background-color: #f07d0f;
}
.NutriScoreCell__E__current {
  background-color: #e63113;
}

.NutriScoreCell__suggested {
  border: solid;
  border-width: 1.7px;
}

.NutriScoreCell__A__suggested {
  color: #00803c;
  border-color: #00803c;
}
.NutriScoreCell__B__suggested {
  color: #87be22;
  border-color: #87be22;
}
.NutriScoreCell__C__suggested {
  color: #ffcd00;
  border-color: #ffcd00;
}
.NutriScoreCell__D__suggested {
  color: #f07d0f;
  border-color: #f07d0f;
}
.NutriScoreCell__E__suggested {
  color: #e63113;
  border-color: #e63113;
}

.NutriScoreCell__tooltipWarning {
  opacity: initial;
  color: grey;
  padding-left: 4px;
}

.NutriScoreCell__tooltipTable {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}
