.ListBody {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .search-container {
    padding-left: 1rem;
    padding-top: 1rem;
  }
}
