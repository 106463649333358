@import 'src/styles/vars';

.FormTypePackaging__Choice {
  padding: 1rem;
  border-radius: $border-radius-base;
  cursor: pointer;
}

.FormTypePackaging__Choice--selected {
  background-color: $alk-background-hover;
  border: 1px solid $brand-primary;
}

.FormTypePackaging__Choice--disabled {
  cursor: inherit;
}

.FormTypePackaging__Help {
  margin-bottom: 0;
  padding-inline-start: 20px;
}

.FormTypePackaging__TypePackagings {
  margin: 1rem 0;
}

.FormTypePackaging__TypeSelection {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1em;
}

.FormTypePackaging__RowDisplay {
  display: flex;
}
