@import 'src/styles/vars';

.AddAllowanceLevel {
  color: $brand-primary;
  border: 1px dashed;

  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.AddAllowanceLevel__row {
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    padding-right: 0.5em;
    font-size: 20px;
  }
}

.AddAllowanceLevel__row__label {
  padding-right: 1em;
}
