@use 'sass:math';

@import 'src/styles/vars';

$listWidth: 300px;
$labelMargin: 2px;
$labelMaxWidth: math.div($listWidth, 3) - ($labelMargin * 6);

.ListTableCell.CatalogSourcingLabelsColumn {
  padding-top: 0;
  padding-bottom: 0;
}

.CatalogSourcingLabel__list {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-top: 2px;

  &.CatalogSourcingLabel__list--restrict {
    flex-wrap: wrap;
    width: $listWidth;
  }
}

.CatalogSourcingLabel__label {
  display: inline-block;
  color: $gray-dark;
  background-color: $gray-slightly-lighter;
  text-align: center;
  border-radius: 3px;
  margin: 0 $labelMargin 2px $labelMargin;
  max-width: $labelMaxWidth;
  padding: 0 4px;
  font-size: 0.8rem;
}

.CatalogSourcingLabel__moreLabels {
  color: $brand-secondary;
  font-size: 0.8rem;
  padding: 0 2px;
  cursor: default;

  &:hover {
    text-decoration: underline;
  }
}
