@import 'src/styles/vars';

.KindAutocomplete {
  margin-top: 10px;
}

.KindAutocomplete__examplesLink {
  font-style: italic;
  color: $gray-medium;
}
