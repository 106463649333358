$segment_with: 13rem;

.DropdownTree {
  position: relative;

  .Dropdown__button {
    width: $segment_with;
    margin: 0 3px 3px 0;
    text-align: left;
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Dropdown__button::after {
    position: absolute;
    top: 11px;
    right: 8px;
  }

  .Dropdown__list {
    width: 100%;

    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.DropdownTree__Node--vertical {
  display: block;
}

.DropdownTree__Node--horizontal {
  display: flex;
}

.DropdownTree__Leaf {
  font-style: italic;
  font-weight: bolder;
  padding-left: 14px;
  padding-right: 15px;
  margin: 0;
  line-height: 35px;
  width: $segment_with;
  list-style: none;
}

.DropdownTree__LeafLabel {
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    white-space: nowrap;
  }
}
