@use 'sass:math';

@import 'src/styles/vars';

.PNQItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

// Do not add any margins here, flex-basis cannot render properly if some items are missing.

.PNQItem__column {
  padding: 0 math.div($padding-base-horizontal, 2);
  min-width: 0;
}

.PNQItem__label {
  @include ellipsis;
  flex-basis: 25%;
  border-right: 1px solid $gray-light;
  text-align: right;
  padding: $field-padding-vertical $padding-base-horizontal
    $field-padding-vertical 0;
  line-height: $field-height-base;
}

.PNQItem__float,
.PNQItem__select,
.PNQItem__text {
  flex-basis: 10%;
}

.PNQItem__list {
  flex-basis: 20%;
}

.PNQItem__text {
  text-align: right;
}

.PNQItem__text-alignLeft {
  text-align: left;
}

.PNQItem__delete {
  flex-basis: 5%;
  color: $gray-light;
  font-size: x-large;
  flex-grow: 1;
  text-align: right;
}

.PNQItem__delete > i:hover {
  cursor: pointer;
  color: $gray-dark;
}

.PNQItem__list .Autocomplete {
  min-width: 120px;
}
