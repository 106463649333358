.DatesFilter {
  .InputDate {
    position: relative;
  }
  .InputDate__calendar--opened {
    width: 100%;
  }
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePicker__Month {
    width: 100%;
    margin: 0;
  }
}
