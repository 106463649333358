@import 'src/styles/vars';

$hierarchy-level-size: 60px;

.SuppliedUnitRadio__HierarchyWrapper {
  display: inline-flex;
  font-weight: 500;
  text-align: center;
  width: $hierarchy-level-size;
  cursor: pointer;
}

.SuppliedUnitRadio__Placeholder {
  min-height: $field-height-base;
  line-height: $field-height-base;
  color: $gray-medium;
}

.SuppliedUnitRadio__Label {
  padding-top: 8px;
}
