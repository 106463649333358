@import 'src/styles/vars';

#nav-link-sharingunit:hover,
#nav-link-sharingunit:hover button {
  color: $brand-primary;
}

.SharingUnitDropdown__NavLink {
  position: relative !important;
}

.SharingUnitDropdown__DropdownLabel {
  color: rgb(222, 222, 222);
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SharingUnitDropdown__DropdownLabel:hover {
  color: $brand-primary;
}

.Navbar__moreDropdown .Dropdown__list li {
  height: auto !important;
}

.SharingUnitDropdown__DropdownLabelActive {
  color: $brand-primary;
}

.SharingUnitDropdown__DropdownLabel > i {
  font-size: 22px;
}

.SharingUnitDropdown__Dropdown {
  position: unset;
  color: rgb(222, 222, 222);
  height: 100%;
  display: flex;
}

.SharingUnitDropdown__DropdownButton {
  padding: 0 !important;
  border: 0 !important;
  background-color: inherit !important;

  :hover {
    background-color: inherit !important;
  }
}

.SharingUnitDropdown__DropdownButton > a {
  height: 100%;
  display: flex;
  align-items: center;
}

.SharingUnitDropdown__DropdownButton::after {
  display: none !important;
}

.SharingUnitDropdown__DropdownList {
  top: 100%;
  padding: 0;
  border-radius: 0;
}

.SharingUnitDropdown__DropdownList > li {
  padding: 0;
  color: black !important;
}

.SharingUnitDropdown__DropdownList > li > button {
  padding: 0;
}

.SharingUnitDropdown__Chevron {
  margin-left: 6px;
  display: flex;
  align-items: center;
}

.SharingUnitDropdown__Option {
  color: #3c3c3c;
  padding: 8px 15px;
  border-bottom: 1px solid $gray-lighter;
  width: 400px;

  &--noBorder {
    border-bottom: 0;
  }
}

.SharingUnitDropdown__Option:hover {
  background-color: #99c5cf;
  color: #ffffff;

  .SharingUnitDropdown__OptionLabelWrapper {
    border: 1px solid #fff;
    background-color: $brand-white !important;
    color: #99c5cf !important;
  }
}

.SharingUnitDropdown__OptionTariff {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.SharingUnitDropdown__OptionHeader {
  padding-top: 8px;
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  &--noPadding {
    padding-top: 4px;
  }
}

.SharingUnitDropdown__OptionTitle {
  font-weight: bold;
  font-size: 16px;
  margin-right: 16px;
}

.SharingUnitDropdown__OptionText {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  line-height: 18px;
  font-size: 13px;
}

.SharingUnitDropdown__OptionLabel {
  font-size: 12px;
  font-weight: 600;
}
