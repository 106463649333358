@import 'src/styles/colors';

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  height: calc(100% - 2.5rem);
}

.previewWithMetadataWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.previewCanvas {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 370px;
  min-height: 370px;
  border: 1px solid $gray-lighterer;
  margin-bottom: 1rem;
}

.previewNotAvailable {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.previewPlaceholder {
  font-size: 6rem;
}

.image {
  height: 100%;
}

.metadataWrapper {
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 1rem;
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.inputs {
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.marginBottom {
  margin-bottom: 1rem;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
